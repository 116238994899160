import styles from "./css-modules/conv.module.css";
import about_image from "../../resources/convening.jpg";
import about_image1 from "../../resources/our-research/convening2.jpeg";
import about_image2 from "../../resources/our-research/partners screenshot.jpg";
import about_image3 from "../../resources/our-research/partners.jpeg";
import about_image4 from "../../resources/our-research/convening3.jpg";
import classNames from "classnames";
import {Link} from 'react-router-dom'

function ConvDesc({ checkConvButton }) {
  return (
    <div className={styles.about_desc}>
      <div className={classNames(styles.image_container, styles.no_margin)}>
        <img id="ab1" src={about_image} alt="" className={styles.image}></img>
        <img id="ab2" src={about_image1} alt="" className={styles.image}></img>
        <img id="ab3" src={about_image2} alt="" className={styles.image}></img>
        <img id="ab4" src={about_image3} alt="" className={styles.image}></img>
        <img id="ab5" src={about_image4} alt="" className={styles.image}></img>
      </div>
      <div className={styles.info_container}>
        <h2 className={classNames(styles.info_title, styles.conv_title)}>Convening</h2>
        <div className={styles.separator_container}>
          <div className={styles.separator_line}></div>
          <div className={styles.separator_skewed}></div>
       </div>
        <div className={classNames(styles.info_text, styles.conv_desc)}>
          <p>
            CAPS regularly holds invitation-only philanthropy conferences with our donors, clients, philanthropists, global experts and thought leaders from Asia and beyond.  This is the only pan-Asian, principals-only philanthropy conference series in the region.  CAPS’ convenings provide a unique ground for business leaders, philanthropists and visionaries to share ideas, experiences and strategies for improving our communities and doing good.
          </p>
          <p>
            In addition to the philanthropists convening, CAPS brings its partner organizations together to create a network where knowledge and insights are shared. CAPS is committed to building an inclusive and efficient ecosystem of social sector communities across Asia.
          </p>

          {checkConvButton === "true" && <Link to="/convening">
            <p className={styles.comm_research_butt}>
              Click to learn more
            </p>
          </Link>}
        </div>
      </div>
    </div>
  );
}

export default ConvDesc;
