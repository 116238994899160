export const commRData = [
  {
    commRTitle: "Alignment of social investment with goals and governance",
    id: "comm-research1",
    bullets: [
      "​Identifying primary programmatic goals",
      "Identifying important relationship goals and stakeholder relationships",
      "Developing impact-focused strategies",
      "Designing delivery processes including partnerships"
    ]
  },
  {
    commRTitle: "Project and program design, evaluation and review ",
    id: "comm-research2",
    bullets: [
      "​Evaluating existing projects on a one-off or on-going basis",
      "Recommending improvements, tool kits and next steps ",
    ]
  },
  {
    commRTitle: "Customized research ",
    id: "comm-research3",
    bullets: [
      <span>Scanning target sectors, survey experts, and create "landscape studies"</span>,
      "Researching relevant best practices and peer organizational benchmarking",
      "Identifying new or expansive social investments opportunities",
      "Connecting partner organizations with aligned strategy",
    ]
  }
];