import styles from "./css-modules/contact_title.module.css";
import GoogleMap from "./google_map";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail, MdLocationOn } from "react-icons/md";
import classNames from "classnames";

function openLink(e, link) {
  e.preventDefault();
  window.open(link);
}

function ContactTitleMap(props) {
  return (
    <div className={styles.title_container}>
      <div className={styles.title_text_container}>
        <div className={styles.information_container}>
          <div className={styles.information_box}>
            <MdLocationOn className={styles.info_icon_tall} />
            <p className={styles.information_value}>
              Room 2001-2, 20/F, Shui On Centre, Wan Chai, Hong Kong
            </p>
          </div>
          <div className={styles.ph_email_information_box}>
            <div className={styles.information_box}>
              <BsTelephoneFill className={styles.info_icon} />
              <p className={styles.information_value}>(+852) 3611 0250</p>
            </div>
            <div
              className={classNames(styles.information_box, styles.information_box_email)}
              onClick={(e) => openLink(e, "mailto:research@caps.org")}
            >
              <MdEmail className={styles.info_icon_tall} />
              <p className={styles.information_value}>info@caps.org</p>
            </div>
          </div>
        </div>
        <GoogleMap />
      </div>
      <div className={styles.title_image_container}>
        <div className={styles.image_gradient_overlay}></div>
        <img src={props.titleImage} className={styles.title_image}></img>
      </div>
    </div>
  );
}

export default ContactTitleMap;
