import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import StyledLandingDoors from "../components/styled-components/LandingDoors.style.js";
import OurWork from "../components/OurWork.js";
import SideHeadSection from "../components/SideHeadSection.js";
import ResourceTiles from "../components/resourceTiles.js";
import Quotes from "../components/Quotes.js";
import { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import Fade from "@mui/material/Fade";
import ReactGA from "react-ga4";
import DocumentMeta from 'react-document-meta';

function Home({ loadedCommonData, logo, setModalOpen, setModalData, setMediaData, homeReports, setHomeReports, homeMedia, setHomeMedia, partnersData, setPartnersData, loadedHomeData, setHomeLoadedData, setLoadedPartners, setPartnerCount, partnerCount, reachStats, setReachStats }) {

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (!loadedHomeData) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query fetchPolicyResearch {
              allCapsResearch(
                where: {taxQuery: {taxArray: {taxonomy: CONTENTTYPE, terms: "homepost", operator: IN, field: SLUG}}}
              ) {
                nodes {
                  id
                  title
                  uri
                  date
                  asianPeopleInfo {
                    intro
                    subtitle
                    coverImage {
                      mediaItemUrl
                    }
                  }
                }
              }
              allNewsMedia(
                first: 3
                where: {taxQuery: {taxArray: {taxonomy: HOMEPAGETAG, terms: "home", operator: IN, field: SLUG}}}
              ) {
                nodes {
                  title
                  asianPeopleInfo {
                    subtitle
                    intro
                    coverImage {
                      mediaItemUrl
                    }
                  }
                  date
                  content
                  uri
                }
              }
              page(id: "cG9zdDoxOA==") {
                id
                template {
                  ... on Template_Partners {
                    templateName
                    partners {
                      partnersListByCountryRegion {
                        countryRegionName
                        listOfPartners {
                          partnerName
                          partnerWebsiteUrl
                        }
                      }
                    }
                  }
                }
              }
              allReachStats(first: 1) {
                nodes {
                  reachStats {
                    researchCount
                    engagement
                    economies
                    advisoryCount
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        setPartnerCount(computeTotalCount(result.data.data.page.template.partners.partnersListByCountryRegion));
        setReachStats(result.data.data.allReachStats.nodes[0].reachStats);
        setHomeReports(result.data.data.allCapsResearch.nodes);
        setHomeMedia(result.data.data.allNewsMedia.nodes);
        setPartnersData(result.data.data.page.template.partners.partnersListByCountryRegion.map(d => ({
          economy: d.countryRegionName,
          id: d.countryRegionName.replace(" ", "").toLowerCase(),
          partners: d.listOfPartners.map(partner => ({
            partnerName: partner.partnerName,
            link: partner.partnerWebsiteUrl
          }))
        })));
        setLoadedPartners(true);
        setHomeLoadedData(true);
        if (location.state) {
          if (location.pathname.split("/")[location.pathname.split("/").length - 1] !== location.state.partner_slug) {
            history.push({
              pathname: `/partners/${location.state.partner_slug}`,
              state: {
                background: location,
              },
            });          
          }
        }
      });
    }    
  }, [location.state]);

  function computeTotalCount(arr) {
    let total = 0; 
    for (let i = 0; i < arr.length; i++) {
      total += arr[i].listOfPartners.length;
    }
    return total;
  }

  const meta = {
    title: 'Centre for Asian Philanthropy and Society',
    description: 'Uniquely Asian, independent action-oriented research and advisory organization committed to maximizing private resources for doing good.',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!loadedCommonData || !loadedHomeData ? (
         <CAPSLoader logo_image={logo}/>
      ) : (
        <Fade in={true}>
          <div className={common_styles.view_content_container}>
            <Navbar
              logo_image={logo}
              navigation_array={[
                { text: "about us", route: "about", type: "standard" },
                { text: "OUR WORK", route: "work", type: "standard" },
                { text: "RESOURCES", route: "resources", type: "standard" },
                { text: "CONTACT US", route: "contact", type: "standard" },
                {
                  text: "NEWSLETTER",
                  type: "button",
                  function: "subscription",
                },
              ]}
            />
            <StyledLandingDoors />
            <OurWork />
            <SideHeadSection
              header="Our Research"
              bgCol={"#FFFFFF"}
              content="research"
              contentArray={homeReports}
              toLink="/work"
              toLinkTitle="Browse Our Research"
            />
            <SideHeadSection
              header="Our Microsites"
              description="Interact with the data from our studies through our microsites. Learn the main findings at a glance. Visualize, explore and compare economies from across Asia."
              bgCol={"#F4F4F4"}
              content="microsite"
            />
            <SideHeadSection
              header="Our Reach"
              bgCol={"#FFFFFF"}
              content="reach"
              setModalOpen={setModalOpen}
              setModalData={setModalData}
              contentArray={homeMedia}
              mediaData={homeMedia}
              setMediaData={setMediaData}
              partnersData={partnersData}
              partnerCount={partnerCount}
              reachStats={reachStats}
            />
            <SideHeadSection
              header="Media and Updates"
              bgCol={"#FFFFFF"}
              content="media"
              setModalOpen={setModalOpen}
              setModalData={setModalData}
              contentArray={homeMedia}
              mediaData={homeMedia}
              setMediaData={setMediaData}
              partnersData={partnersData}
              partnerCount={partnerCount}
              reachStats={reachStats}
            />
            <ResourceTiles layout={"row"} />
            <Quotes quoteBool={false} />
            <Footer />
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Home;
