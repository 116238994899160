import styles from './css-modules/careerPost.module.css';
import CAPSLoader from "../components/capsLoader.js";
import common_styles from "./css-modules/views.module.css";
import Fade from "@mui/material/Fade";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import axios from "axios";
import classNames from 'classnames';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function CareerPost({ loadedCommonData, logo}){

  const [postData, setPostData] = useState([]);
  const [loadedPostData, setLoadedPostDataState] = useState(false);

  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query MyQuery {
              page(id: "cG9zdDoyMDcz") {
                title
                template {
                  ... on Template_Careers {
                    templateName
                    careers {
                      currentVacancies {
                        office
                        popupDescription
                        role
                      }
                    }
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        console.log(result);

        let postData = result.data.data.page.template.careers.currentVacancies.filter(function (el) {
            return el.role.includes(location.pathname.split('/')[location.pathname.split('/').length - 1].replaceAll('-', ' '));
        })[0]; 
        setPostData(postData);
        setLoadedPostDataState(true);
      });
  }, []);

  const meta = {
    title: postData.role ? postData.role : '',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!loadedCommonData || !loadedPostData ? (
         <CAPSLoader logo_image={logo}/>
      ) : (
        <Fade in={true}>
          <div className={common_styles.view_content_container}>
            <Navbar
                logo_image={logo}
                navigation_array={[
                    { text: "about us", route: "about", type: "standard" },
                    { text: "OUR WORK", route: "work", type: "standard" },
                    { text: "RESOURCES", route: "resources", type: "standard" },
                    { text: "CONTACT US", route: "contact", type: "standard" },
                    {
                    text: "NEWSLETTER",
                    type: "button",
                    function: "subscription",
                    },
                ]}
            />

            <SmallPageTitle
                title="Careers"
                subtitle = {postData.role + " (" + postData.office + ")"}
            />

            <div className={classNames(common_styles.content_container, styles.wp_content)} dangerouslySetInnerHTML={{ __html: postData.popupDescription}}></div>
            <Footer />
          </div>
        </Fade>
      )}
    </div>
  );
}

export default CareerPost;
