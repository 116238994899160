import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./css-modules/responsiveslider.module.css";

import { GoChevronRight } from "react-icons/go";
import { GoChevronLeft } from "react-icons/go";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.next_arrow} onClick={onClick}>
      <GoChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={styles.prev_arrow} onClick={onClick}>
      <GoChevronLeft />
    </div>
  );
}

export default class ResponsiveAnnR extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: this.props.contentArray.length < 6 ? 4.5 : 5,
      slidesToScroll: 3,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          },
        },
      ],
    };
    return (
      <div className={styles.slider_container}>
        <Slider {...settings}>
          {this.props.contentArray.map((image, imageIdx) => (
            <div className={styles.image_contain}
              onClick = {() => {
                window.open(`${image.fileToDownload.mediaItemUrl}`)
              }}
              key={`image${imageIdx}`}
            >
              <div
                aria-label={image.fileTitleName}
                className={styles.image_parent}
                style={{
                  background: `url(${image.cover.mediaItemUrl})`,
                  backgroundPosition: "left",
                  backgroundSize: "cover",
                }}
              >
                <div className={styles.hover_foreground}></div>
              </div>
              <p className={styles.report_title}>{image.fileTitleName}</p>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

Component.defaultProps = {
  imageArr: [],
};
