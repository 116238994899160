import door1 from "../resources/doors/door_one.jpeg";
import door2 from "../resources/doors/door_two.jpeg";
import door3 from "../resources/doors/door_three.jpeg";
import door4 from "../resources/doors/door_four.jpeg";

function LandingDoors({ className }) {
  return (
    <section className={className}>
      <div className={`door-image-stack stack1`}>
        <div
          className={"image-contain left"}
          style={{ backgroundImage: `url(${door1})` }}
          id="door1"
        />
        <div
          className={"image-contain right"}
          style={{ backgroundImage: `url(${door4})` }}
          id="door4"
        />
      </div>
      <div className={`door-image-stack stack2`}>
        <div
          className={"image-contain left"}
          style={{ backgroundImage: `url(${door2})` }}
          id="door2"
        />
        <div
          className={"image-contain right"}
          style={{ backgroundImage: `url(${door3})` }}
          id="door3"
        />
      </div>
      <div className="indigo-foreground">
        <h1 className="title">Centre for Asian Philanthropy and Society</h1>
        <p className="subtitle">
          Uniquely Asian, independent action-oriented research and advisory
          organization committed to maximizing private resources for doing good.
        </p>
      </div>
    </section>
  );
}

export default LandingDoors;
