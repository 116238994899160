import styles from "./css-modules/team_member.module.css";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

function TeamMember({
  setModalOpen,
  setModalData,
  member,
  index
}) {
  function openModal(modalData) {
    setModalOpen(true);
    setModalData(modalData);
  }

  let location = useLocation();

  return (
    <Link
      className={styles.team_member_link}
      to={{
        pathname: `/about/people/${
          member.uri.split("/")[member.uri.split("/").length - 2]
        }`,
        state: { background: location },
      }}
      key={`people-${index}`}
    >
      <div
        className={styles.team_member}
        onClick={() => openModal(member)}
        key={`people-${index}`}
      >
        <div
          className={styles.image_container}
          style={{
            background: `url(${member.details.picture.sourceUrl})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className={styles.member_info_container}>
          <h3 className={styles.employee_name}>{member.title}</h3>
          {member.details.title && (
            <p
              className={classNames(
                styles.employee_desig,
                member.empType.type === "Our Team" && styles.noBoldDesig
              )}
            >
              {member.details.title}
            </p>
          )}
          {member.details.companyName && (
            <p className={styles.employee_comp}>
              {member.details.companyName}
            </p>
          )}
          {member.details.countryRegion && (
            <p className={styles.employee_country}>
              {member.details.countryRegion}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}

export default TeamMember;
