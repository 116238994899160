import styles from "./css-modules/media_modal.module.css";
import Fade from "@mui/material/Fade";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function MediaModal({ modalData, setModalOpen, modalOpen }) {

  let options = { year: 'numeric', month: 'long' };


  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div>
    {modalOpen && <Fade in={modalOpen}>
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <Link to='/newsandmedia' style={{textDecoration: 'none'}}>
            <div onClick={() => setModalOpen(false)} className={styles.close_icon_cont}>
              <AiOutlineClose className={styles.close_icon}/>
            </div>
          </Link>
          <div className={styles.flexed_container}>
            <div className={styles.logo_container}>
              <p className={styles.newspaper}>{modalData.asianPeopleInfo.subtitle}</p>
              <img src={modalData.asianPeopleInfo.coverImage.mediaItemUrl} alt={modalData.asianPeopleInfo.subtitle} className={styles.paper_logo}></img>
            </div>
            <div className={styles.title_container}>
              <h1 className={styles.title}>{modalData.title}</h1>
              <p className={styles.date}>
                  {new Date(Date.parse(modalData.date)).toLocaleDateString("en-UK", options)}
              </p>
            </div>
          </div>
          <div className={styles.content} dangerouslySetInnerHTML={{__html: modalData.content}}/>
        </div>
      </div>
    </Fade>}
    </div>
  );
}

export default MediaModal;