import { useEffect } from 'react';
import styles from './css-modules/asianVoices.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import SmallPageTitle from '../components/view-specific/small_page_title.js';
import axios from 'axios';
import Fade from '@mui/material/Fade';
import VoiceMember from '../components/view-specific/VoiceMember.js';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function AsianVoices({loadedAsianVoices, loadedCommonData, setAsianVoicesData, asianVoicesData, logo}) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/resources/asian_voices` });
    if (!loadedAsianVoices) {
      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              allAsianVoices(first: 250) {
                nodes {
                  asianVoicesTypes {
                    nodes {
                      taxonomyName
                      name
                    }
                  }
                  title
                  content
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro
                    subtitle
                  }
                  youtube {
                    link
                  }
                  gallery_carousel {
                    fieldGroupName
                    galleryTitle
                  }
                  uri
                  asianVoices_moreInfo {
                    countryAdditional
                    organizationAdditional
                    titleAdditional
                  }
                }
              }
            }
          `
        }
      }).then((result) => {
        setAsianVoicesData(result.data.data.allAsianVoices.nodes);
      });
    }
    window.scrollTo(0,0);
  }, []);

  const meta = {
    title: 'CAPS | Asian Voices',
    description: 'Interviews with leading voices from our network',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!loadedCommonData || !loadedAsianVoices) ?
        <CAPSLoader logo_image={logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <SmallPageTitle
            title="Asian Voices"
            subtitle="Interviews with leading voices from our network"
          />

          <div className={common_styles.content_container}>
            <div className={styles.flexed_voices_container}>
              {asianVoicesData.length > 0 && asianVoicesData.map((asianVoice, index) =>
                <VoiceMember details={asianVoice} key={`voice-${index}`}/>
              )}
            </div>
          </div>

          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default AsianVoices;
