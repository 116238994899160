import styles from "./css-modules/newsandmedia.module.css";
import { Link } from "react-router-dom";
import { timeParse, timeFormat } from "d3";
import { useHistory } from "react-router-dom";

function getDate(datetimeString) {
  const date = timeParse("%Y-%m-%d")(datetimeString.split("T")[0]);
  const formattedDate = timeFormat("%d %b, %Y")(date);
  return formattedDate;
}

function NewsAndMedia({ mediaData, setMediaData }) {

  let history = useHistory();

  function changeLocation(e,d) {
    setMediaData(null);
    history.push({
      pathname: "/newsandmedia",
      state: {
        uri_slug:
          d.uri.split('/')[d.uri.split('/').length - 2],
      },
    });
  }

  return (
    <>
      <p className={styles.section_desc}>
        Our research as featured in major media sources across Asia.
        <Link to="/newsandmedia">
          <span className={styles.media_button}><i>Click to view more media</i></span>
        </Link>
      </p>

      <div className={styles.news_card_contain}>
        {mediaData.map((newsItem, idx) => (
          <div style={{cursor: 'pointer'}} className={styles.news_card} onClick={e => changeLocation(e, newsItem)} key={`newsItem${idx}`}>
            <div className={styles.news_img_header}>
              <div className={styles.image_contain} style={{backgroundImage: `url(${newsItem.asianPeopleInfo.coverImage.mediaItemUrl})`}}>
              </div>
              <h3 className={styles.news_header}>{newsItem.asianPeopleInfo.subtitle}</h3>
            </div>
            <div className={styles.desc_news_card}>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p className="resource-subtitle">
                {`${newsItem.title} `}
                <span className={styles.news_date}>{`| ${getDate(newsItem.date)}`}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default NewsAndMedia;
