import styles from "./css-modules/workcard.module.css";
import { Link } from "react-router-dom";

//console.log(styles);

function WorkCard({ image, workTypeObj }) {
  const { workType, description, description2 } = workTypeObj;
  return (
    <Link style={{textDecoration: 'none'}} to={{pathname: "/work", state: {param: workType}}} className={styles.workCard}>
      <div style={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
        <figure className={styles.figure}>
          <img
            src={image}
            alt="Supporting image for describing our work"
            className={styles.image}
          />
        </figure>
        <div className={styles.descContain}>
          <h3 className={styles.workTitle}>{workType}</h3>
          <p className={styles.description}>{description}</p>
          {description2 ? (
            <p className={styles.description}>{description2}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Link>
  );
}

export default WorkCard;
