import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './css-modules/responsiveslider.module.css';
import { Link } from 'react-router-dom';
import { timeParse, timeFormat } from "d3";
import { GoChevronRight } from 'react-icons/go';
import { GoChevronLeft } from 'react-icons/go';

function getYear(datetimeString, formatted = true) {
  const dateString = datetimeString.split("T")[0];
  const date = timeParse("%Y-%m-%d")(dateString);
  const formattedDate = timeFormat("%Y")(date);
  return formatted ? formattedDate : dateString;
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={styles.next_arrow}
      onClick={onClick}
    >
      <GoChevronRight/>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={styles.prev_arrow}
      onClick={onClick}
    >
      <GoChevronLeft/>
    </div>
  );
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: this.props.contentArray.length < 6 ? 4 : 4.5,
      slidesToScroll: 3,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        }
      ]
    };

    let options = { year: 'numeric', month: 'short' };

    return (
      <div  className={styles.slider_container}>
        <Slider {...settings}>
          {this.props.contentArray.map((image, imageIdx) => (
            <Link to={`work/${image.uri.split('/')[image.uri.split('/').length - 3]}_${image.uri.split('/')[image.uri.split('/').length - 2]}`} key={`imgDiv${imageIdx}`} className={styles.image_link}>
              <div className={styles.image_contain}>
                <div aria-label={image.title} className={styles.image_parent} style={{background: `url(${image.asianPeopleInfo.coverImage.mediaItemUrl})`, backgroundPosition: 'left', backgroundSize: 'cover'}}>
                  <div className={styles.hover_foreground}></div>
                </div>
                <p className={styles.report_title}>{image.title}</p>
                {image.date && <p className={styles.report_categ}>{new Date(Date.parse(image.date)).toLocaleDateString("en-UK", options)}</p>}
              </div>
            </Link>
          ))}
          {this.props.toLink && 
            <div>
              <Link style={{textDecoration: 'none', color: 'auto'}} to={this.props.toLink} >
                <div className={styles.view_all_button}>
                  <div className={styles.view_all_button_child}>
                    <p>{this.props.toLinkTitle}</p>
                  </div>
                </div>
              </Link>
            </div>
          }
        </Slider>
      </div>
    );
  }
}

Component.defaultProps = {
  imageArr: []
}
