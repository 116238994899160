import styles from './css-modules/sideheadsection.module.css';
import SideHeader from './SideHeader.js';
import Microsite from './Microsite.js';
import CommResearch from './CommResearch.js';
import ReachContent from './ReachContent.js';
import MediaContent from './MediaContent.js';
import Responsive from './ResponsiveSlider.js';

import stat1Icon from '../resources/reach-icons/stat1.png';
import stat2Icon from '../resources/reach-icons/stat2.png';
import stat3Icon from '../resources/reach-icons/stat3.png';
import stat4Icon from '../resources/reach-icons/stat4.png';
import stat5Icon from '../resources/reach-icons/stat5.png'; 
import { useEffect, useState } from 'react';

function SideHeadSection({ header, description, bgCol, content, imageArr, contentArray, setModalOpen, setModalData, mediaData, setMediaData, partnersData, partnerCount, reachStats, toLink, toLinkTitle}) {
  const [impactData, setImpactData] = useState([]);
  useEffect(() => {
    setImpactData([
      {
        stat: reachStats ? reachStats.economies : 0,
        desc: "Asian Economies Covered",
        icon: stat1Icon
      },
      {
        stat: partnerCount,
        desc: "Partnering Organizations",
        icon: stat2Icon
      },
      {
        stat: reachStats ? reachStats.researchCount : 0,
        desc: "Research studies, reports and books published",
        icon: stat3Icon
      },
      {
        stat: reachStats ? reachStats.engagement : 0,
        desc: "Philanthropists and thought leaders engaged with",
        icon: stat4Icon
      },
      {
        stat: reachStats ? reachStats.advisoryCount : 0,
        desc: "Foundations and corporates advised",
        icon: stat5Icon
      }
    ])
  }, []);

  return (
    <section className={styles.sidehead_section} style={content === "research" ? {flexWrap: 'noWrap', backgroundColor: bgCol} : {backgroundColor: bgCol}}>
      {
        (content === "reach") ? 
          <SideHeader header={header} impactData = {impactData}/> :
          <SideHeader header={header}/>
      }
      <div className={styles.section_pres}>
        {
          (description) ? (
            <div className={styles.section_desc}>
              {description}
            </div>
          ) : (<></>)
        }
        {
          (content === "microsite") ?
            <Microsite/> :
          (content === "comm-research") ?
            <CommResearch/> :
          (content === "reach") ?
            <ReachContent
              setModalOpen = {setModalOpen}
              setModalData = {setModalData}
              contentArray={contentArray}
              mediaData = {mediaData}
              setMediaData={setMediaData}
              partnersData={partnersData}
            /> :
          (content === "media") ?
            <MediaContent
              setModalOpen = {setModalOpen}
              setModalData = {setModalData}
              contentArray={contentArray}
              mediaData = {mediaData}
              setMediaData={setMediaData}
              partnersData={partnersData}
            /> :
          (content === "research") ?
            <Responsive contentArray={contentArray} toLink={toLink} toLinkTitle={toLinkTitle}/> : <></>
        }
      </div>
    </section>
  )
}

export default SideHeadSection;
