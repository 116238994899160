import styles from './css-modules/contact_form.module.css';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import * as emailjs from 'emailjs-com';
import Fade from "@mui/material/Fade";

import doorFr from '../../resources/doors/door_four.jpeg';
import doorOn from '../../resources/doors/door_one.jpeg';
import doorTw from '../../resources/doors/door_two.jpeg';
import doorTh from '../../resources/doors/door_three.jpeg';

function ContactForm(props) {
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [disableInputs, setDisableInputs] = useState(false);
  const [incompleteErr, setIncompleteErr] = useState(false);
  const [successFul, setSuccessFul] = useState(false);

  useEffect(() => {
    emailjs.init('user_M2RF41uyjJ9yMVqpa7dMo');
  }, [])


  function setValueState(event, id) {
    if (id === 'message') {
      setMessage(event.target.value);
    }
    else if (id === 'subject') {
      setSubject(event.target.value);
    }
    else if (id === 'email') {
      setEmail(event.target.value);
    }
    else if (id === 'fname') {
      setFName(event.target.value);
    }
    else if (id === 'lname') {
      setLName(event.target.value);
    }
  }

  function submitContactForm() {
    if (message === '' || email === '' || subject === '' || fName === '' || lName === '') {
      setIncompleteErr(true);
      setSuccessFul(false);
    }
    else {
      setDisableInputs(true);
      if (validateEmail(email)) {
        let templateParams = {
          from_name: fName + ' ' + lName,
          email: email,
          subject: subject, 
          message: message,
        }
        sendEmail(templateParams);
      }
      else {
        setDisableInputs(false);
        setIncompleteErr(true);
        setSuccessFul(false);
      }
    }
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function resetForm() {
    setMessage('');
    setSubject('');
    setEmail('');
    setFName('');
    setLName('');
  }

  function sendEmail(templateParams) {
    emailjs.send(
      'service_arqyybj',
      'template_rj26spj',
      templateParams,
      'user_M2RF41uyjJ9yMVqpa7dMo',
    ).then(function(response) {
          resetForm();
          setIncompleteErr(false);
          setSuccessFul(true);
          setDisableInputs(false);
    }, function(error) {
          setIncompleteErr(true);
          setSuccessFul(false);
          setDisableInputs(false);
    });
  }

  return (
    <div className={styles.title_container}>
        <div className={styles.doors_container}>
            <div className={styles.gradient_container}>
            </div>
            <div className={styles.images_container}>
            <img src={doorOn} alt=""/>
            <img src={doorTw} alt=""/>
            <img src={doorTh} alt=""/>
            <img src={doorFr} alt=""/>
            </div>
        </div>
      <div className={styles.title_text_container}>
        <h1 className={classNames(styles.title, "heading")}>{props.title}</h1>
        <div className={styles.title_separator}></div>
        <div className={styles.form_container}>
          <div className={styles.input_container}>
            <TextField value={fName} onChange={(event) => setValueState(event, 'fname')} required disabled={disableInputs} id="first_name" label="First Name" variant="standard" />
          </div>
          <div className={styles.input_container}>
            <TextField value={lName} onChange={(event) => setValueState(event, 'lname')} required disabled={disableInputs} id="last_name" label="Last Name" variant="standard" />
          </div>
          <div className={styles.input_container}>
            <TextField value={email} onChange={(event) => setValueState(event, 'email')} required disabled={disableInputs} id="email" label="Email" variant="standard" />
          </div>
          <div className={styles.input_container}>
            <TextField value={subject} onChange={(event) => setValueState(event, 'subject')} required disabled={disableInputs} id="subject" label="Subject" variant="standard" />
          </div>
          <div className={styles.input_container}>
            <TextField value={message} onChange={(event) => setValueState(event, 'message')} required disabled={disableInputs} id="message" label="Message" multiline rows={3} variant="standard" />
          </div>

          {incompleteErr && <Fade in={true}><p className={styles.status}>Failed to submit. Please make sure all information is provided (email is correctly formatted) and try again</p></Fade>}
          {successFul && <Fade in={true}><p className={styles.status}>Contact request received. Thankyou for contacting us.</p></Fade>}

          <div onClick={() => submitContactForm()} className={styles.button_submit}>
            <p className={styles.button_p}>Submit</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
