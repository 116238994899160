import { Link } from 'react-router-dom';
import styles from './css-modules/sideheadsection.module.css';


function SideHeader({ header, impactData}) {
  return (
    <div className={styles.side_section_header}>
      <div className={styles.separator_container}>
        <div className={styles.separator_line}></div>
        <div className={styles.separator_skewed}></div>
      </div>
      <h2>{header}</h2>
      {/*{header === 'Policy Research' && <Link style={{textDecoration: 'none'}} to="/caps_research/policy_research"><div className={styles.button}><p>View All</p></div></Link>}
      {header === 'Applied Research' && <Link style={{textDecoration: 'none'}} to="/caps_research/applied_research"><div className={styles.button}><p>View All</p></div></Link>} */}
      {
        impactData &&
        <div className={styles.impactData}>
          {
            impactData && impactData.map((datum, i) => (
              <div className={styles.impactDatum} datum_desc={datum.desc} key={`impactType${i}`}>
                <div className={styles.impactImgNumber}>
                  <img src={datum.icon}/>
                  <p className={styles.impactDatumNumber}>{datum.stat}</p>
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}

export default SideHeader;
