import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch as Routes,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

import "./App.css";
import Home from "./views/home";
import About from "./views/about";
import Insights from "./views/caps_insights";
import Contact from "./views/contact";
import Newsletter from "./views/newsletter";
import NewsAndMediaAll from "./views/newsandmediaall";
import Post from "./views/post";
import Resources from "./views/resources";
import Convening from "./views/convening";
import AsianVoices from "./views/asianVoices";
import SearchPage from "./views/search";
import SearchResults from "./views/searchResults";
import Library from "./views/library";
import AsianVoiceInterview from "./views/asianVoiceInterview";
import Work from "./views/work";
import PrivacyPolicy from './views/privacyPolicy';
import TermsOfUse from './views/termsofuse';
import CareerPost from './views/careerPost';
import Blog from './views/blog';
import AppliedResearch from './views/appliedResearch.js';
import PolicyResearch from './views/policyResearch.js';

//import Blog from './views/blog';

import nav_logo from "./resources/caps_logo.png";
import MemberModal from "./components/view-specific/MemberModal.js";
import PartnerModal from "./components/view-specific/PartnerModal.js";
import CommRModal from "./components/view-specific/CommRModal.js";
import MediaModal from "./components/view-specific/mediaModal.js";
import { commRData } from "./components/commRData.js";
import axios from "axios";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

function AppRoutes({loadedCommonData, commonData}) {

  const [partnerModalOpen, setPartnerModalOpen] = useState(false);
  const [partnerModalData, setPartnerModalData] = useState([]);

  const [commRModalOpen, setCommRModalOpen] = useState(false);

  const [loadedAboutData, setLoadedAboutData] = useState(false);
  const [aboutData, setAboutData] = useState([]);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [aboutModalData, setAboutModalData] = useState({});

  const [loadedCAPSInsights, setLoadedCAPSInsights] = useState(false);
  const [insightData, setInsightData] = useState([]);

  const [loadedAsianVoices, setloadedAsianVoices] = useState(false);
  const [asianVoicesData, setAsianVoicesData] = useState([]);

  const [loadedWorkData, setLoadedWorkData] = useState(false);
  const [workData, setWorkData] = useState([]);

  const [mediaData, setMediaData] = useState(null);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [mediaModalData, setMediaModalData] = useState({});
  const [mediaNextPage, setMediaNextPage] = useState(false);

  const [newsletterData, setNewsletterData] = useState(null);

  const [loadedQuickSearchTerms, setLoadedQuickSearchTerms] = useState(false);
  const [quickSearchTerms, setQuickSearchData] = useState([]);

  const [homeReports, setHomeReports] = useState({});
  const [homeMedia, setHomeMedia] = useState({});
  const [partnersData, setPartnersData] = useState({});
  const [loadedPartners, setLoadedPartners] = useState(false);
  const [loadedHomeData, setHomeLoadedData] = useState(false);

  const [conveningLoaded, setConveningLoaded] = useState(false);
  const [conveningData, setConveningData] = useState([]);

  const [checkConvButton, setCheckConvButton] = useState([]);
  const [partnerCount, setPartnerCount] = useState(0);

  const [reachStats, setReachStats] = useState([]);

  let location = useLocation();
  let history = useHistory();

  function setAboutModal(state) {
    if (state) {
      setAboutModalOpen(true);
    } else {
      setAboutModalOpen(false);
    }
  }

  function setAboutState(data) {
    setLoadedAboutData(true);
    setAboutData(data);
  }

  function setInsightState(data) {
    setLoadedCAPSInsights(true);
    setInsightData(data);
  }

  function setAsianVoicesState(data) {
    setloadedAsianVoices(true);
    setAsianVoicesData(data);
  }


  let background = location.state && location.state.background;

  useEffect(() => {
    //Rerouting for people direct load.
    let locationString = location.pathname;

    if (locationString.includes("partners/")) {
      history.push({
        state: {
          partner_slug:
            locationString.split("/")[locationString.split("/").length - 1],
        },
        pathname: "/",
      });
    }

    if (locationString.includes("about/people/")) {
      history.push({
        pathname: "/about",
        state: {
          uri_slug:
            locationString.split("/")[locationString.split("/").length - 1],
        },
      });
    }

    if (locationString.includes("newsandmedia/")) {
      let splitLocation = locationString.split('/');
      if (splitLocation[splitLocation.length - 1].length > 0) {
        history.push({
          pathname: "/newsandmedia",
          state: {
            uri_slug:
              splitLocation[splitLocation.length - 1],
          },
        });
      }
    }

    //Rerouting old website links.
    if (locationString.includes("our-research/")) {
      let splitLocation = locationString.split('/');
      splitLocation = splitLocation.filter(item => item);
      if (splitLocation[1]) {
        history.push({
          pathname: `/work/${splitLocation[0]}_${splitLocation[1]}`,
        });
      }
      else {
        history.push({
          pathname: `/work`,
        });
      }
    }

    if (locationString.includes("insights/what-exactly-is-a-gongo/")) {
      history.push({
        pathname: `/blog/gongo`,
      });
    }

    if (locationString.includes("insights/indias-csr-law/")) {
      history.push({
        pathname: `/blog/india_csr`,
      });
    }

    if (locationString.includes("insights/thoughts-role-social-sector-china/")) {
      history.push({
        pathname: `/blog/social_sector_china`,
      });
    }

    if (locationString.includes("insights/疫情过后对中国第三部门的几点思考/")) {
      history.push({
        pathname: `/blog/soc_org_china`,
      });
    }

    if (locationString.includes("insights/covid-19-fork-collective-road/")) {
      history.push({
        pathname: `/blog/covid`,
      });
    }

    //Rerouting old website links.
    if (locationString.includes("/doing-good/")) {
      let splitLocation = locationString.split('/');
      splitLocation = splitLocation.filter(item => item);
      history.push({
        pathname: `/content/other-research_${splitLocation[0]}&${splitLocation[1]}`,
      });
    }

    if (locationString.includes("/who-we-are/our-people/")) {
      history.push({
        pathname: `/about`,
      });
    }

    if (locationString.includes("/who-we-are/careers/")) {
      history.push({
        pathname: `/about`,
      });
    }

    if (locationString.includes("/who-we-are/mission-and-goals/")) {
      history.push({
        pathname: `/about`,
      });
    }

    if (locationString.includes("asian-voices")) {
      history.push({
        pathname: "/resources/asian_voices",
      });
    }

    if (locationString.includes("asian_voices")) {
      if (!locationString.includes("resources")) {
        let splitLocation = locationString.split('/');
        splitLocation = splitLocation.filter(item => item);
        history.push({
          pathname: `/resources/${splitLocation[0]}/${splitLocation[1]}`,
        });
      }
    }

    if (locationString === "/caps_research/" || locationString === "/caps_research") {
      history.push({
        pathname: "/work",
      });
    }

  }, []);

  return (
    <div className="App">
      <Routes location={background || location}>
        <Route
          exact
          path="/"
          children={
            <Home
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
              setModalOpen={setPartnerModalOpen}
              setModalData={setPartnerModalData}
              setMediaData={setMediaData}
              homeReports={homeReports}
              setHomeReports={setHomeReports}
              homeMedia={homeMedia}
              partnersData = {partnersData}
              setPartnersData = {setPartnersData}
              setLoadedPartners={setLoadedPartners}
              setHomeMedia={setHomeMedia}
              loadedHomeData={loadedHomeData}
              setHomeLoadedData={setHomeLoadedData}
              setPartnerCount={setPartnerCount}
              partnerCount={partnerCount}
              reachStats={reachStats}
              setReachStats={setReachStats}
            />
          }
        />
        <Route
          exact
          path="/about"
          children={
            <About
              loadedCommonData={loadedCommonData}
              loadedAboutData={loadedAboutData}
              setAboutState={setAboutState}
              aboutData={aboutData}
              setModalOpen={setAboutModalOpen}
              setModalData={setAboutModalData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/resources/glossary"
          children={
            <Insights
              loadedCommonData={loadedCommonData}
              loadedCAPSInsights={loadedCAPSInsights}
              setInsightData={setInsightState}
              insightData={insightData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/contact"
          children={
            <Contact loadedCommonData={loadedCommonData} logo={nav_logo} />
          }
        />
        <Route
          exact
          path="/post"
          children={
            <Post loadedCommonData={loadedCommonData} logo={nav_logo} />
          }
        />
        <Route
          exact
          path="/resources"
          children={
            <Resources loadedCommonData={loadedCommonData} logo={nav_logo} />
          }
        />
        <Route
          exact
          path="/resources/newsletter"
          children={
            <Newsletter 
              loadedCommonData={loadedCommonData} 
              logo={nav_logo} 
              newsletterData = {newsletterData}
              setNewsletterData = {setNewsletterData}
            />
          }
        />
        <Route
          exact
          path="/resources/asian_voices"
          children={
            <AsianVoices
              loadedCommonData={loadedCommonData}
              loadedAsianVoices={loadedAsianVoices}
              setAsianVoicesData={setAsianVoicesState}
              asianVoicesData={asianVoicesData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/resources/library"
          children={
            <Library
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
              commonData={commonData}
            />
          }
        />
        <Route
          exact
          path="/resources/asian_voices/:member_name"
          children={
            <AsianVoiceInterview
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/work"
          children={
            <Work
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
              loadedWorkData={loadedWorkData}
              setLoadedWorkData={setLoadedWorkData}
              setWorkData={setWorkData}
              workData={workData}
              checkConvButton={checkConvButton}
              setCheckConvButton={setCheckConvButton}
            />
          }
        />
        <Route
          exact
          path="/convening"
          children={
            <Convening loadedCommonData={loadedCommonData} logo={nav_logo} 
              conveningData={conveningData}
              setConveningData={setConveningData}
              conveningLoaded={conveningLoaded}
              setConveningLoaded={setConveningLoaded}
            />
          }
        />
        <Route
          exact
          path="/work/:post_id"
          children={
            <Post loadedCommonData={loadedCommonData} logo={nav_logo} />
          }
        />
        <Route
          exact
          path="/caps_research/applied_research"
          children={
            <AppliedResearch loadedCommonData={loadedCommonData} logo={nav_logo} commonData={commonData}/>
          }
        />
        <Route
          exact
          path="/caps_research/policy_research"
          children={
            <PolicyResearch loadedCommonData={loadedCommonData} logo={nav_logo} commonData={commonData}/>
          }
        />
        <Route
          exact
          path="/content/:post_id"
          children={
            <Post loadedCommonData={loadedCommonData} logo={nav_logo} />
          }
        />
        <Route
          exact
          path="/search"
          children={
            <SearchPage 
              loadedCommonData={loadedCommonData} 
              logo={nav_logo}
              loadedQuickSearchTerms={loadedQuickSearchTerms}
              quickSearchTerms={quickSearchTerms}
              setQuickSearchData={setQuickSearchData}
              setLoadedQuickSearchTerms={setLoadedQuickSearchTerms} 
            />
          }
        />
        <Route
          exact
          path="/search/:search_query"
          children={
            <SearchResults
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
              commonData={commonData}
            />
          }
        />
        <Route
          exact
          path="/newsandmedia"
          children={
            <NewsAndMediaAll
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
              setAboutState={setAboutState}
              newsAndMediaData={mediaData}
              setNewsAndMediaData={setMediaData}
              setMediaModalData={setMediaModalData}
              setMediaModalOpen={setMediaModalOpen}
              setMediaNextPage={setMediaNextPage}
              mediaNextPage={mediaNextPage}
            />
          }
        />
        <Route
          exact
          path="/privacypolicy"
          children={
            <PrivacyPolicy
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/termsofuse"
          children={
            <TermsOfUse
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
            />
          }
        />
        <Route
          exact
          path="/careers/:careerID"
          children={
            <CareerPost
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
            />
          }
        />
         <Route
          exact
          path="/blog/:blogID"
          children={
            <Blog
              loadedCommonData={loadedCommonData}
              logo={nav_logo}
            />
          }
        />
      </Routes>

      {background && (
        <Route
          path="/about/people/:empid"
          children={
            <MemberModal
              setModalData={setAboutModalData}
              setModalOpen={setAboutModalOpen}
              modalOpen={aboutModalOpen}
              modalData={aboutModalData}
              allTeamD={aboutData}
            />
          }
        />
      )}

      {background && (
        <Route
          path="/partners/:partner"
          children={
            <PartnerModal
              setModalOpen={setPartnerModalOpen}
              modalData={partnersData}
              modalOpen={partnerModalOpen}
              loadedPartners={loadedPartners}
            />
          }
        />
      )}

      {background && (
        <Route
          path="/work/:commRType"
          children={
            <CommRModal
              setModalOpen={setCommRModalOpen}
              modalData={commRData}
              modalOpen={commRModalOpen}
            />
          }
        />
      )}
      
      {background && (
        <Route
          path="/newsandmedia/:mediaId"
          children={
            <MediaModal
              setModalOpen={setMediaModalOpen}
              modalData={mediaModalData}
              modalOpen={mediaModalOpen}
            />
          }
        />
      )}
    </div>
  );
}

function App() {
  const [loadedCommonData, setLoadedCommonData] = useState(false);
  const [commonData, setCommonData] = useState([]);

  const tagManagerArgs = {
    gtmId: 'GTM-MNHWMQD'
  } 

  useEffect(() => {
    //Loading Application Common Data, Economies, Filters etc.
    if (!loadedCommonData) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query fetchEconomies {
              economies(first: 30) {
                nodes {
                  name
                  slug
                }
              }
              topics(first: 30) {
                nodes {
                  name
                  slug
                }
              }
              languages(first: 20) {
                nodes {
                  name
                  slug
                }
              }
              allResearchType {
                nodes {
                  name
                  slug
                }
              }
              contentTypes(first: 15) {
                nodes {
                  name
                  id
                }
              }
            }
          `,
        },
      }).then((result) => {
        let fetchedArr = [
          { id: "economies", data: result.data.data.economies },
          { id: "researchTypes", data: result.data.data.allResearchType },
          { id: "topics", data: result.data.data.topics },
          { id: "types", data: result.data.data.contentTypes },
          { id: "languages", data: result.data.data.languages}
        ];
        setCommonData(fetchedArr);
        setLoadedCommonData(true);
      });
    }
    ReactGA.initialize("G-B271KGLG3G");
    TagManager.initialize(tagManagerArgs)
  }, []);

  return (
    <Router>
      <AppRoutes 
        loadedCommonData={loadedCommonData}
        commonData={commonData}
      />
    </Router>
  );
}

export default App;
