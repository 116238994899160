import styles from "./css-modules/navbar.module.css";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { HiMenuAlt3, HiOutlineSearch } from "react-icons/hi";
import { IoLogoWechat } from "react-icons/io5";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import { useLocation } from 'react-router-dom';
import qrcode from '../resources/qrcode_we_chat.jpg';

function openLink(e, link) {
  e.preventDefault();
  window.open(link);
}

function Navbar({ logo_image, navigation_array, setNewsLetterModalOpen }) {
  const [mobileNav, setMobileNav] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [incompleteErr, setIncompleteErr] = useState(false);
  const [successFul, setSuccessFul] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [mailChimpReq, setMailChimpReq] = useState(null);
  const [stickNav, setStickNav] = useState(false);
  const [weChat, openWeChatModel] = useState(false);

  let location = useLocation();

  function resetText() {
    setFName("");
    setLName("");
    setEmail("");
    setOrganization("");
  }

  function setValueState(event, id) {
    if (id === "fname") {
      setFName(event.target.value);
    } else if (id === "lname") {
      setLName(event.target.value);
    } else if (id === "organization") {
      setOrganization(event.target.value);
    } else if (id === "email") {
      setEmail(event.target.value);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  function stickNavbar() {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 300 ? setStickNav(true) : setStickNav(false);
    }
  }


  useEffect(() => {
    mailChimpReq &&
      axios
        .post(
          "https://18.163.120.145.nip.io/api/addMember",
          mailChimpReq
        )
        .then(function (response) {
          if (response.data.statusCode === 200) {
            //console.log("success");
            setSuccessFul(true);
            setAlreadyExists(false);
            setIncompleteErr(false);
            setDisableInputs(false);
            resetText();
          } else if (response.data.statusCode === 400) {
            //console.log("Already exists");

            setAlreadyExists(true);
            setIncompleteErr(false);
            setSuccessFul(false);
            setDisableInputs(false);
            resetText();
          } else {
            //console.log("Error");
            setIncompleteErr(true);
            setSuccessFul(false);
            setAlreadyExists(false);
            setDisableInputs(false);
          }
        });
  }, [mailChimpReq]);

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function submitContactForm() {
    if (email === "" || organization === "" || fName === "" || lName === "") {
      setIncompleteErr(true);
      setSuccessFul(false);
    } else {
      setDisableInputs(true);
      if (validateEmail(email)) {
        let req = {
          email: email,
          firstName: fName,
          lastName: lName,
          organization: organization,
        };
        setMailChimpReq(req);
      } else {
        setDisableInputs(false);
        setIncompleteErr(true);
        setSuccessFul(false);
      }
    }
  }

  return (
    <div>
    <nav className={styles.navbar_container}>
      <div className={styles.navbar_logo_container}>
        <Link to="/">
          <img
            src={logo_image}
            alt="Centre for Asian Philanthropy and Society"
            className={styles.nav_logo}
          ></img>
        </Link>
      </div>
      <div className={styles.navigation_container}>
        {navigation_array &&
          navigation_array.map((navItem, index) => (
            <div key={index} className={styles.navItem_container}>
              {navItem.type === "button" ? (
                <div
                  className={styles.navItem_button}
                  onClick={() => {
                    setModalShow(true);
                    document.body.style.top = `-${window.scrollY}px`;
                    document.body.style.position = "fixed";
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <p className={styles.navItem_text}>{navItem.text}</p>
                </div>
              ) : (
                <Link
                  key={index}
                  className={styles.no_text_decoration}
                  to={"/" + navItem.route}
                >
                  <p className={styles.navItem_text}>{navItem.text}</p>
                </Link>
              )}
              {index < navigation_array.length - 2 && (
                <div
                  key={index + "dot"}
                  className={styles.navigation_circle}
                ></div>
              )}
            </div>
          ))}
      </div>

      <div className={styles.icons_container}>
        <div className={styles.flexed_icons}>
          <Link to="/search" style={{ textDecoration: "none", color: "black" }}>
            <HiOutlineSearch className={styles.search_icon} />
          </Link>
        </div>
        <div className={styles.flexed_icons}>
          <FaInstagram
            className={styles.social_icon}
            onClick={(e) => openLink(e, "https://www.instagram.com/capsasia/")}
          />
          <FaFacebookF
            className={styles.social_icon_high}
            onClick={(e) => openLink(e, "https://www.facebook.com/capsasia/")}
          />
          <FaTwitter
            className={styles.social_icon}
            onClick={(e) => openLink(e, "https://twitter.com/caps_asia")}
          />
          <FaYoutube
            className={styles.social_icon}
            onClick={(e) =>
              openLink(
                e,
                "https://www.youtube.com/channel/UCalxz6pdanDvK7xtH95Rj2Q"
              )
            }
          />
          <FaLinkedin
            className={styles.social_icon_high}
            onClick={(e) =>
              openLink(e, "https://www.linkedin.com/company/capsasia/")
            }
          />
          <MdMail
            className={styles.social_icon}
            onClick={(e) => openLink(e, "mailto:research@caps.org")}
          />
          <IoLogoWechat
            className={styles.social_icon_high}
            onClick={() => openWeChatModel(true)}
          />
        </div>
      </div>

      <div onClick={() => setMobileNav(true)} className={styles.mobile_menu}>
        <HiMenuAlt3 className={styles.mobile_menu_icon} />
      </div>

      <Fade in={mobileNav}>
        <div className={styles.mobile_nav_container} style={mobileNav ? {display: 'flex'} : {display: 'none'}}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar_logo_container}>
              <img
                src={logo_image}
                alt="Centre for Asian Philanthropy and Society"
                className={styles.nav_logo}
              ></img>
            </div>
            <AiOutlineClose
              onClick={() => setMobileNav(false)}
              className={styles.mobile_menu_icon}
            />
          </div>

          <div className={styles.buttons_container}>
            <div className={styles.navigation_container_mob}>
              <div className={styles.navItem_container}>
                <Link className={styles.no_text_decoration} to={"/" + "search"}>
                  <p className={styles.navItem_text}>Search</p>
                </Link>
              </div>
              {navigation_array &&
                navigation_array.map((navItem, index) => (
                  <div key={index} className={styles.navItem_container} style={location.pathname === `/${navItem.route}` ? {display: 'none'} : {visibility: 'visible'}}>
                    {navItem.type === "button" ? (
                      <div
                        className={styles.navItem_button}
                        onClick={() => {
                          setModalShow(true);
                          document.body.style.top = `-${window.scrollY}px`;
                          document.body.style.position = "fixed";
                          document.body.style.overflow = "hidden";
                        }}
                      >
                        <p className={styles.navItem_text}>{navItem.text}</p>
                      </div>
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'column'}}>{location.pathname !== `/${navItem.route}` && <Link
                        key={index}
                        className={styles.no_text_decoration}
                        to={"/" + navItem.route}
                      >
                        <p className={styles.navItem_text}>{navItem.text}</p>
                      </Link>}</div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className={styles.social_flexed_icons_mob}>
            <FaInstagram
              className={styles.social_icon}
              onClick={(e) =>
                openLink(e, "https://www.instagram.com/capsasia/")
              }
            />
            <FaFacebookF
              className={styles.social_icon_high}
              onClick={(e) => openLink(e, "https://www.facebook.com/capsasia/")}
            />
            <FaTwitter
              className={styles.social_icon}
              onClick={(e) => openLink(e, "https://twitter.com/caps_asia")}
            />
            <FaYoutube
              className={styles.social_icon}
              onClick={(e) =>
                openLink(
                  e,
                  "https://www.youtube.com/channel/UCalxz6pdanDvK7xtH95Rj2Q"
                )
              }
            />
            <FaLinkedin
              className={styles.social_icon_high}
              onClick={(e) =>
                openLink(e, "https://www.linkedin.com/company/capsasia/")
              }
            />
            <MdMail
              className={styles.social_icon}
              onClick={(e) => openLink(e, "mailto:research@caps.org")}
            />
          <IoLogoWechat
            className={styles.social_icon_high}
            onClick={() => openWeChatModel(true)}
          />
          </div>
        </div>
      </Fade>

      <Fade in={modalShow}>
        <div className={styles.modal_container}>
          <div className={styles.form_container}>
            <h3 className={styles.newsletter_sub_header}>
              Subscribe to our newsletter
            </h3>
            <AiOutlineClose
              onClick={() => {
                setModalShow(false);
                const scrollY = document.body.style.top;
                document.body.style.position = "";
                document.body.style.top = "";
                window.scrollTo(0, parseInt(scrollY || "0") * -1);
                document.body.style.overflow = "unset";
                resetText();
                setSuccessFul(false);
                setAlreadyExists(false);
                setIncompleteErr(false);
              }}
              className={styles.icon_close}
            />
            <div className={styles.input_container}>
              <TextField
                className={styles.text_form}
                value={fName}
                onChange={(event) => setValueState(event, "fname")}
                required
                disabled={disableInputs}
                id="first_name"
                label="First Name"
                variant="standard"
              />
            </div>
            <div className={styles.input_container}>
              <TextField
                className={styles.text_form}
                value={lName}
                onChange={(event) => setValueState(event, "lname")}
                required
                disabled={disableInputs}
                id="last_name"
                label="Last Name"
                variant="standard"
              />
            </div>
            <div className={styles.input_container}>
              <TextField
                className={styles.text_form}
                value={email}
                onChange={(event) => setValueState(event, "email")}
                required
                disabled={disableInputs}
                id="email"
                label="Email"
                variant="standard"
              />
            </div>
            <div className={styles.input_container}>
              <TextField
                className={styles.text_form}
                value={organization}
                onChange={(event) => setValueState(event, "organization")}
                required
                disabled={disableInputs}
                id="organization"
                label="Organization"
                variant="standard"
              />
            </div>
            {incompleteErr && (
              <Fade in={true}>
                <p className={styles.status}>
                  Failed to submit. Please make sure all information is provided
                  (email is correctly formatted) and try again
                </p>
              </Fade>
            )}
            {successFul && (
              <Fade in={true}>
                <p className={styles.status}>Thankyou for subscribing.</p>
              </Fade>
            )}
            {alreadyExists && (
              <Fade in={true}>
                <p className={styles.status}>You are already subscribed.</p>
              </Fade>
            )}
            <div
              className={styles.button_submit}
              onClick={() => submitContactForm()}
            >
              <p className={styles.button_p}>Submit</p>
            </div>
            <Link
              to="/resources/newsletter"
              onClick={() => {
                const scrollY = document.body.style.top;
                document.body.style.position = "";
                document.body.style.top = "";
                window.scrollTo(0, parseInt(scrollY || "0") * -1);
                document.body.style.overflow = "unset";
              }}
            >
              <p>Visit Newsletter Archive</p>
            </Link>
          </div>
        </div>
      </Fade>
    </nav>

    <Fade in={stickNav}>
    <nav className={classNames(styles.navbar_container, styles.stick_nav)}>
    <div className={styles.navbar_logo_container}>
      <Link to="/">
        <img
          src={logo_image}
          alt="Centre for Asian Philanthropy and Society"
          className={styles.nav_logo}
        ></img>
      </Link>
    </div>
    <div className={styles.navigation_container}>
      {navigation_array &&
        navigation_array.map((navItem, index) => (
          <div key={index} className={styles.navItem_container}>
            {navItem.type === "button" ? (
              <div
                className={styles.navItem_button}
                onClick={() => {
                  setModalShow(true);
                  document.body.style.top = `-${window.scrollY}px`;
                  document.body.style.position = "fixed";
                  document.body.style.overflow = "hidden";
                }}
              >
                <p className={styles.navItem_text}>{navItem.text}</p>
              </div>
            ) : (
              <Link
                key={index}
                className={styles.no_text_decoration}
                to={"/" + navItem.route}
              >
                <p className={styles.navItem_text}>{navItem.text}</p>
              </Link>
            )}
            {index < navigation_array.length - 2 && (
              <div
                key={index + "dot"}
                className={styles.navigation_circle}
              ></div>
            )}
          </div>
        ))}
    </div>

    <div className={styles.icons_container}>
      <div className={styles.flexed_icons}>
        <Link to="/search" style={{ textDecoration: "none", color: "black" }}>
          <HiOutlineSearch className={styles.search_icon} />
        </Link>
      </div>
      <div className={styles.flexed_icons}>
        <FaInstagram
          className={styles.social_icon}
          onClick={(e) => openLink(e, "https://www.instagram.com/capsasia/")}
        />
        <FaFacebookF
          className={styles.social_icon_high}
          onClick={(e) => openLink(e, "https://www.facebook.com/capsasia/")}
        />
        <FaTwitter
          className={styles.social_icon}
          onClick={(e) => openLink(e, "https://twitter.com/caps_asia")}
        />
        <FaYoutube
          className={styles.social_icon}
          onClick={(e) =>
            openLink(
              e,
              "https://www.youtube.com/channel/UCalxz6pdanDvK7xtH95Rj2Q"
            )
          }
        />
        <FaLinkedin
          className={styles.social_icon_high}
          onClick={(e) =>
            openLink(e, "https://www.linkedin.com/company/capsasia/")
          }
        />
        <MdMail
          className={styles.social_icon}
          onClick={(e) => openLink(e, "mailto:research@caps.org")}
        />
        <IoLogoWechat
            className={styles.social_icon}
            onClick={() => openWeChatModel(true)}
          />
      </div>
    </div>

    <div onClick={() => setMobileNav(true)} className={styles.mobile_menu}>
      <HiMenuAlt3 className={styles.mobile_menu_icon} />
    </div>

    <Fade in={mobileNav}>
      <div className={styles.mobile_nav_container}>
        <div className={styles.navbar_container}>
          <div className={styles.navbar_logo_container}>
            <img
              src={logo_image}
              alt="Centre for Asian Philanthropy and Society"
              className={styles.nav_logo}
            ></img>
          </div>
          <AiOutlineClose
            onClick={() => setMobileNav(false)}
            className={styles.mobile_menu_icon}
          />
        </div>

        <div className={styles.buttons_container}>
          <div className={styles.navigation_container_mob}>
            <Link className={styles.no_text_decoration} to={"/" + "search"}>
              <p className={styles.navItem_text}>Search</p>
            </Link>
            {navigation_array &&
              navigation_array.map((navItem, index) => (
                <div key={index} className={styles.navItem_container}>
                  {navItem.type === "button" ? (
                    <div
                      className={styles.navItem_button}
                      onClick={() => {
                        setModalShow(true);
                        document.body.style.top = `-${window.scrollY}px`;
                        document.body.style.position = "fixed";
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <p className={styles.navItem_text}>{navItem.text}</p>
                    </div>
                  ) : (
                    <Link
                      key={index}
                      className={styles.no_text_decoration}
                      to={"/" + navItem.route}
                    >
                      <p className={styles.navItem_text}>{navItem.text}</p>
                    </Link>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className={styles.social_flexed_icons_mob}>
          <FaInstagram
            className={styles.social_icon}
            onClick={(e) =>
              openLink(e, "https://www.instagram.com/capsasia/")
            }
          />
          <FaFacebookF
            className={styles.social_icon_high}
            onClick={(e) => openLink(e, "https://www.facebook.com/capsasia/")}
          />
          <FaTwitter
            className={styles.social_icon}
            onClick={(e) => openLink(e, "https://twitter.com/caps_asia")}
          />
          <FaYoutube
            className={styles.social_icon}
            onClick={(e) =>
              openLink(
                e,
                "https://www.youtube.com/channel/UCalxz6pdanDvK7xtH95Rj2Q"
              )
            }
          />
          <FaLinkedin
            className={styles.social_icon_high}
            onClick={(e) =>
              openLink(e, "https://www.linkedin.com/company/capsasia/")
            }
          />
          <MdMail
            className={styles.social_icon}
            onClick={(e) => openLink(e, "mailto:research@caps.org")}
          />
          <IoLogoWechat
            className={styles.social_icon}
            onClick={() => openWeChatModel(true)}
          />
        </div>
      </div>
    </Fade>

    <Fade in={modalShow}>
      <div className={styles.modal_container}>
        <div className={styles.form_container}>
          <h3 className={styles.newsletter_sub_header}>
            Subscribe to our newsletter
          </h3>
          <AiOutlineClose
            onClick={() => {
              setModalShow(false);
              const scrollY = document.body.style.top;
              document.body.style.position = "";
              document.body.style.top = "";
              window.scrollTo(0, parseInt(scrollY || "0") * -1);
              document.body.style.overflow = "unset";
              resetText();
              setSuccessFul(false);
              setAlreadyExists(false);
              setIncompleteErr(false);
            }}
            className={styles.icon_close}
          />
          <div className={styles.input_container}>
            <TextField
              className={styles.text_form}
              value={fName}
              onChange={(event) => setValueState(event, "fname")}
              required
              disabled={disableInputs}
              id="first_name"
              label="First Name"
              variant="standard"
            />
          </div>
          <div className={styles.input_container}>
            <TextField
              className={styles.text_form}
              value={lName}
              onChange={(event) => setValueState(event, "lname")}
              required
              disabled={disableInputs}
              id="last_name"
              label="Last Name"
              variant="standard"
            />
          </div>
          <div className={styles.input_container}>
            <TextField
              className={styles.text_form}
              value={email}
              onChange={(event) => setValueState(event, "email")}
              required
              disabled={disableInputs}
              id="email"
              label="Email"
              variant="standard"
            />
          </div>
          <div className={styles.input_container}>
            <TextField
              className={styles.text_form}
              value={organization}
              onChange={(event) => setValueState(event, "organization")}
              required
              disabled={disableInputs}
              id="organization"
              label="Organization"
              variant="standard"
            />
          </div>
          {incompleteErr && (
            <Fade in={true}>
              <p className={styles.status}>
                Failed to submit. Please make sure all information is provided
                (email is correctly formatted) and try again
              </p>
            </Fade>
          )}
          {successFul && (
            <Fade in={true}>
              <p className={styles.status}>Thankyou for subscribing.</p>
            </Fade>
          )}
          {alreadyExists && (
            <Fade in={true}>
              <p className={styles.status}>You are already subscribed.</p>
            </Fade>
          )}
          <div
            className={styles.button_submit}
            onClick={() => submitContactForm()}
          >
            <p className={styles.button_p}>Submit</p>
          </div>
          <Link
            to="/resources/newsletter"
            onClick={() => {
              const scrollY = document.body.style.top;
              document.body.style.position = "";
              document.body.style.top = "";
              window.scrollTo(0, parseInt(scrollY || "0") * -1);
              document.body.style.overflow = "unset";
            }}
          >
            <p>Visit Newsletter Archive</p>
          </Link>
        </div>
      </div>
    </Fade>

    </nav>
    </Fade>

      <Fade in={weChat}>
        <div className={styles.weChatModal} onClick={() => openWeChatModel(false)}>
          <div className={styles.modal_we_container}>
            <p className={styles.modal_title}>
              Join us on WeChat
            </p>
            <img src={qrcode} className={styles.qr_code}></img>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Navbar;
