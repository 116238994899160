import styles from "./css-modules/partner_modal.module.css";
import { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
// import classNames from "classnames";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function PartnerModal({ modalData, setModalOpen, modalOpen, loadedPartners }) {
  const { partner } = useParams();

  let econData = [];

  const [econName, setEconName] = useState('');
  const [econPartnersList, setEconPartnersList] = useState([]);

  const location = useLocation();
  useEffect(() => {
    if (!modalOpen) {
      setModalOpen(true);
    }

    if (loadedPartners) {
      econData = modalData.filter((econ, idx) => econ.id === location.pathname.split("/")[location.pathname.split("/").length - 1])[0];
      setEconName(econData.economy);
      setEconPartnersList(econData.partners);
    }

    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    return () => {
       const scrollY = document.body.style.top;
       document.body.style.position = "";
       document.body.style.top = "";
       window.scrollTo(0, parseInt(scrollY || "0") * -1);
       document.body.style.overflow = "unset";
     };
  }, []);

  return (
    <Fade in={modalOpen}>
      <section className={styles.modalData}>
        <Link onClick={() => setModalOpen(false)} to="/">
          <button className={styles.goBackWhite}></button>
        </Link>

        {<div className={styles.data_container}>
          <h3 className={styles.econHeader}>{econName}</h3>
          <ul>
            {econPartnersList.length > 0 && econPartnersList.map((partner, idx) => (
              <li key={`partner${idx}`}>
                <a
                  className={styles.partnerLink}
                  href={partner.link}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {partner.partnerName}
                </a>
              </li>
            ))}
          </ul>
          <Link onClick={() => setModalOpen(false)} to="/">
            <div className={styles.close_button}>
              <AiOutlineClose onClick={() => setModalOpen(false)} />
            </div>
          </Link>
            </div> }
      </section>
    </Fade>
  );
}

export default PartnerModal;
