import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import ContactTitleMap from "../components/view-specific/contact_title_map.js";
import ContactForm from "../components/view-specific/contact_form.js";
import door from "../resources/doors/door_four.jpeg";
import {useEffect} from "react";
import Fade from "@mui/material/Fade";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function openLink(e, link) {
  e.preventDefault();
  window.open(link);
}

function Contact(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/contact` });
    window.scrollTo(0,0);
  }, [])

  const meta = {
    title: 'CAPS | Contact Us',
    description: '',
  };
  
  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!props.loadedCommonData ? 
        <CAPSLoader logo_image={props.logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array={[
              { text: "about us", route: "about", type: "standard" },
              { text: "OUR WORK", route: "work", type: "standard" },
              { text: "RESOURCES", route: "resources", type: "standard" },
              { text: "CONTACT US", route: "contact", type: "standard" },
              { text: "NEWSLETTER", type: "button", function: "subscription" },
            ]}
          />

          <ContactTitleMap titleImage={door} />

          <ContactForm title="Send us a message"/>

          <Footer />
        </div>
      </Fade>
      }
    </div>
  );
}

export default Contact;
