import NetworkMap from "./NetworkMap.js";
import NewsAndMedia from "./NewsAndMedia.js";
import { projection, geoPath } from "./projections.js";
import styles from "./css-modules/reachcontent.module.css";

const mapStyles = {
  fill: "lightgrey",
  fillOpacity: "1",
  stroke: "darkgrey",
  strokeWidth: "1px",
  strokeOpacity: "0.0",
};

function ReachContent({
  setModalOpen,
  setModalData,
  contentArray,
  mediaData,
  setMediaData,
  partnersData
}) {
  return (
    <>
      <NewsAndMedia mediaData={mediaData} setMediaData={setMediaData} />
    </>
  );
}

export default ReachContent;
