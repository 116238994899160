import styles from './css-modules/searchPage.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Fade from '@mui/material/Fade';
import classNames from 'classnames';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

import doorFr from '../resources/doors/door_four.jpeg';
import doorOn from '../resources/doors/door_one.jpeg';
import doorTw from '../resources/doors/door_two.jpeg';
import doorTh from '../resources/doors/door_three.jpeg';

function SearchPage(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [CAPSOnly, setCAPSOnly] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/search` });
    if (!props.loadedQuickSearchTerms) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query MyQuery {
              allQuickSearch(first: 5) {
                nodes {
                  searchTerm {
                    title
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        props.setQuickSearchData(result.data.data.allQuickSearch.nodes);
        props.setLoadedQuickSearchTerms(true);
      });
    }
    window.scrollTo(0,0);
  }, [])

  const meta = {
    title: 'CAPS | Search',
    description: '',
  };

  function onSearchTermChange(event) {
    setSearchTerm(event.target.value);
  }

  function capsResearchCheckBox() {
    setCAPSOnly(!CAPSOnly);
  }

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!props.loadedCommonData || !props.loadedQuickSearchTerms) ?
        <CAPSLoader logo_image={props.logo}/>
        :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <div className={styles.search_container_main}>
            <div className={styles.doors_container}>
              <div className={styles.gradient_container}>
              </div>
              <div className={styles.images_container}>
                <img src={doorOn} alt=""/>
                <img src={doorTw} alt=""/>
                <img src={doorTh} alt=""/>
                <img src={doorFr} alt=""/>
              </div>
            </div>
            <div className={styles.absolute_content}>
              <div className={common_styles.content_container}>
                <div className={styles.increased_margin}>
                  <div className={styles.search_bar_container}>
                    <div className={styles.search_bar_flex}>
                        <TextField onChange={(e) => onSearchTermChange(e)} className={classNames(styles.searchBox, "searchBox")} defaultValue={searchTerm} id="update-search" label="Search Term" variant="standard" />
                        <Link style={{textDecoration: 'none'}} to={`/search/${searchTerm.replaceAll(' ','_')}${CAPSOnly ? `&&capsonly` : ``}`}>
                          <div className={styles.search_button}>
                              <p className={styles.search_button_text}>Search</p>
                          </div>
                        </Link>
                    </div>
                    <div className={styles.show_caps_only}>
                      <p className={styles.checkbox_text}>Show CAPS research only</p>
                      <Checkbox className={'invert_check'} onChange={() => capsResearchCheckBox()} aria-label="Show CAPS research only" checked={CAPSOnly} />
                    </div>

                    <p className={styles.quick_link_title}>Quick Links</p>
                    <div className={styles.quick_search_containers}>
                      {props.quickSearchTerms.length > 0 && props.quickSearchTerms.map((search, index) => {
                        return <Link style={{textDecoration: 'none'}} to={`/search/${search.searchTerm.title.replaceAll(' ','_')}`}>
                        <div key={index} className={styles.quick_search_item}>
                            <p className={styles.search_text}>{search.searchTerm.title}</p>
                          </div>  
                          </Link>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default SearchPage;
