import microsite1 from '../resources/microsites/microsite1.jpg';
import microsite2 from '../resources/microsites/microsite2.jpg';
import styles from './css-modules/microsite.module.css';

function Microsite(){
  return (
    <div className={styles.microsite_card_contain}>
      <div className={styles.microsite_card} onClick={() => window.open("https://doinggoodindex.caps.org/")}>
        <img className={styles.absolute_background} src={microsite1}></img>
        <div className={`${styles.desc_DGI} ${styles.desc_microsite}`}>
          <h3 className="microsite-title">Doing Good Index 2024</h3>
          <p className="microsite-subtitle">Examining the Readiness of Asia’s Social Sectors to Thrive</p>
        </div>
      </div>
      <div className={styles.microsite_card}  onClick={() => window.open("https://www.socialenterprises.caps.org/")}>
        <img className={styles.absolute_background} src={microsite2}></img>
        <div className={`${styles.desc_BFG} ${styles.desc_microsite}`}>
          <h3 className="microsite-title">Business For Good</h3>
          <p className="microsite-subtitle">Maximing the Value of Social Enterprises in Asia</p>
        </div>
      </div>
    </div>
  )
}

export default Microsite;
