import NetworkMap from "./NetworkMap.js";
import NewsAndMedia from "./NewsAndMedia.js";
import { projection, geoPath } from "./projections.js";
import styles from "./css-modules/reachcontent.module.css";

const mapStyles = {
  fill: "lightgrey",
  fillOpacity: "1",
  stroke: "darkgrey",
  strokeWidth: "1px",
  strokeOpacity: "0.0",
};

function ReachContent({
  setModalOpen,
  setModalData,
  contentArray,
  mediaData,
  setMediaData,
  partnersData
}) {
  return (
    <>
      {}
      <p className={styles.section_desc}>
        CAPS has developed a wide network of partners and collaborators in 18 economies to conduct our research and advisory projects
      </p>

      <NetworkMap
        svgWidth={1000}
        svgHeight={750}
        mapStyles={mapStyles}
        landkey={"capsAsiaMap"}
        geoPath={geoPath}
        geoProj={projection}
        setModalOpen={setModalOpen}
        setModalData={setModalData}
        partnersData={partnersData}
      />
    </>
  );
}

export default ReachContent;
