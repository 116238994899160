import common_styles from './css-modules/views.module.css';
import styles from '../components/view-specific/css-modules/newsandmediatiles.module.css'
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import SmallPageTitle from '../components/view-specific/small_page_title.js';
import NewsAndMediaTiles from '../components/view-specific/NewsAndMediaTiles.js';
import axios from 'axios';
import { useEffect, useState } from "react";
import Fade from '@mui/material/Fade';
import { useLocation, useHistory } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function NewsAndMediaAll({
  loadedCommonData,
  logo,
  newsAndMediaData,
  setNewsAndMediaData,
  setMediaModalData,
  setMediaModalOpen,
  setMediaNextPage,
  mediaNextPage
}) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/newsandmedia" });
    window.scrollTo(0,0);
  }, []);
  
  useEffect(() => {
    if (!newsAndMediaData) {
      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              allNewsMedia(first: 30) {
                nodes {
                  title
                  asianPeopleInfo {
                    subtitle
                    intro
                    coverImage {
                      mediaItemUrl
                    }
                  }
                  date
                  content
                  uri
                }
                pageInfo {
                  hasNextPage
                  endCursor
                }
              }
            }
          `
        }
      }).then((result) => {
        setNewsAndMediaData(result.data.data.allNewsMedia.nodes);
        setMediaNextPage(result.data.data.allNewsMedia.pageInfo);
        if (location.state) {
          if (location.state && location.state.uri_slug) {

            let resultArr = result.data.data.allNewsMedia.nodes;
            let node = resultArr.filter(function (node) { return node.uri.includes(location.state.uri_slug) })[0];

            if (!node) {
              axios({
                url: 'https://wordpress.caps.org/graphql',
                method: 'post',
                data: {
                  query: `
                    query MyQuery {
                      newsAndMedia(id: "/news-and-media/${location.state.uri_slug}", idType: URI) {
                        title
                        asianPeopleInfo {
                          subtitle
                          intro
                          coverImage {
                            mediaItemUrl
                          }
                        }
                        date
                        content
                        uri
                      }
                    }
                  `
                }
              }).then((result) => {
                setMediaModalData(result.data.data.newsAndMedia);
                setMediaModalOpen(true);
                history.push({
                  pathname: `/newsandmedia/${location.state.uri_slug}`,
                  state: {
                    background: location
                  } 
                });
              });
              
            }
            else {
              setMediaModalData(node);
              setMediaModalOpen(true);
              history.push({
                pathname: `/newsandmedia/${location.state.uri_slug}`,
                state: {
                  background: location
                } 
              });
            }
          }
        }
      });
    }
  }, [location.state]);

  function moreMediaPosts() {
    axios({
      url: "https://wordpress.caps.org/graphql",
      method: "post",
      data: {
        query: `
          query MyQuery {
            allNewsMedia(first: 30, after: "${mediaNextPage.endCursor}") {
              nodes {
                title
                asianPeopleInfo {
                  subtitle
                  intro
                  coverImage {
                    mediaItemUrl
                  }
                }
                date
                content
                uri
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        `
      },
    }).then((result) => {
      setNewsAndMediaData(
        newsAndMediaData.concat(result.data.data.allNewsMedia.nodes)
      );
      setMediaNextPage(result.data.data.allNewsMedia.pageInfo);
    });
  }

  const meta = {
    title: 'CAPS | News & Media',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!loadedCommonData || !newsAndMediaData) ?
        <CAPSLoader logo_image={logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />
          <SmallPageTitle
            title="News and Media"
            subtitle="Our work as published in the press"
          />
          {
            newsAndMediaData &&
            <NewsAndMediaTiles tileData={newsAndMediaData} setMediaModalOpen={setMediaModalOpen} setMediaModalData={setMediaModalData}/>
          }
          <div className={common_styles.content_no_top_margin}>
            {mediaNextPage.hasNextPage && (
                <div
                  onClick={() => moreMediaPosts()}
                  className={styles.next_button}
                >
                  Load More Posts
                </div>
            )}
          </div>        
          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default NewsAndMediaAll;
