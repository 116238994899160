import danTsai from '../resources/quotes/DanTsai.jpg';
import alokSuchitra from '../resources/quotes/AlokSuchitra.jpg';
import godrej from '../resources/quotes/Godrej.jpg';
import styles from './css-modules/quotes.module.css';
import Slider from "react-slick";
import YouTube from "react-youtube-embed";

const quotesData = [
  {
    quoter: "Daniel Tsai",
    image: danTsai,
    designation: "Chairman Fubon Group",
    quote: "Caps’ high quality research on Asia’s Social Sector provides valuable learning to address today’s relevant challenges"
  },
  {
    quoter: "Jamshyd Godrej",
    image: godrej,
    designation: "Chairman, Godrej & Boyce",
    quote: "CAPS helps philanthropists understand where philanthropy should go and how to get the most impact from it."
  },
  {
    quoter: "Aloke and Suchitra Lohia",
    image: alokSuchitra,
    designation: "Founders, IVL Foundation",
    quote: "CAPS’ research helps all of us learn what works and what doesn’t so as to utilize our resources in the best way possible."
  }
];

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7500,
  cssEase: "linear"
};

function Quotes(props){
  return (
    <section className={styles.quote_section}>
      {props.quoteBool && <Slider {...settings}>
        {quotesData.length > 0 && quotesData.map((quote, index) =>
          <div key={index} className={styles.quote_container}>
            <figure className={styles.quoter_image}>
              <img src={quote.image} alt={quote.quoter}/>
            </figure>
            <div className={styles.quote_contain}>
              <p className={styles.quote}>{quote.quote}</p>
              <p className={styles.quoter}>
                <span className="quoterName">
                  {quote.quoter}
                </span>
                <span className="quoterDesig">
                  {` | ${quote.designation}`}
                </span>
              </p>
            </div>
          </div>
        )}
      </Slider>}

      {!props.quoteBool && <div className={styles.flexed_video_container}>
        <div className={styles.yt_centered}>
            <div className={styles.yt_contain}>
              <YouTube id="w5u7PGkp07c" />
            </div>
          </div>
          <div className={styles.yt_centered}>
            <div className={styles.yt_contain}>
              <YouTube id="R6YHgPYbyf4" />
            </div>
          </div>
      </div>}
    </section>
  )
}

export default Quotes;
