import styles from './css-modules/small_page_title.module.css';
import classNames from 'classnames';
import door_one from '../../resources/doors/small/door_one.jpg';
import door_two from '../../resources/doors/small/door_two.jpg';
import door_three from '../../resources/doors/small/door_three.jpg';

function AsianVoiceTitle({ content }) {
  return (
    <div className={styles.title_container}>
      <div className={classNames(styles.title_text_container, styles.left_align)}>
        <div className={styles.asian_voices_flexed_content}>
          <div className={styles.asian_voices_image} style={{background: `url(${content.asianPeopleInfo.coverImage.mediaItemUrl})`, backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
          <div className={styles.asian_voices_content}>
            <h1 className={classNames(styles.voice_title, "heading")}>{content.title}</h1>
            <div className={styles.flexed_role}>
              {content.asianVoicesTypes.nodes.length > 0 && content.asianVoicesTypes.nodes.map((type, index) =>
                <p className={styles.types} key={type.name}>{type.name}</p>
              )}
            </div>
            <p className={styles.voices_info}>{content.asianVoices_moreInfo.titleAdditional}</p>
            <p className={styles.voices_info}>{content.asianVoices_moreInfo.organizationAdditional}</p>
            <p className={styles.country}>{content.asianVoices_moreInfo.countryAdditional}</p>
          </div>
        </div>
      </div>
      <div className={classNames(styles.title_image_container, styles.asian_voice_images)}>
        <img src={door_one} className={styles.title_image}></img>
        <img src={door_two} className={styles.title_image}></img>
        <img src={door_three} className={styles.title_image}></img>
        <div className={styles.image_gradient_overlay}></div>
      </div>
    </div>
  );
}

export default AsianVoiceTitle;
