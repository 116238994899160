import { useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import styles from "./css-modules/about.module.css";
import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import SideHeader from "../components/SideHeader.js";
import ResponsiveAnnR from "../components/responsiveSliderAnnR.js";
import TeamMember from "../components/view-specific/TeamMember.js";
import PageTitle from "../components/view-specific/page_title.js";
import Approach from "../components/view-specific/about/approach.js";
import door from "../resources/doors/door_one.jpeg";
import YouTube from "react-youtube-embed";
import AboutDesc from "../components/view-specific/about/about_description";
import Fade from "@mui/material/Fade";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import DocumentMeta from 'react-document-meta';
import Quotes from "../components/Quotes.js";

function About({
  loadedAboutData,
  setAboutState,
  aboutData,
  setModalData,
  setModalOpen,
  loadedCommonData,
  logo,
}) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about" });
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (!loadedAboutData) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query MyQuery {
              people(first: 50) {
                nodes {
                  empType {
                    type
                    orderId
                  }
                  details {
                    companyName
                    countryRegion
                    title
                    picture {
                      sourceUrl
                    }
                  }
                  content
                  title
                  uri
                }
              }
              page(id: "cG9zdDoyMA==") {
                title
                template {
                  ... on Template_AnnualReports {
                    templateName
                    annualReportList {
                      reportsList {
                        cover {
                          mediaItemUrl
                        }
                        fileTitleName
                        fileToDownload {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
              page2: page(id: "cG9zdDoyMDcz") {
                title
                template {
                  ... on Template_Careers {
                    templateName
                    careers {
                      currentVacancies {
                        office
                        popupDescription
                        role
                      }
                    }
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        let resultNodes = result.data.data.people.nodes;
        let teamData = resultNodes
          .filter(function (node) {
            return node.empType.type === "Our Team";
          })
          .sort(function (a, b) {
            return a.empType.orderId - b.empType.orderId;
          });
        let boardData = resultNodes
          .filter(function (node) {
            return node.empType.type === "Board of Governors";
          })
          .sort(function (a, b) {
            return a.empType.orderId - b.empType.orderId;
          });
        let advisoryData = resultNodes
          .filter(function (node) {
            return node.empType.type === "Advisory Board";
          })
          .sort(function (a, b) {
            return a.empType.orderId - b.empType.orderId;
          });
        let annReportsData =
          result.data.data.page.template.annualReportList.reportsList;

        let openVacancies =
          result.data.data.page2.template.careers.currentVacancies;

        let finalArr = [
          { type: "board", data: boardData },
          { type: "advisory", data: advisoryData },
          { type: "team", data: teamData },
          { type: "allD", data: resultNodes },
          { type: "annReports", data: annReportsData },
          { type: "vacancies", data: openVacancies },
        ];
        setAboutState(finalArr);

        if (location.state) {
          if (location.state && location.state.uri_slug) {
            let node = resultNodes.filter(function (node) {
              return node.uri.includes(location.state.uri_slug);
            })[0];
            setModalData(node);
            setModalOpen(true);
            history.push({
              pathname: `/about/people/${location.state.uri_slug}`,
              state: {
                background: location,
              },
            });
          }
        }
      });
    }
  }, [location.state]);

  const meta = {
    title: 'CAPS | About Us',
    description: 'Established in 2013, the Centre for Asian Philanthropy and Society (CAPS) is committed to improving the quantity and quality of philanthropic giving throughout Asia. Our mission is to improve the social investment sector in Asia by identifying and disseminating best practices, models, policies and strategies that can contribute to positive system change.',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!loadedCommonData || !loadedAboutData ? (
        <CAPSLoader logo_image={logo}/>
      ) : (
        <Fade in={true}>
          <div>
          <div className={common_styles.view_content_container}>
            <Navbar
              logo_image={logo}
              navigation_array={[
                { text: "about us", route: "about", type: "standard" },
                { text: "OUR WORK", route: "work", type: "standard" },
                { text: "RESOURCES", route: "resources", type: "standard" },
                { text: "CONTACT US", route: "contact", type: "standard" },
                {
                  text: "NEWSLETTER",
                  type: "button",
                  function: "subscription",
                },
              ]}
            />

            <PageTitle
              title="About Us"
              desc="Established in 2013, the Centre for Asian Philanthropy and Society (CAPS) is committed to improving the quantity and quality of philanthropic giving throughout Asia. Our mission is to improve the social investment sector in Asia by identifying and disseminating best practices, models, policies and strategies that can contribute to positive system change."
              titleImage={door}
            />

            <section className={styles.colored_background}>
              <div className={styles.yt_centered}>
                <div className={styles.yt_contain}>
                  <YouTube id="JiYKvBEVNQw" />
                </div>

                <div className={styles.brochure_button} onClick={() => window.open('https://wordpress.caps.org/wp-content/uploads/2017/10/CAPS-2pager.pdf')}>
                  <p className={styles.broch_p}>CAPS Brochure</p>
                </div>
              </div>

              <Approach />
            </section>

            <section className={styles.grey_colored_background}>
              <AboutDesc />
              <div className={common_styles.flexed_container}>
                <SideHeader header="Board of Governors" />
                <div className={common_styles.side_content_section}>
                  <p className={styles.section_desc}>
                    Our Governors and Advisory board members are leading
                    philanthropists from Asia who share a deep understanding of
                    how philanthropy and other types of private social
                    investment can bring about important positive impact in our
                    community and in the larger world.
                  </p>
                  <div className={styles.team_member_container}>
                    {aboutData &&
                      aboutData.length > 0 &&
                      aboutData[0].data.map((member, index) => (
                        <TeamMember
                          setModalOpen={setModalOpen}
                          setModalData={setModalData}
                          member={member}
                          index={index}
                          key={`team-member${index}`}
                        />
                      ))}
                  </div>
                </div>
              </div>

              <div className={common_styles.flexed_container}>
                <SideHeader header="Advisory Board" />
                <div className={common_styles.side_content_section}>
                  <div className={styles.team_member_container}>
                    {aboutData &&
                      aboutData.length > 0 &&
                      aboutData[1].data.map((member, index) => (
                        <TeamMember
                          setModalOpen={setModalOpen}
                          setModalData={setModalData}
                          member={member}
                          index={index}
                          key={`team-member${index}`}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </section>

            <div className={common_styles.content_container}>
              <div className={common_styles.flexed_container}>
                <SideHeader header="Our Team" />
                <div className={common_styles.side_content_section}>
                  <p className={styles.section_desc}>
                    Our multinational team has in-depth experience in the
                    philanthropic and non-profit sectors and committed to
                    providing world-class research and advisory services. With
                    our Governors and Advisory board members’ guidance on the
                    strategy, integrity and vision of our organization, we work
                    to improve the quality of life for those living in Asia.
                  </p>
                  <div className={styles.team_member_container}>
                    {aboutData &&
                      aboutData.length > 0 &&
                      aboutData[2].data.map((member, index) => (
                        <TeamMember
                          setModalOpen={setModalOpen}
                          setModalData={setModalData}
                          member={member}
                          index={index}
                          key={`team-member${index}`}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quotes quoteBool={true} />
          <div>
            <div className={classNames(common_styles.content_container, common_styles.background_grey)}>
              <div className={common_styles.flexed_container}>
                <SideHeader header="Annual Reports" />
                <div className={common_styles.side_content_section}>
                  <p className={styles.section_desc}>
                    The Centre for Asian Philanthropy and Society was
                    incorporated and registered as a charitable organization in
                    Hong Kong in 2013.
                  </p>
                  {aboutData.filter((d) => d.type === "annReports")[0] ? (
                    <ResponsiveAnnR
                      contentArray={
                        aboutData.filter((d) => d.type === "annReports")[0].data
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className={common_styles.content_container}>
              <div className={common_styles.flexed_container}>
                <SideHeader header="Careers" />
                <div className={common_styles.side_content_section}>
                  <div className={styles.section_desc}>
                    <p className={styles.para_zero_top_margin}>
                      CAPS takes pride in being a pioneer in philanthropic
                      thought leadership in Asia. We endeavor to maintain an
                      invigorating, challenging, and healthy work environment
                      that values individuality, fosters collaboration, and
                      encourages personal and professional growth for all team
                      members.
                    </p>
                    <p>
                      From time to time, we offer research and non-research
                      opportunities for individuals who share our vision and
                      commitment to improving the world that we live in. {}We
                      invite you to peruse the current openings below.
                    </p>
                  </div>
                  {aboutData.filter((d) => d.type === "vacancies")[0] && (
                    <ul className={styles.rolesList}>
                      {aboutData.filter((d) => d.type === "vacancies")[0].data &&
                        aboutData.filter((d) => d.type === "vacancies")[0].data.map((vac, idx) => (
                          <Link to={`/careers/${vac.role.replaceAll(' ', '-')}`} key={`vac-${idx}`}>
                            <li key={`vac-${idx}`}>
                              <span className={styles.role}>{vac.role}</span>
                              <span
                                className={styles.office}
                              >{` (${vac.office})`}</span>
                            </li>
                          </Link>
                        ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
          </div>
        </Fade>
      )}
    </div>
  );
}

export default About;
