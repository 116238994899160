import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './css-modules/resources.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import SmallPageTitle from '../components/view-specific/small_page_title.js';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import PostRenderer from '../components/view-specific/post_renderer.js';
import Fade from '@mui/material/Fade';
import FadeRR from 'react-reveal/Fade';
import {GoChevronDown} from 'react-icons/go';
import {AiOutlineClose} from 'react-icons/ai';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function SearchResults(props) {

  const [searchTerm, setSearchTerm] = useState('');
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [activeFilters, setActiveFilters] = useState('');
  const [caps_searchResults, setCAPSSearchResults] = useState([]);
  const [caps_nextPage, setCAPSNextPage] = useState(false);
  const [other_searchResults, setOtherSearchResults] = useState([]);
  const [other_nextPage, setOtherNextPage] = useState(false);
  const [asianvoice_results, setAsianVoiceResults] = useState([]);
  const [asianvoice_next, setAsianVoiceNext] = useState(false);
  const [CAPSOnly, setCAPSOnly] = useState(false);
  const [showFilters, setFiltersShown] = useState(false);

  const [economyFilter, setEconomyFilter] = useState([]);
  const [topicsFilter, setTopicFilter] = useState([]);
  const [typesFilter, setTypesFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });

    let locationPath = location.pathname.split('/');

    locationPath = locationPath[locationPath.length - 1];
    let searchTerm = locationPath.replaceAll("_", " ");

    if (searchTerm.includes('&&capsonly')) {
      setCAPSOnly(true);
      searchTerm = searchTerm.split('&&')[0];
    }

    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allCapsResearch(first: 6, where: {search: "${searchTerm}"}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                allResearchType {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
              }
            }
            allOtherResearch(first: 6, where: {search: "${searchTerm}"}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
            allAsianVoices(first: 2, where: {search: "${searchTerm}"}) {
              nodes {
                asianVoicesTypes {
                  nodes {
                    taxonomyName
                    name
                  }
                }
                title
                content
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
          }                
        `
      }
    }).then((result) => {
      setCAPSSearchResults(result.data.data.allCapsResearch.nodes);
      setOtherSearchResults(result.data.data.allOtherResearch.nodes);
      setAsianVoiceResults(result.data.data.allAsianVoices.nodes)
      setSearchTerm(searchTerm);
      setSearchLoaded(true);
      setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
      setOtherNextPage(result.data.data.allOtherResearch.pageInfo);
      setAsianVoiceNext(result.data.data.allAsianVoices.pageInfo)
    });
    window.scrollTo(0,0);
  }, []);

  function capsResearchCheckBox() {
    setCAPSOnly(!CAPSOnly);
    if (location.pathname.includes("&&capsonly")) {
      let newPath = location.pathname.replaceAll("&&capsonly", "");
      history.push({
        pathname: newPath,
      });
    }
    else {
      let newPath = location.pathname + "&&capsonly";
      history.push({
        pathname: newPath,
      });
    }
  }

  function onSearchTermChange(event) {
    setSearchTerm(event.target.value);
  }

  function updateSearchTerm() {
    let locationPath = location.pathname.split('/');
    locationPath = locationPath[locationPath.length - 1];
    let searchTermPath = locationPath.replaceAll("_", " ");

    if (searchTermPath.includes('&&capsonly')) {
      setCAPSOnly(true);
      searchTermPath = searchTermPath.split('&&')[0];
    }
    
    if (searchTermPath !== searchTerm) {
      history.push({
        pathname: location.pathname.replaceAll(searchTermPath.replaceAll(" ", "_"), searchTerm.replaceAll(" ", "_"))
      });
      setSearchLoaded(false);

      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              allCapsResearch(first: 6, where: {search: "${searchTerm}"}) {
                nodes {
                  title
                  uri
                  date
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro 
                    subtitle
                  }
                  allResearchType {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage
                }
              }
              allOtherResearch(first: 6, where: {search: "${searchTerm}"}) {
                nodes {
                  title
                  uri
                  date
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro 
                    subtitle
                  }
                  languages {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage              
                }
              }
              allAsianVoices(first: 2, where: {search: "${searchTerm}"}) {
                nodes {
                  asianVoicesTypes {
                    nodes {
                      taxonomyName
                      name
                    }
                  }
                  title
                  content
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro
                    subtitle
                  }
                  languages {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage              
                }
              }
            }                
          `
        }
      }).then((result) => {
        setCAPSSearchResults(result.data.data.allCapsResearch.nodes);
        setOtherSearchResults(result.data.data.allOtherResearch.nodes);
        setAsianVoiceResults(result.data.data.allAsianVoices.nodes);
        setSearchTerm(searchTerm);
        setSearchLoaded(true);
        setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
        setOtherNextPage(result.data.data.allOtherResearch.pageInfo);
        setAsianVoiceNext(result.data.data.allAsianVoices.pageInfo);
      });
    }
  }

  function moreCAPSPosts() {
    let currentMoreState = '';
    if (activeFilters !== '') {
      currentMoreState = `{search: "${searchTerm}", taxQuery: {taxArray: ${activeFilters}}}`
    } 
    else {
      currentMoreState = `{search: "${searchTerm}"}`
    }

    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allCapsResearch( first: 6, after: "${caps_nextPage.endCursor}", where: ${currentMoreState}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                allResearchType {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
              }
            }
          }                
        `
      }
    }).then((result) => {
      setCAPSSearchResults(caps_searchResults.concat(result.data.data.allCapsResearch.nodes));
      setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
    });
  }

  function moreOtherPosts() {
    let currentMoreState = '';
    if (activeFilters !== '') {
      currentMoreState = `{search: "${searchTerm}", taxQuery: {taxArray: ${activeFilters}}}`
    } 
    else {
      currentMoreState = `{search: "${searchTerm}"}`
    }

    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allOtherResearch( first: 6, after: "${other_nextPage.endCursor}", where: ${currentMoreState}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
          }                
        `
      }
    }).then((result) => {
      setOtherSearchResults(other_searchResults.concat(result.data.data.allOtherResearch.nodes));
      setOtherNextPage(result.data.data.allOtherResearch.pageInfo);
    });
  }

  function moreAsianPosts() {
    let currentMoreState = '';
    if (activeFilters !== '') {
      currentMoreState = `{search: "${searchTerm}", taxQuery: {taxArray: ${activeFilters}}}`
    } 
    else {
      currentMoreState = `{search: "${searchTerm}"}`
    }

    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allAsianVoices( first: 2, after: "${asianvoice_next.endCursor}", where: ${currentMoreState}) {
              nodes {
                asianVoicesTypes {
                  nodes {
                    taxonomyName
                    name
                  }
                }
                title
                content
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
                asianVoices_moreInfo {
                  countryAdditional
                  organizationAdditional
                  titleAdditional
                }
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
          }                
        `
      }
    }).then((result) => {
      setAsianVoiceResults(asianvoice_results.concat(result.data.data.allAsianVoices.nodes));
      setAsianVoiceNext(result.data.data.allAsianVoices.pageInfo);
    });
  }

  function openFilter() {
    setFiltersShown(true);
  }

  function closeFilter() {
    setFiltersShown(false);
  }

  function setEconomiesFilter(event) {
    if (economyFilter.includes(event.target.id)) {
      setEconomyFilter(economyFilter.filter(filterItem => filterItem !== event.target.id));
    }
    else {
      setEconomyFilter([...economyFilter, event.target.id]);
    }
  }

  function setTopicsFilter(event) {
    if (topicsFilter.includes(event.target.id)) {
      setTopicFilter(topicsFilter.filter(filterItem => filterItem !== event.target.id));
    }
    else {
      setTopicFilter([...topicsFilter, event.target.id]);
    }
  }

  function setTypeFilter(event) {
    if (typesFilter.includes(event.target.id)) {
      setTypesFilter(typesFilter.filter(filterItem => filterItem !== event.target.id));
    }
    else {
      setTypesFilter([...typesFilter, event.target.id]);
    }
  }

  function setLangFilter(event) {
    if (languageFilter.includes(event.target.id)) {
      setLanguageFilter(languageFilter.filter(filterItem => filterItem !== event.target.id));
    }
    else {
      setLanguageFilter([...languageFilter, event.target.id]);
    }
  }

  function clearFilters() {
    setLanguageFilter([]);
    setTypesFilter([]);
    setTopicFilter([]);
    setEconomyFilter([]);

    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allCapsResearch(first: 6, where: {search: "${searchTerm}"}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                allResearchType {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
              }
            }
            allOtherResearch(first: 6, where: {search: "${searchTerm}"}) {
              nodes {
                title
                uri
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
            allAsianVoices(first: 2, where: {search: "${searchTerm}"}) {
              nodes {
                asianVoicesTypes {
                  nodes {
                    taxonomyName
                    name
                  }
                }
                title
                content
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro
                  subtitle
                }
                languages {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
                asianVoices_moreInfo {
                  countryAdditional
                  organizationAdditional
                  titleAdditional
                }
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage              
              }
            }
          }                
        `
      }
    }).then((result) => {
      setCAPSSearchResults(result.data.data.allCapsResearch.nodes);
      setOtherSearchResults(result.data.data.allOtherResearch.nodes);
      setAsianVoiceResults(result.data.data.allAsianVoices.nodes);
      setFiltersShown(false);
      setActiveFilters('');
      setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
      setOtherNextPage(result.data.data.allOtherResearch.pageInfo);
      setAsianVoiceNext(result.data.data.allAsianVoices.pageInfo);
    });
  }

  function applyFilters() {
    if (economyFilter.length > 0 || typesFilter.length > 0 || topicsFilter.length > 0 || languageFilter.length > 0) {
      let locationPath = location.pathname;
      let newPath = location.pathname;

      if (location.pathname.includes('&&capsonly')) {
        newPath.replace('&&capsonly', '');
      }

      if (!location.pathname.includes('filter&&')) {
        newPath += 'filter&&';
      }

      let economyStrings = '';
      if (economyFilter.length > 0) {
        for (let i = 0; i < economyFilter.length; i++) {
          if (economyFilter.length === 1) {
            economyStrings += '"' + economyFilter[0] + '"';
          }
          else {
            if (i === 0) {
              economyStrings += "[" + '"' + economyFilter[i] + '"' + ",";
            }
            else if (i === economyFilter.length -  1) {
              economyStrings += '"' + economyFilter[i] + '"' + "]";
            }
            else {
              economyStrings += '"' + economyFilter[i] + '"' + ','
            }
          }
        }
      }

      let typesStrings = '';
      if (typesFilter.length > 0) {
        for (let i = 0; i < typesFilter.length; i++) {
          if (typesFilter.length === 1) {
            typesStrings += '"' + typesFilter[0] + '"';
          }
          else {
            if (i === 0) {
              typesStrings += "[" + '"' + typesFilter[i] + '"' + ",";
            }
            else if (i === typesFilter.length -  1) {
              typesStrings += '"' + typesFilter[i] + '"' + "]";
            }
            else {
              typesStrings += '"' + typesFilter[i] + '"' + ','
            }
          }
        }
      }

      let topicsStrings = '';
      if (topicsFilter.length > 0) {
        for (let i = 0; i < topicsFilter.length; i++) {
          if (topicsFilter.length === 1) {
            topicsStrings += '"' + topicsFilter[0] + '"';
          }
          else {
            if (i === 0) {
              topicsStrings += "[" + '"' + topicsFilter[i] + '"' + ",";
            }
            else if (i === topicsFilter.length -  1) {
              topicsStrings += '"' + topicsFilter[i] + '"' + "]";
            }
            else {
              topicsStrings += '"' + topicsFilter[i] + '"' + ','
            }
          }
        }
      }

      let languageStrings = '';
      if (languageFilter.length > 0) {
        for (let i = 0; i < languageFilter.length; i++) {
          if (languageFilter.length === 1) {
            languageStrings += '"' + languageFilter[0] + '"';
          }
          else {
            if (i === 0) {
              languageStrings += "[" + '"' + languageFilter[i] + '"' + ",";
            }
            else if (i === languageFilter.length -  1) {
              languageStrings += '"' + languageFilter[i] + '"' + "]";
            }
            else {
              languageStrings += '"' + languageFilter[i] + '"' + ','
            }
          }
        }
      }

      let taxonomyString = '';

      if (languageStrings !== '' && topicsStrings !== '' && typesStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (languageStrings !== '' && topicsStrings !== '' && typesStrings !== '') {
        taxonomyString = `[{taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (topicsStrings !== '' && typesStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (languageStrings !== '' && typesStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}]`
      }
      else if (languageStrings !== '' && topicsStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (languageStrings !== '' && topicsStrings !== '') {
        taxonomyString = `[{taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (languageStrings !== '' && typesStrings !== '') {
        taxonomyString = `[{taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}]`
      }
      else if (languageStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}]`
      }
      else if (topicsStrings !== '' && typesStrings !== '') {
        taxonomyString = `[{taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (topicsStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (typesStrings !== '' && economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}, {taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}]`
      }
      else if (languageStrings !== '') {
        taxonomyString = `[{taxonomy: LANGUAGE, terms: ${languageStrings}, operator: IN, field: SLUG}]`
      }
      else if (topicsStrings !== '') {
        taxonomyString = `[{taxonomy: TOPIC, terms: ${topicsStrings}, operator: IN, field: SLUG}]`
      }
      else if (typesStrings !== '') {
        taxonomyString = `[{taxonomy: CONTENTTYPE, terms: ${typesStrings}, operator: IN, field: NAME}]`
      }
      else if (economyStrings !== '') {
        taxonomyString = `[{taxonomy: ECONOMY, terms: ${economyStrings}, operator: IN, field: SLUG}]`
      }

      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              allCapsResearch(first: 6, where: {search: "${searchTerm}", taxQuery: {taxArray: ${taxonomyString}}}) {
                nodes {
                  title
                  uri
                  date
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro 
                    subtitle
                  }
                  allResearchType {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage
                }
              }
              allOtherResearch(first: 6, where: {search: "${searchTerm}", taxQuery: {taxArray: ${taxonomyString}}}) {
                nodes {
                  title
                  uri
                  date
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro 
                    subtitle
                  }
                  languages {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage              
                }
              }
              allAsianVoices(first: 2, where: {search: "${searchTerm}", taxQuery: {taxArray: ${taxonomyString}}}) {
                nodes {
                  asianVoicesTypes {
                    nodes {
                      taxonomyName
                      name
                    }
                  }
                  title
                  content
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro
                    subtitle
                  }
                  languages {
                    nodes {
                      name
                    }
                  }
                  economies {
                    nodes {
                      name
                    }
                  }
                  topics {
                    nodes {
                      name
                    }
                  }
                  uri
                  asianVoices_moreInfo {
                    countryAdditional
                    organizationAdditional
                    titleAdditional
                  }
                }
                pageInfo {
                  startCursor
                  hasPreviousPage
                  endCursor
                  hasNextPage              
                }
              }
            }                
          `
        }
      }).then((result) => {
        setCAPSSearchResults(result.data.data.allCapsResearch.nodes);
        setOtherSearchResults(result.data.data.allOtherResearch.nodes);
        setAsianVoiceResults(result.data.data.allAsianVoices.nodes);
        setActiveFilters(taxonomyString);
        setFiltersShown(false);
        setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
        setOtherNextPage(result.data.data.allOtherResearch.pageInfo); 
        setAsianVoiceNext(result.data.data.allAsianVoices.pageInfo);
      }); 
    }
    else {
      setFiltersShown(false);
    }
  }

  const meta = {
    title: 'CAPS | Search - ' + searchTerm,
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!props.loadedCommonData || !searchLoaded) ?
         <CAPSLoader logo_image={props.logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <SmallPageTitle
              title="Search Results"
              subtitle="Browse our latest content"
          />

          <div className={common_styles.content_container}>
            <div className={styles.search_bar_container}>
              <p className={styles.curr_search_term}>Change Search Term</p>
              <div className={styles.search_select_container}>
                  <TextField onChange={(e) => onSearchTermChange(e)} className={styles.searchBox} defaultValue={searchTerm} id="update-search" label="Search Term" variant="standard" />
                  <div onClick={() => updateSearchTerm()} className={styles.search_button}>
                      <p className={styles.search_button_text}>Search</p>
                  </div>
              </div>
              <div className={styles.search_check_box}>
                  <p className={styles.checkbox_text}>Show CAPS research only</p>
                  <Checkbox onChange={() => capsResearchCheckBox()} aria-label="Show CAPS research only" checked={CAPSOnly} />
              </div>
            </div>
          </div>

          <div className={styles.filter_menu_container}>
            <div className={common_styles.content_no_top_margin}>
              <div className={styles.filter_dropdowns}>
                <div onClick={() => openFilter()} className={styles.filter_dropdown}>
                  <p>Economies</p> 
                  <GoChevronDown/> 
                </div>
                <div onClick={() => openFilter()} className={styles.filter_dropdown}>
                  <p>Topics</p> 
                  <GoChevronDown/> 
                </div>
                <div onClick={() => openFilter()} className={styles.filter_dropdown}>
                  <p>Content Types</p>
                  <GoChevronDown/> 
                </div>
                <div onClick={() => openFilter()} className={styles.filter_dropdown}>
                  <p>Languages</p> 
                  <GoChevronDown/> 
                </div>
              </div>
            </div>
          </div>

           <Fade in={showFilters}>
             <div className={styles.filters_container}>
                <div className={common_styles.content_container}>
                  <div className={styles.filters_bg_container}>
                    <p className={styles.filter_heading}>Filter Results</p>
                    <AiOutlineClose onClick={() => closeFilter()} className={styles.close_icon}/>
                    <div className={styles.buttons_container}>
                      <div onClick={() => applyFilters()} className={styles.apply_button}>
                        <p className={styles.button_title}>Apply Filters</p>
                      </div>
                      <div onClick={() => clearFilters()} className={styles.reset_button}>
                        <p className={styles.button_title}>Reset Filters</p>
                      </div>
                    </div>
                    <div className={styles.filters_container_inner}>
                      <div className={styles.filter_section_long}>
                        <p className={styles.filter_title}>Economies</p>
                        <div className={styles.filter_list_container}>
                          {props.commonData[0].data.nodes.length > 0 && props.commonData[0].data.nodes.map((economy, index) => {
                            return <div className={styles.filter_checkbox} key={`filterTopic_sr${index}`}>
                              <Checkbox onClick={(event) => setEconomiesFilter(event)} inputProps={{'id': economy.slug}} checked={economyFilter.includes(economy.slug)} />
                              <p className={styles.filter_label}>{economy.name}</p>
                            </div>
                          })}
                        </div>
                      </div>

                      <div className={styles.filter_section_long}>
                        <p className={styles.filter_title}>Topics</p>
                        <div className={styles.filter_list_container}>
                          {props.commonData[2].data.nodes.length > 0 && props.commonData[2].data.nodes.map((topic, index) => {
                              return <div className={styles.filter_checkbox} key={`filterTopic2_sr${index}`}>
                                <Checkbox onClick={(event) => setTopicsFilter(event)} inputProps={{'id': topic.slug}} checked={topicsFilter.includes(topic.slug)}/>
                                <p className={styles.filter_label}>{topic.name}</p>
                              </div>
                            })}
                        </div>
                      </div>
                      <div className={styles.filter_section}>
                        <p className={styles.filter_title}>Types</p>
                        <div className={styles.filter_list_container}>
                          {props.commonData[3].data.nodes.length > 0 && props.commonData[3].data.nodes.map((topic, index) => {
                            return <div key={`filterTopic3_sr${index}`}>
                                {topic.name !== 'HomePost' && <div className={styles.filter_checkbox}>
                                  <Checkbox onClick={(event) => setTypeFilter(event)} inputProps={{'id': topic.name}} checked={typesFilter.includes(topic.name)} />
                                  <p className={styles.filter_label}>{topic.name}</p>
                                </div>}
                            </div>
                          })}
                        </div>
                      </div>
                      <div className={styles.filter_section}>
                        <p className={styles.filter_title}>Languages</p>
                        <div className={styles.filter_list_container}>
                          {props.commonData[4].data.nodes.length > 0 && props.commonData[4].data.nodes.map((topic, index) => {
                                return <div className={styles.filter_checkbox} key={`filterTopic4_sr${index}`}>
                                  <Checkbox onClick={(event) => setLangFilter(event)} inputProps={{'id': topic.slug}} checked={languageFilter.includes(topic.slug)} />
                                  <p className={styles.filter_label}>{topic.name}</p>
                                </div>
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Fade>

          {/*

          <div onClick={() => openFilter()} className={styles.fixed_filter_button}>
            <Fab className={styles.fixed_filterButton} variant="extended">
              <BsFilterLeft className={styles.filter_icon} />
              Filter
            </Fab>
          </div> */}

          <div className={common_styles.content_container}>
          {caps_searchResults.length > 0 && 
              <div className={styles.posts_section_container}>
                <h2 className={styles.research_section}>Research by CAPS</h2>
                <div className={styles.post_container}>
                  {caps_searchResults.map((result, index) => {         
                      return <div key={index}>
                        <PostRenderer content={result} type='our-research' index={index}/>
                      </div>
                  })}
                </div>
                {caps_nextPage.hasNextPage && <div onClick={() => moreCAPSPosts()} className={styles.next_button}>Load More Posts</div>}
              </div>
          }

          {!CAPSOnly && 
            <FadeRR when={!CAPSOnly}>
              {other_searchResults.length > 0 && 
                  <div className={styles.posts_section_container}>
                    <h2 className={styles.research_section}>External Resources</h2>
                    <div className={styles.post_container}>
                      {other_searchResults.map((result, index) => {
                        return <div key={index}>
                          <PostRenderer content={result} type='other-research' index={index}/>
                        </div>
                      })}
                    </div>
                    {other_nextPage.hasNextPage && <div onClick={() => moreOtherPosts()} className={styles.next_button}>Load More Posts</div>}
                  </div>
              }
            </FadeRR>
          }

          {asianvoice_results.length > 0 && 
              <div className={styles.posts_section_container}>
                <h2 className={styles.research_section}>Asian Voices</h2>
                <div className={styles.post_container}>
                  {asianvoice_results.map((result, index) => {         
                      return <div key={index}>
                        <PostRenderer content={result} type='asian-voice' index={index}/>
                      </div>
                  })}
                </div>
                {asianvoice_next.hasNextPage && <div onClick={() => moreAsianPosts()} className={styles.next_button}>Load More Posts</div>}
              </div>
          }



          {((CAPSOnly && caps_searchResults.length === 0) || (!CAPSOnly && caps_searchResults.length === 0 && other_searchResults.length === 0 && asianvoice_results.length === 0))
            && 
            <div className={styles.no_posts}>
              <p className={styles.no_post_heading}>No Results Found</p>
            </div>
          }
          
          </div>




          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default SearchResults;
