import styles from "./css-modules/contact.module.css";
import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import Fade from "@mui/material/Fade";
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import { useEffect } from "react";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function PrivacyPolicy(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/privacypolicy` });
    window.scrollTo(0,0);
  }, [])

  const meta = {
    title: 'CAPS | Privacy Policy',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!props.loadedCommonData ? 
        <CAPSLoader logo_image={props.logo}/>
        :
        <Fade in={true}>
            <div className={common_styles.view_content_container}>
                <Navbar
                logo_image={props.logo}
                navigation_array={[
                    { text: "about us", route: "about", type: "standard" },
                    { text: "OUR WORK", route: "work", type: "standard" },
                    { text: "RESOURCES", route: "resources", type: "standard" },
                    { text: "CONTACT US", route: "contact", type: "standard" },
                    { text: "NEWSLETTER", type: "button", function: "subscription" },
                ]}
                />

                <SmallPageTitle
                    title="Privacy Policy"
                    subtitle="Centre for Asian Philanthropy and Society"
                />

                <div className={common_styles.content_container}>
                    <p className={styles.paragraph}>CAPS may collect information and data about you, from which your identity may be ascertained, during your visit of our website (“Personal Data”). CAPS is subject to and will comply with the Personal Data (Privacy) Ordinance in Hong Kong.</p>
                    <p className={styles.paragraph}>Your Personal Data will only be used for the specific purposes for which the data is collected and/or as stated at the time of collection or their directly related purposes (“Purposes”). CAPS may be unable to carry out such Purposes if you do not provide the necessary information and particulars.</p>
                    <p className={styles.paragraph}>CAPS may also generate and compile information and collect information from publicly available sources about you. Personal information and particulars provided by you and all information collected, generated and compiled by us about you from time to time is collectively referred to as “Personal Data”.</p>
                    <p className={styles.paragraph}>We and our data processors may use your Personal Data for sending you materials concerning our activities or services. We will however not engage in direct marketing except with your prior written consent as required by law. CAPS may also share information or statistics with third parties in an aggregated or anonymised form that does not directly identify you (e.g. we may share aggregated information about your preferences and location with advertisers for marketing purposes). You are entitled to request access to or copy of your Personal Data held by us or request correction of any incorrect or inaccurate data. We may charge a reasonable fee for processing your request.</p>
                    <p className={styles.paragraph}>Please send all such requests or requests for information regarding policies and practices and kinds of Personal Data held by us to info@caps.org.</p>
                </div>

                <Footer />
            </div>
        </Fade>
      }
    </div>
  );
}

export default PrivacyPolicy;
