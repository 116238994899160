import styled, { keyframes } from "styled-components";
import LandingDoors from "../LandingDoors.js";

const fadeAnim = keyframes`
    0% {opacity: 1;}
    42% {opacity: 1;}
    50% {opacity: 0;}
    92% {opacity: 0;}
    100% {opacity: 1;}
`;

const headerAnim = keyframes`
    0% {letter-spacing: 0px;}
    40% {letter-spacing: 1.5px;}
    100% {letter-spacing: 0px; }
`;

const headerDescAnim = keyframes`
    0% {transform: translateY(-20px); opacity: 0;}
    70% {transform: translateY(-20px); opacity: 0;}
    100% {transform: translateY(0px); opacity: 1}
`;

const landingDoorsAnimLeft = keyframes`
    0% {border-width: 120px;}
    40% {border-width: 120px; }
    100% {border-width: 0px; }
`;

const landingDoorsAnimRight = keyframes`
    0% {border-width: 175px; }
    63% {border-width: 175px; }
    100% {border-width: 0px; }
`;

const navHeight = 110;
const navHeight50 = 100;
const navHeight40 = 100;

const StyledLandingDoors = styled(LandingDoors)`
  min-height: calc(100vh - 110px);
  position: relative;

  & h1,
  p {
    text-align: center;
  }

  & h1 {
    color: #ffffff;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    font-size: 2.1rem;
    margin: 0px 5px;
    margin-bottom: 0px;
    animation: ${headerAnim} 2s 1;
    animation-delay: 0.5s;
  }

  & p {
    color: #ffffff;
    max-width: 80ch;
    font-size: 1rem;
    animation: ${headerDescAnim} 2.5s 1;
  }

  & .indigo-foreground {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0px;
    background-image: linear-gradient(
      to bottom,
      #172f58bb,
      #172f58ff 70%,
      #172f58ff 100%
    );
    width: 100vw;
    padding: 10px 0px;
    z-index: 2;
  }

  & .door-image-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: calc(100vh - 110px);
    animation-name: ${fadeAnim};
    animation-iteration-count: infinite;
    animation-duration: 15s;
  }

  & .door-image-stack > div {
    position: relative;
  }

  & .door-image-stack > div::before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-color: #ffffffee;
    border-style: solid;
    border-width: 0px;
    content: "";
  }

  & .door-image-stack > div.left::before {
    animation: ${landingDoorsAnimLeft} 1s 1;
    animation-timing-function: ease-in-out;
  }

  & .door-image-stack > div.right::before {
    animation: ${landingDoorsAnimRight} 1.5s 1;
    animation-timing-function: ease-in-out;
  }

  & .stack1 {
    position: relative;
    z-index: 1;
  }

  & .stack2 {
    position: absolute;
    top: 0px;
    z-index: 0;
    width: 100vw;
    animation-delay: 7500ms;
  }

  & .image-contain {
    width: 50%;
    min-height: 75vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  & .navItem_button {
    background: #ffcb05;
    padding: 8px 13px;
    margin: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 12px 3px rgb(0 0 0 / 6%);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.4s;
    max-width: 175px;
  }

  & .navItem_button:hover {
    background: #2484c6;
  }

  & .navItem_text {
    font-size: 14px;
    margin: 3px 0px;
    font-weight: 500;
    color: #00196d;
    text-transform: uppercase;
  }

  & .navItem_button > .navItem_text {
    color: #5c607f;
    transition: all 0.4s;
  }

  & .navItem_button:hover > .navItem_text {
    color: #fff;
  }

  // tab styles

  @media (max-width: 60em) {
    & h1 {
      font-size: 1.8rem;
      margin: 0px 4px;
      padding: 0px 10px;
    }

    & p {
      font-size: 0.95rem;
      padding: 0px 30px;
    }

    & .door-image-stack {
      min-height: calc(100vh - 90px);
    }
  }

  // mobile styles

  @media (max-width: 40em) {
    & h1 {
      font-size: 1.7rem;
      margin: 0px 2.5px;
    }

    & p {
      font-size: 0.9rem;
      padding: 0px 30px;
    }

    & .door-image-stack > div.right {
      display: none;
    }

    & .image-contain {
      width: 100%;
    }

    & .door-image-stack {
      min-height: calc(100vh - 70px);
    }
  }
`;

export default StyledLandingDoors;
