import styles from "./css-modules/footer.module.css";
import classNames from "classnames";
import flat_logo from "../resources/caps_logo.png";
import TextField from "@mui/material/TextField";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { IoLogoWechat } from "react-icons/io5";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import qrcode from '../resources/qrcode_we_chat.jpg';
import Fade from "@mui/material/Fade";

function openLink(e, link) {
  e.preventDefault();
  window.open(link);
}

function Footer() {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [incompleteErr, setIncompleteErr] = useState(false);
  const [successFul, setSuccessFul] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [mailChimpReq, setMailChimpReq] = useState(null);
  const [weChat, openWeChatModel] = useState(false);

  function resetText() {
    setFName("");
    setLName("");
    setEmail("");
    setOrganization("");
  }

  function setValueState(event, id) {
    if (id === "fname") {
      setFName(event.target.value);
    } else if (id === "lname") {
      setLName(event.target.value);
    } else if (id === "organization") {
      setOrganization(event.target.value);
    } else if (id === "email") {
      setEmail(event.target.value);
    }
  }

  useEffect(() => {
    mailChimpReq &&
      axios
        .post(
          "https://18.163.120.145.nip.io/api/addMember",
          mailChimpReq
        )
        .then(function (response) {
          if (response.data.statusCode === 200) {
            //console.log("success");
            setSuccessFul(true);
            setAlreadyExists(false);
            setIncompleteErr(false);
            setDisableInputs(false);
            resetText();
            alert("Successfully subscribed");
          } else if (response.data.statusCode === 400) {
            //console.log("Already exists");

            setAlreadyExists(true);
            setIncompleteErr(false);
            setSuccessFul(false);
            setDisableInputs(false);
            resetText();
            alert("You are already subscribed");
          } else {
            //console.log("Error");
            setIncompleteErr(true);
            setSuccessFul(false);
            setAlreadyExists(false);
            setDisableInputs(false);
            alert("YFailed to submit. Please make sure all information is provided (email is correctly formatted) and try again");
          }
        });
  }, [mailChimpReq]);

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function submitContactForm() {
    if (email === "" || organization === "" || fName === "" || lName === "") {
      setIncompleteErr(true);
      setSuccessFul(false);
      alert("Failed to submit. Please make sure all information is provided (email is correctly formatted) and try again");
    } else {
      setDisableInputs(true);
      if (validateEmail(email)) {
        let req = {
          email: email,
          firstName: fName,
          lastName: lName,
          organization: organization,
        };
        setMailChimpReq(req);
      } else {
        setDisableInputs(false);
        setIncompleteErr(true);
        setSuccessFul(false);
      }
    }
  }

  return (
    <footer className={styles.footer_container}>
      <div className={styles.form_logo_flexed}>
        <div className={styles.form_parent}>
          <h1 className={classNames(styles.footer_heading, "heading")}>
            Subscribe to our newsletter
          </h1>
          <div className={styles.form_container}>
            <div className={styles.textfield_container}>
              <TextField
                className={styles.textfield}
                value={fName}
                onChange={(event) => setValueState(event, "fname")}
                required
                disabled={disableInputs}
                id="first_name"
                label="First Name"
                variant="standard"
              />
            </div>
            <div className={styles.textfield_container}>
              <TextField
                className={styles.textfield}
                value={lName}
                onChange={(event) => setValueState(event, "lname")}
                required
                disabled={disableInputs}
                id="last_name"
                label="Last Name"
                variant="standard"
              />
            </div>
            <div className={styles.textfield_container}>
              <TextField
                className={styles.textfield}
                value={email}
                onChange={(event) => setValueState(event, "email")}
                required
                disabled={disableInputs}
                id="email"
                label="Email"
                variant="standard"
              />
            </div>
            <div className={styles.textfield_container}>
              <TextField
                className={styles.textfield}
                value={organization}
                onChange={(event) => setValueState(event, "organization")}
                required
                disabled={disableInputs}
                id="organization"
                label="Organization"
                variant="standard"
              />
            </div>
          </div>
          <div
            className={styles.subscribe_button}
            onClick={() => submitContactForm()}
          >
            <p className={styles.button_text}>Subscribe</p>
          </div>
        </div>
        <div className={styles.logo_container}>
          <img src={flat_logo} className={styles.footer_logo}></img>
        </div>
      </div>

      <div className={styles.copyright_social_container}>
        <div className={styles.copyright_container}>
          <p className={styles.copyright_message}>
            {new Date().getFullYear()} © Centre for Asian Philanthropy and
            Society. All Rights Reserved.
          </p>
        </div>
        <div className={styles.social_navigation_container}>
          <div className={styles.social_icons_container}>
            <FaInstagram
              className={styles.social_icon}
              onClick={(e) =>
                openLink(e, "https://www.instagram.com/capsasia/")
              }
            />
            <FaFacebookF
              className={styles.social_icon_longer}
              onClick={(e) => openLink(e, "https://www.facebook.com/capsasia/")}
            />
            <FaTwitter
              className={styles.social_icon}
              onClick={(e) => openLink(e, "https://twitter.com/caps_asia")}
            />
            <FaYoutube
              className={styles.social_icon}
              onClick={(e) =>
                openLink(
                  e,
                  "https://www.youtube.com/channel/UCalxz6pdanDvK7xtH95Rj2Q"
                )
              }
            />
            <FaLinkedin
              className={styles.social_icon_longer}
              onClick={(e) =>
                openLink(e, "https://www.linkedin.com/company/capsasia/")
              }
            />
            <MdMail
              className={styles.social_icon}
              onClick={(e) => openLink(e, "mailto:research@caps.org")}
            />
            <IoLogoWechat
              className={styles.social_icon_longer}
              onClick={() => openWeChatModel(true)}
            />
          </div>
          <div className={styles.navigation_container}>
            <Link to="/termsofuse" style={{ textDecoration: "none" }}>
              <div className={styles.footer_navItem_container}>
                <p className={styles.footer_navItem}>Terms of Use</p>
              </div>
            </Link>
            <div className={styles.footer_nav_separator}></div>
            <Link to="/privacypolicy" style={{ textDecoration: "none" }}>
              <div className={styles.footer_navItem_container}>
                <p className={styles.footer_navItem}>Privacy Policy</p>
              </div>
            </Link>
            <div className={styles.footer_nav_separator}></div>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <div className={styles.footer_navItem_container}>
                <p className={styles.footer_navItem}>Contact Us</p>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <Fade in={weChat}>
        <div className={styles.weChatModal} onClick={() => openWeChatModel(false)}>
          <div className={styles.modal_we_container}>
            <p className={styles.modal_title}>
              Join us on WeChat
            </p>
            <img src={qrcode} className={styles.qr_code}></img>
          </div>
        </div>
      </Fade>
    </footer>
  );
}

export default Footer;
