import styles from '../css-modules/about_desc.module.css';
import about_image from '../../../resources/about_image.jpg';

function AboutDesc() {
  return (
    <div className={styles.about_desc}>
     { <div className={styles.image_container}>
        <img src={about_image} alt="" className={styles.image_a}></img>
      </div>}
      <div className={styles.info_container}>
        <h2 className={styles.info_title}>Our People</h2>
        <div className={styles.info_text}>
          <p>Our multinational team has in-depth experience in the philanthropic and nonprofit sectors and a shared belief in the importance of civic engagement.</p>
          <p>Our work is entirely based on rigorous, evidence-based research. Our team is committed to providing world-class research and advisory services.</p>
        {/*  <p>Our Governors and Advisory Board members are leading philanthropists from Asia who share a deep understanding of how philanthropy and other types of private social investment can bring about important positive impact in our communities and in the larger world. They guide the strategy, integrity, and vision of our organization as we work to improve the quality of life for those living in Asia.</p> */}
        </div> 
      </div>
    </div>
  );
}

export default AboutDesc;
