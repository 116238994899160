import * as d3 from "d3";
import { Link, useLocation } from "react-router-dom";

export default function VoronComp({
  d3Projection,
  voronoiExt,
  pointData,
  circleRadius,
  onMouseEnterFunc,
  onMouseLeaveFunc,
  setModalOpen,
  setModalData,
  partnersData
}) {
  const location = useLocation();
  // define an voronoi function
  const cellXY = {
    x: (d) => d3Projection([+d.Lon, +d.Lat])[0],
    y: (d) => d3Projection([+d.Lon, +d.Lat])[1],
  };

  const points = pointData.map((d) => [d.x, d.y]);
  const delaunay = d3.Delaunay.from(points);
  const voronoi = delaunay.voronoi(voronoiExt);

  const voronPointData = Array.from(voronoi.cellPolygons());
  const voronPoints = voronPointData.map((entry, index) => ({
    points: entry,
    data: pointData[index],
  }));

  function getEconEv(e){
    return e.target.className.baseVal.split(" ")[1];
  }

  function getPartnerData(data, econ){
    return data.filter(d => d.id === econ)[0].partners
  }

  function openModal(modalData){
    setModalOpen(true);
    setModalData(modalData);
  }

  return (
    <>
      <defs>
        {voronPoints.map((point) => (
          <clipPath
            className="clip"
            id={`clip-index${point.data.Id}`}
            key={`clip-index${point.data.Id}`}
          >
            <path
              className="clip-path-circle"
              key={`clip-path-index${point.data.Id}`}
              d={
                point.points !== undefined ? `M${point.points.join(",")}Z` : ""
              }
            />
          </clipPath>
        ))}
      </defs>
      <g id="CCatcher-Grp">
        {pointData.map((point) => (
          <Link
            to={{
              pathname: `/partners/${point.Id}`,
              state: { background: location },
            }}
            key={`link${point.Id}`}
          >
            <circle onClick={e => openModal(getPartnerData(partnersData, getEconEv(e)))}
              key={`index${point.Id}`}
              className={`circle-catcher ${point.Id}`}
              clipPath={`url(#clip-index${point.Id})`}
              style={{
                clipPath: `url(#clip-index${point.Id})`,
                fill: "grey",
                fillOpacity: 0,
                pointerEvents: "all",
                cursor: "pointer",
              }}
              cx={point.x}
              cy={point.y}
              r={circleRadius}
              onMouseEnter={onMouseEnterFunc}
              onMouseLeave={onMouseLeaveFunc}
            />
          </Link>
        ))}
      </g>
    </>
  );
}

function getCircleId(classNme) {
  return classNme.replace("circle-catcher ", "");
}
