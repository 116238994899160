import styles from "./css-modules/newsandmediatiles.module.css";
import { timeParse, timeFormat } from "d3";
import { Link, useLocation, useHistory } from "react-router-dom";

function getDate(datetimeString, formatted) {
  const dateString = datetimeString.split("T")[0];
  const date = timeParse("%Y-%m-%d")(dateString);
  const formattedDate = timeFormat("%d %b, %Y")(date);
  return formatted ? formattedDate : dateString;
}

function removeWS(string){
  return string.replace(/ /g,'')
}

function NewAndMediaTiles({ tileData, setMediaModalData, setMediaModalOpen }) {
  const location = useLocation();

  function linkClick(idx) {
    setMediaModalData(tileData[idx]);
    setMediaModalOpen(true);
  }

  return (
    <section className={styles.media_tiles_container}>
      {tileData.map((media, idx) => (
        <Link
          to={{
            pathname: `/newsandmedia/${media.uri.split('/')[media.uri.split('/').length - 2]}`,
            state: { background: location, myID: idx },
          }}
          onClick={() => linkClick(idx)}
          key={`newsItem${idx}`}
          className={styles.news_card}
        >
            <div className={styles.news_img_header}>
              <div
                className={styles.image_contain}
                style={{
                  backgroundImage: `url(${media.asianPeopleInfo.coverImage.mediaItemUrl})`,
                  backgroundSize: "contain",
                }}
              ></div>
              <h3 className={styles.news_header}>
                {media.asianPeopleInfo.subtitle}
              </h3>
            </div>
            <div className={styles.desc_news_card}>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p className="resource-subtitle">
                {`${media.title} `}
                <span className={styles.news_date}>{`| ${getDate(
                  media.date,
                  true
                )}`}</span>
              </p>
            </div>
        </Link>
      ))}
    </section>
  );
}

export default NewAndMediaTiles;
