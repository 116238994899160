import styles from './css-modules/insights.module.css';
import common_styles from './css-modules/views.module.css';
import classNames from 'classnames';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Fade from '@mui/material/Fade';
import SmallPageTitle from '../components/view-specific/small_page_title.js';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

function filterInsights(insightsData, title, property){
  if (property === 'content') {
    return insightsData.filter(
      (item, idx) => item.title === title
    )[0][property];
  }
  else {
    if (insightsData.filter((item, idx) => item.title === title)[0].insights_new.optImage) {
      console.log(insightsData.filter(
        (item, idx) => item.title === title
      )[0]);
      return insightsData.filter(
        (item, idx) => item.title === title
      )[0].insights_new.optImage[property];
    } 
  }
}

function getClassName(insightsData, title, property){
  if (insightsData.filter((item, idx) => item.title === title)[0].insights_new.optImage) {
    let x = insightsData.filter(
      (item, idx) => item.title === title
    )[0].insights_new.optImage.mediaDetails;

    if (x.width / x.height > 1.15) {
      return 'landImage'
    }
    else if (x.width / x.height < 1.15 && x.width / x.height > 0.85) {
      return 'square'
    }
    else if (x.width / x.height < 0.85) {
      return 'portrait'
    }
  }
}



function compare( a, b ) {
  if ( a.title < b.title ){
    return -1;
  }
  if ( a.title > b.title ){
    return 1;
  }
  return 0;
}


function Insights({loadedCAPSInsights, logo, setInsightData, loadedCommonData, insightData}) {

  const [insightTitle, setInsightTitle] = useState("Blended Finance");

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/resources/glossary` });
    if (!loadedCAPSInsights) {
      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              allCapsInsights(first: 100) {
                nodes {
                  title
                  content
                  insights_new {
                    optImage {
                      mediaDetails {
                        file
                        height
                        width
                      }
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          `
        }
      }).then((result) => {
          const nodes = result.data.data.allCapsInsights.nodes;
          nodes.sort(compare)
          setInsightData(nodes);
      });
    }
    window.scrollTo(0,0);

  }, []);

  const meta = {
    title: 'CAPS Glossary',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!loadedCommonData || !loadedCAPSInsights) ?
        <div className={common_styles.loader_container}>
          <CAPSLoader logo_image={logo}/>
        </div>
        :
        <Fade in={true}>
          <div className={common_styles.view_content_container}>
            <Navbar
              logo_image={logo}
              navigation_array = {[
                {text: 'about us', route: 'about', type: 'standard'},
                {text: 'OUR WORK', route: 'work', type: 'standard'},
                {text: 'RESOURCES', route: 'resources', type: 'standard'},
                {text: 'CONTACT US', route: 'contact', type: 'standard'},
                {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
              ]}
            />
            <SmallPageTitle
              title="CAPS’ Glossary"
              subtitle=""
            />
            <div className={common_styles.content_container}>
              <div className={styles.select_container}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <FormHelperText>Select a term</FormHelperText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty                    
                    value={insightTitle}
                  >
                    {insightData.length > 0 && insightData.map((item, index) => 
                      <MenuItem 
                        key={index} 
                        id={item.title} 
                        value={item.title}
                        onClick={e => setInsightTitle(e.target.getAttribute("id"))}
                      >{item.title}</MenuItem>
                    )}
                  </Select>
                  </FormControl>
                </div>
              <div className={styles.insights_container}>
                <div className={styles.insights_list}>
                  {insightData.length > 0 && insightData.map((item, index) =>
                    <div
                      key={`insight${index}`}
                      className={item.title == insightTitle ? classNames(styles.insight_title, styles.insight_title_active) : styles.insight_title}
                      onClick={e => setInsightTitle(e.target.getAttribute("id"))}
                      id={item.title}
                    >{item.title}
                    </div>
                  )}
                </div>
                {insightData.length > 0 ?
                  <div className={styles.insights_content}>
                    <h1 className={classNames(styles.insights_title, "heading")}>{insightTitle}</h1>
                    <div className={styles.title_separator}></div>
                    {filterInsights(insightData, insightTitle, 'mediaItemUrl') && <img className={styles[getClassName(insightData, insightTitle, 'mediaItemUrl')]} src={filterInsights(insightData, insightTitle, 'mediaItemUrl')}></img>} 
                    <div className={styles.insights_desc} dangerouslySetInnerHTML={{ __html: filterInsights(insightData, insightTitle, 'content')}}>
                    </div>
                  </div>
                  :
                  <div className={styles.insights_content}>
                  </div>
                }
              </div>
            </div>

            <Footer/>
          </div>
        </Fade>
      }
    </div>
  );
}

export default Insights;
