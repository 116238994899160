import styles from './css-modules/small_page_title.module.css';
import classNames from 'classnames';
import door_one from '../../resources/doors/small/door_one.jpg';
import door_two from '../../resources/doors/small/door_two.jpg';
import door_three from '../../resources/doors/small/door_three.jpg';

function SmallPageTitle(props) {
  return (
    <div className={styles.title_container}>
      <div className={styles.title_text_container}>
        <div className={styles.title_white_bg}>
          <h1 className={classNames(styles.title, "heading")}>{props.title}</h1>
          <div className={styles.title_separator}></div>
          <p className={styles.subtitle}>{props.subtitle}</p>
        </div>
      </div>
      <div className={styles.title_image_container}>
        <img src={door_one} className={styles.title_image}></img>
        <img src={door_two} className={styles.title_image}></img>
        <img src={door_three} className={styles.title_image}></img>
      </div>
    </div>
  );
}

export default SmallPageTitle;
