import { useLocation } from "react-router-dom";
import styles from "./css-modules/blog.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import CAPSLoader from "../components/capsLoader.js";
import common_styles from "./css-modules/views.module.css";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import Fade from "@mui/material/Fade";
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import DocumentMeta from 'react-document-meta';

function Blog({ loadedCommonData, logo }) {
  let location = useLocation();
  const [blogData, setBlogData] = useState(false);

  useEffect(() => {
    if (!blogData) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query MyQuery {
              insightBlogs {
                nodes {
                  asianPeopleInfo {
                    coverImage {
                      mediaItemUrl
                    }
                    intro
                    subtitle
                  }
                  uri
                  title
                  content
                  blogID {
                    id
                  }
                  blogAuthor {
                    author {
                      ... on Person {
                        id
                        details {
                          picture {
                            mediaItemUrl
                          }
                        }
                        title
                      }
                    }
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        const blogData = result.data.data.insightBlogs.nodes;
        console.log(blogData);
        const pathname = location.pathname.split("/");
        setBlogData(
          blogData.filter((d) =>
            d.blogID.id.includes(pathname[pathname.length - 1])
          )[0]
        );
      });
    }
  }, []);

  const meta = {
    title: blogData.title ? blogData.title : '',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      {<DocumentMeta {...meta} />}
      {!loadedCommonData || !blogData ? (
        <div className={common_styles.loader_container}>
           <CAPSLoader logo_image={logo}/>
        </div>
      ) : (
        <Fade in={true}>
          <div className={common_styles.view_content_container}>
            <Navbar
              logo_image={logo}
              navigation_array={[
                { text: "about us", route: "about", type: "standard" },
                { text: "OUR WORK", route: "work", type: "standard" },
                { text: "RESOURCES", route: "resources", type: "standard" },
                { text: "CONTACT US", route: "contact", type: "standard" },
                {
                  text: "NEWSLETTER",
                  type: "button",
                  function: "subscription",
                },
              ]}
            />
            <SmallPageTitle title={"CAPS Blog"} subtitle="" />
            <div className={styles.content_container}>
              {blogData.blogAuthor.author && <div style={{display: 'none'}} className={styles.image_and_authorinfo}>
                <div
                  className={styles.image_author}
                  style={{
                    backgroundImage: `url(${blogData.blogAuthor.author.details.picture.mediaItemUrl})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <div className={styles.author_and_title}>
                  <h3 className={styles.title}>{blogData.title}</h3>
                  <p className={styles.author}>
                    {blogData.blogAuthor.author.title}
                  </p>
                </div>
              </div>}

              <div className={styles.blog_content_contain}>
                <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
              </div>
              <div
                className={styles.image_insights}
                style={{
                  backgroundImage: `url(${blogData.asianPeopleInfo.coverImage.mediaItemUrl})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>

            <Footer />
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Blog;
