import styles from "./css-modules/newsletter.module.css";
import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import axios from "axios";
import { useEffect } from "react";
import { timeFormat, timeParse, group } from "d3";
import classNames from "classnames";
import Fade from "@mui/material/Fade";
import DocumentMeta from "react-document-meta";
import ReactGA from "react-ga4";
import React  from "react";

function getDate(datetimeString) {
  const dateString = datetimeString.split("T")[0];
  const date = timeParse("%Y-%m-%d")(dateString);
  const year = timeFormat("%Y")(date);
  const month = timeFormat("%B")(date);
  return { year, month };
}

function getLinkID(uri) {
  let customizeUri = uri.slice(0, -1);
  customizeUri = customizeUri.substring(1);
  customizeUri = customizeUri.replaceAll("/", "&");
  return customizeUri;
}

const monthNames = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const notCoolLinks = [
  'http://eepurl.com/gMjvBb',
  'http://eepurl.com/g7Eby9',
  'http://eepurl.com/g9lijP'
]

function sortMonths(monthArr) {
  return monthArr.sort(function (a, b) {
    // sort based on the value in the monthNames object
    return monthNames[a] - monthNames[b];
  });
}

function Newsletter({
  loadedCommonData,
  logo,
  newsletterData,
  setNewsletterData,
}) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/resources/newsletter` });
    if (!newsletterData) {
      axios
        .post(
          "https://18.163.120.145.nip.io/api/getCampaigns", null
        )
        .then(function (response) {
          // remove any entries with no send time

          let newsletterArrMc = response.data.campaigns.filter(d => d.send_time !== "");
          newsletterArrMc.forEach((d) => {  
            d.dateString = d.send_time.split("T")[0];
            d.endDate = getDate(d.send_time);
          });

          // two step filtering
          // remove campaigns with less than 100 recipients
          // remove campaigns for report launches
          newsletterArrMc = newsletterArrMc
            .filter(d => d.emails_sent > 100)
            .filter(d => !(notCoolLinks.includes(d.archive_url)))

          const newsletterArrMcByYrMon = group(
            newsletterArrMc,
            d => d.endDate.year,
            d => d.endDate.month
          );
          setNewsletterData(newsletterArrMcByYrMon);
        });
    }
    window.scrollTo(0, 0);
  }, []);

  const meta = {
    title: "CAPS Spotlight",
    description: "",
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta} />
      {!loadedCommonData || !newsletterData ? (
        <div className={common_styles.loader_container}>
           <CAPSLoader logo_image={logo}/>
        </div>
      ) : (
        <Fade in={true}>
          <div className={common_styles.view_content_container}>
            <Navbar
              logo_image={logo}
              navigation_array={[
                { text: "about us", route: "about", type: "standard" },
                { text: "OUR WORK", route: "work", type: "standard" },
                { text: "RESOURCES", route: "resources", type: "standard" },
                { text: "CONTACT US", route: "contact", type: "standard" },
                {
                  text: "NEWSLETTER",
                  type: "button",
                  function: "subscription",
                },
              ]}
            />

            <SmallPageTitle
              title="Newsletter"
              subtitle="CAPS Spotlight | Who's Doing Good?"
            />

            {newsletterData && (
              <section className={styles.newsletter_issues_contain}>
                {Array.from(newsletterData.keys()).sort((a, b) => b - a).map((year, idx) => (
                  <React.Fragment key={`fragment${year}`}>
                    <h2 className={styles.year_header} key={`header${year}`}>{year}</h2>
                    <div className={styles.yearly_issue_contain} key={`container${year}`}>
                      {sortMonths(
                        Array.from(newsletterData.get(year).keys())
                      ).map((month, idx) => (
                        <div
                          className={classNames(
                            styles.monthly_issue_contain,
                            [1, 2, 3, 4, 5, 6].includes(idx + 1)
                              ? styles.first_6
                              : styles.last_6
                          )}
                          key={`${year}${month}`}
                        >
                          <h3 className={styles.month_header}>{month}</h3>
                          {newsletterData
                            .get(year)
                            .get(month)
                            .sort((a, b) => new Date(a.dateString) - new Date(b.dateString))
                            .map((issue, idx) => (
                              <a
                                href={`${issue.archive_url}`}
                                target="_blank"
                                className={styles.issue_link}
                                key={issue.id}
                              >
                                <span className={styles.issue_text} key={`span${issue.id}`}>{`Issue ${
                                  idx + 1
                                }`}</span>
                              </a>
                            ))}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </section>
            )}
            <Footer />
          </div>
        </Fade>
      )}
    </div>
  );
}

export default Newsletter;
