//import styles from './css-modules/google_map.module.css';
import styles from './css-modules/google_map.module.css';

function GoogleMap() {
  return (
    <div className={styles.map_container}>
      <iframe className={styles.iframe} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4047.908145778717!2d114.16967220078324!3d22.280283142997433!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcd56a29ea424b696!2sCentre%20for%20Asian%20Philanthropy%20and%20Society!5e0!3m2!1sen!2sus!4v1641773290613!5m2!1sen!2sus" width="100%" height="100%"></iframe>
    </div>
  );
}

export default GoogleMap;
