import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import ConveningTiles from "../components/conveningTiles";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import { useEffect } from "react";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import axios from 'axios';
import Fade from '@mui/material/Fade';

function Convening(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/resources` });
    window.scrollTo(0,0);

    if (!props.conveningLoaded) {
        axios({
          url: "https://wordpress.caps.org/graphql",
          method: "post",
          data: {
            query: `
                query MyQuery {
                    conveningPageData {
                    nodes {
                        title
                        content
                        imageData {
                        image {
                            mediaItemUrl
                        }
                        }
                    }
                    }
                }       
            `,
          },
        }).then((result) => {
            props.setConveningData(result.data.data.conveningPageData.nodes);
            props.setConveningLoaded(true);
        });
    }


  }, [])

  const meta = {
    title: 'CAPS | Convening',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!props.loadedCommonData || !props.conveningLoaded) ? 
        <CAPSLoader logo_image={props.logo}/>
        
        :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
            <Navbar
            logo_image={props.logo}
            navigation_array={[
                { text: "about us", route: "about", type: "standard" },
                { text: "OUR WORK", route: "work", type: "standard" },
                { text: "RESOURCES", route: "resources", type: "standard" },
                { text: "CONTACT US", route: "contact", type: "standard" },
                {
                text: "NEWSLETTER",
                type: "button",
                function: "subscription",
                },
            ]}
            />

            <SmallPageTitle
                title="Convening"
                subtitle=""
            />

            <ConveningTiles layout={"grid"} content={props.conveningData}/>

            <Footer />
        </div>
      </Fade>
      }
    </div>
  );
}

export default Convening;
