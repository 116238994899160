import styles from './css-modules/post.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Fade from '@mui/material/Fade';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import ReportSubModal from '../components/view-specific/report_sub_modal.js';

function Post(props) {
  const [pageContent, setPageContent] = useState({});
  const [linkButtons, setLinkButtons] = useState([]);
  const [micrositeButton, setMicrositeButton] = useState({});
  const [pageHasMicrosite, setPageHasMicrosite] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  const [topicsString, setTopicsString] = useState('');
  const [economiesString, setEconomiesString] = useState('');
  const [reportSubModal, setReportSubModal] = useState(false);
  const [currReportLink, setCurrReportLink] = useState('');
  const [currReportType, setCurrReportType] = useState('');

  let location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });

    let locationArr = location.pathname.split('_');
    locationArr[0] = locationArr[0].split('/')[locationArr[0].split('/').length -1];

    if (locationArr[0] === 'our-research') {
      let uri = ''; 
      for (let i = 0; i < locationArr.length; i++) {
        uri += '/' + locationArr[i];
      }
      uri += '/';
      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query getContent {
              capsResearch(id: "${uri}", idType: URI) {
                title
                content
                asianPeopleInfo {
                  intro
                  subtitle
                  coverImage {
                    mediaItemUrl
                  }
                }
                pdfLink {
                  pdfLinks
                }
                topics(first: 20) {
                  nodes {
                    name
                  }
                }
                economies(first: 25) {
                  nodes {
                    name
                  }
                }
              }
            }
          `
        }
      }).then((result) => {
        console.log(result.data.data.capsResearch.pdfLink.pdfLinks.split(' - '));
        if (result.data.data.capsResearch.pdfLink.pdfLinks && result.data.data.capsResearch.pdfLink.pdfLinks !== '') {
        let linkButtons = result.data.data.capsResearch.pdfLink.pdfLinks.split(' - ');
        let linkButtonObjArr = []; 
        for (let i = 0; i < linkButtons.length; i++) {
          let splitValues = linkButtons[i].split(',');
          if (splitValues[1].includes('icrosite')) {
            setMicrositeButton({link: splitValues[0], title: splitValues[1]});
            setPageHasMicrosite(true);
          }
          else {
            linkButtonObjArr.push({link: splitValues[0], title: splitValues[1]});
          }
        }
        setLinkButtons(linkButtonObjArr);
      }

        let topics = '';
        let economies = '';

        for (let i = 0; i < result.data.data.capsResearch.topics.nodes.length; i++) {
          if (i === result.data.data.capsResearch.topics.nodes.length - 1) {
            topics += result.data.data.capsResearch.topics.nodes[i].name;
          }
          else {
            topics += result.data.data.capsResearch.topics.nodes[i].name + ', '
          }
        }

        for (let i = 0; i < result.data.data.capsResearch.economies.nodes.length; i++) {
          if (i === result.data.data.capsResearch.economies.nodes.length - 1) {
            economies += result.data.data.capsResearch.economies.nodes[i].name;
          }
          else {
            economies += result.data.data.capsResearch.economies.nodes[i].name + ', '
          }
        }

        setPageContent(result.data.data.capsResearch);
        setTopicsString(topics);
        setEconomiesString(economies);
        setIsContentLoaded(true);

      });
    }

    if (locationArr[0] === 'other-research') {
      let uri = locationArr[1].replaceAll('&', '/');
      uri = '/' + uri + '/';
      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query getContent {
              otherResearch(id: "${uri}", idType: URI) {
                title
                content
                asianPeopleInfo {
                  intro
                  subtitle
                  coverImage {
                    mediaItemUrl
                  }
                }
                sourceAuthor {
                  sourceAuthor
                }
                pdfLink {
                  pdfLinks
                }
                topics(first: 50) {
                  nodes {
                    name
                  }
                }
                economies(first: 50) {
                  nodes {
                    name
                  }
                }
                languages {
                  nodes {
                    name
                  }
                }
              }
            }
          `
        }
      }).then((result) => {
        let fetchedData = result.data.data.otherResearch;
        let linkButtonObjArr = []; 
        if (fetchedData.pdfLink.pdfLinks) {
          let linkButtons = fetchedData.pdfLink.pdfLinks.split(' - ');
          for (let i = 0; i < linkButtons.length; i++) {
            let splitValues = linkButtons[i].split(',');
            if (splitValues[1].includes('icrosite')) {
              setMicrositeButton({link: splitValues[0], title: splitValues[1]});
              setPageHasMicrosite(true);
            }
            else {
              linkButtonObjArr.push({link: splitValues[0], title: splitValues[1]});
            }
          }
        }
        let topics = '';
        let economies = '';

        for (let i = 0; i < fetchedData.topics.nodes.length; i++) {
          if (i === fetchedData.topics.nodes.length - 1) {
            topics += fetchedData.topics.nodes[i].name;
          }
          else {
            topics += fetchedData.topics.nodes[i].name + ', '
          }
        }

        for (let i = 0; i < fetchedData.economies.nodes.length; i++) {
          if (i === fetchedData.economies.nodes.length - 1) {
            economies += fetchedData.economies.nodes[i].name;
          }
          else {
            economies += fetchedData.economies.nodes[i].name + ', '
          }
        }

        setLinkButtons(linkButtonObjArr);
        setPageContent(fetchedData);
        setTopicsString(topics);
        setEconomiesString(economies);
        setIsContentLoaded(true);
      });
    }
    window.scrollTo(0,0);
  },[]);

  const meta = {
    title: pageContent.title ? pageContent.title : '',
    description: '',
  };

  function processButtonClick(title, link) {
    if (!title.replaceAll(' ', '').toLowerCase().includes('pressrelease')) {
      setCurrReportType(title);
      if (title.replaceAll(' ', '').toLowerCase().includes('report')) {
        setCurrReportType('Report');
      }
      if (title.replaceAll(' ', '').toLowerCase().includes('executivesummary')) {
        setCurrReportType('Executive Summary');
      }
      setCurrReportLink(link);
      setReportSubModal(true);
    }
    else {
      window.open(link);
    } 
  }

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!props.loadedCommonData || !isContentLoaded) ?
        <CAPSLoader logo_image={props.logo}/>
      :
      <Fade in={isContentLoaded}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <div className={common_styles.content_container}>
            <div className={styles.flexed_content_container}>
              <div className={styles.image_container}>
                <div className={styles.title_image}>
                  <img className={styles.title_image_img} src={pageContent.asianPeopleInfo.coverImage.mediaItemUrl}></img>

                </div>
                <div className={styles.buttons_container}>
                  {linkButtons && linkButtons.length > 0 && linkButtons.map((buttonData, index) => {
                    return (
                      <div key={index} role="button" className={styles.button} onClick={() => processButtonClick(buttonData.title ,buttonData.link)}> 
                        <p className={styles.button_text}>{buttonData.title}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className={styles.post_content_container}>
                  <h1 className={styles.title}>{pageContent.title}</h1>
                  <p className={styles.subtitle}><i>{pageContent.asianPeopleInfo.subtitle}</i></p>

                  {pageHasMicrosite && <div onClick={() => window.open(micrositeButton.link)} role="button" className={styles.microsite_button}>{micrositeButton.title}</div>}

                  <div className={styles.topics_economies_container}>
                    {pageContent.topics.nodes.length > 0 && 
                      <p className={styles.topics_economy}>
                        <b>{pageContent.topics.nodes.length > 1 ? 'Topics: ': 'Topic: '}</b>
                        {topicsString}
                      </p>
                    }

                    {pageContent.economies.nodes.length > 0 && 
                      <p className={styles.topics_economy}>
                        <b>{pageContent.economies.nodes.length > 1 ? 'Economies: ': 'Economy: '}</b>
                        {economiesString}
                      </p>
                    }

                    <p className={styles.topics_economy}>
                      {pageContent.sourceAuthor ? <span><b>By: </b> {pageContent.sourceAuthor.sourceAuthor} </span> : <span><b>By: </b> Centre for Asian Philanthropy and Society </span>}
                    </p>

                    <div className={styles.separator}></div>

                    <div className={styles.wp_content_container}>
                      <div dangerouslySetInnerHTML={{ __html: pageContent.content}}></div>
                    </div>

                  </div>
              </div>
            </div>
          </div>

          <Footer/>

          <ReportSubModal setReportSubModal={setReportSubModal} reportSubModal={reportSubModal} currReportLink={currReportLink} reportTitle={pageContent.title} currReportType={currReportType}/>
        </div>
      </Fade>

      }
    </div>
  );
}

export default Post;
