import styles from "./css-modules/contact.module.css";
import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import Fade from "@mui/material/Fade";
import SmallPageTitle from "../components/view-specific/small_page_title.js";
import { useEffect } from "react";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function TermsOfUse(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/termsofuse` });
    window.scrollTo(0,0);
  }, [])

  const meta = {
    title: 'CAPS | Terms of Use',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!props.loadedCommonData ? 
        <CAPSLoader logo_image={props.logo}/>
        :
        <Fade in={true}>
            <div className={common_styles.view_content_container}>
                <Navbar
                logo_image={props.logo}
                navigation_array={[
                    { text: "about us", route: "about", type: "standard" },
                    { text: "OUR WORK", route: "work", type: "standard" },
                    { text: "RESOURCES", route: "resources", type: "standard" },
                    { text: "CONTACT US", route: "contact", type: "standard" },
                    { text: "NEWSLETTER", type: "button", function: "subscription" },
                ]}
                />

                <SmallPageTitle
                    title="Terms of Use"
                    subtitle="Centre for Asian Philanthropy and Society"
                />

                <div className={common_styles.content_container}>
                    <p className={styles.paragraph}>The information provided in this website is subject to the following terms and conditions which you accept when you visit or access this website.</p>
                    <div className={styles.seperator}></div>
                    <h2 className={styles.heading}>Legal Disclaimer</h2>
                    <p className={styles.paragraph}>The material contained in this web site has been produced by CAPS in accordance with our current practices and policies and with the benefit of information currently available to us, and all reasonable efforts have been made to ensure the accuracy of the contents of the pages of the website at the time of preparation. We regularly review the website and where appropriate will update pages to reflect changed circumstances.</p>
                    <p className={styles.paragraph}>Notwithstanding all efforts made by us to ensure the accuracy of the website, no responsibility or liability is accepted by us in respect of any use or reference to the website in connection with any matter or action taken following such use or reference or for any inaccuracies, omissions, mis-statements or errors in the said material, or for any economic or other loss which may be directly or indirectly sustained by any visitor to the website or other person who obtains access to the material on the website.</p>
                    <p className={styles.paragraph}>The material on this website is for general information only and nothing in this website contains professional advice or an offer for sale or any binding commitment upon CAPS in relation to the availability of property or the description of property. All visitors should take advice from a suitably qualified professional in relation to any specific query or problem that they may have or in relation to any property included in this website, and CAPS takes no responsibility for any loss caused as a result of reading the contents of this website.</p>
                    <p className={styles.paragraph}>If you visit our website, we may use cookie files to store and track information about you and your preferences. A cookie is a small text file that our website transfers to your device. The use of cookies means we can better serve you and/or maintain your information across multiple pages within or across one or more sessions. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in future. Cookies contain information about you and your preferences. For example, if you inquire about rates and availability, the site might create a cookie that contains the details you entered. Or it might only contain a record of which pages within the site you visited to help the site customise the view for you the next time you visit. Only the information that you provide or the choices you make while visiting a website can be stored in a cookie. For example, the site cannot determine your email address unless you choose to type it. Allowing a website to create a cookie does not give that or any other site access to the rest of your device, and only the site that created the cookie can read it.</p>
                    <p className={styles.paragraph}>CAPS may also collect information regarding your Internet Protocol (IP) address, browser type, domain name and access time. This information is used for our own research purposes. As it is not linked to any personal information, it is separate from your Personal Data. In rare instances, IP addresses may be used to assist in deterring and/or preventing abusive or criminal activity on the website.</p>
                    <p className={styles.paragraph}>No claims, actions or legal proceedings in connection with this website brought by any visitor or other person having reference to the material on this website will be entertained by CAPS.</p>

                    <h2 className={styles.heading}>Copyright</h2>
                    <p className={styles.paragraph}>All content included on this website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of CAPS or licensed to CAPS, and protected by Hong Kong and international copyright laws.</p>

                    <h2 className={styles.heading}>Trademarks</h2>
                    <p className={styles.paragraph}>The CAPS logo, Doing Good™ and other marks indicated on our site are registered trademarks under the laws of Hong Kong  and other countries. Our trademarks may not be used in connection with any product or service that is not CAPS’, in any manner that is likely to cause confusion among partners, clients or donors, or in any manner that disparages or discredits CAPS.  All other trademarks not owned by CAPS that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by us.</p>

                    <h2 className={styles.heading}>License and Site Access</h2>
                    <p className={styles.paragraph}>CAPS hereby grants you a limited license to access and make personal use of this website and not to modify it, or any portion of it, except with our express written consent.</p>
                    <p className={styles.paragraph}>This website or any portion of this website may not be reproduced, duplicated, uploaded, published, transmitted, distributed, copied, sold, resold, visited, or otherwise exploited  without express written consent of CAPS.  You may not create derivative works from this website or frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of CAPS without express written consent. You may not use any meta tags or any other “hidden text” utilizing our name or trademarks without our express written consent. Any unauthorized use terminates the permission or license granted by us. You are granted a limited, revocable, and nonexclusive right to create hyperlinks to the home page or other pages of our website so long as any links do not portray CAPS or its services in a false, misleading, derogatory, or otherwise offensive manner. You may not use any of our logos or other proprietary graphics or trademarks as part of the link without express written permission.</p>

                    <h2 className={styles.heading}>Links to Third Party Sites</h2>
                    <p className={styles.paragraph}>Some links posted on this website will direct you to external websites. These linked sites are not under our control and as such, CAPS is not responsible for or be taken as endorsing the contents of any linked site or any link contained in a linked site, or any changes or updates to such websites. We are not responsible for webcasting or any other form of transmission received from any linked site.</p>
                </div>

                <Footer />
            </div>
        </Fade>
      }
    </div>
  );
}

export default TermsOfUse;
