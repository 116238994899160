import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import ResourceTiles from "../components/resourceTiles.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import PageTitle from "../components/view-specific/page_title.js";
import door from "../resources/doors/door_two.jpeg";
import { useEffect } from "react";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function Resources(props) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/resources` });
    window.scrollTo(0,0);
  }, [])

  const meta = {
    title: 'CAPS | Resources',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!props.loadedCommonData && (
        <CAPSLoader logo_image={props.logo}/>
        )}

      <div className={common_styles.view_content_container}>
        <Navbar
          logo_image={props.logo}
          navigation_array={[
            { text: "about us", route: "about", type: "standard" },
            { text: "OUR WORK", route: "work", type: "standard" },
            { text: "RESOURCES", route: "resources", type: "standard" },
            { text: "CONTACT US", route: "contact", type: "standard" },
            {
              text: "NEWSLETTER",
              type: "button",
              function: "subscription",
            },
          ]}
        />

        <PageTitle
          title="Resources"
          desc="CAPS is committed to be a voice and a trusted knowledge partner in helping philanthropists, business leaders and policy makers make informed decisions to address today’s complex social issues in Asia.  Our Resources site showcases the key terms, best studies, reports, articles and videos from individuals and organizations from Asia, with an Asian perspective."
          titleImage={door}
        />

        <ResourceTiles layout={"grid"} />

        <Footer />
      </div>
    </div>
  );
}

export default Resources;
