import { useEffect, useState } from 'react';
import styles from './css-modules/asianVoicesContent.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import AsianVoiceTitle from '../components/view-specific/asian_voice_title.js';
import axios from 'axios';
import Fade from '@mui/material/Fade';
import YouTube from 'react-youtube-embed';
import {
  Link,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import DocumentMeta from 'react-document-meta';

function AsianVoices({loadedCommonData, logo}) {
  const [isContentLoaded, setLoadedContent] = useState(false);
  const [interviewContent, setInterviewContent] = useState({});
  const [Yt_ID, setYt_ID] = useState('');

  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    if (location.content) {
      console.log(location.content.youtube.link);
      location.content.youtube.link && setYt_ID(parseYoutubeURL(location.content.youtube.link));
      setInterviewContent(location.content);
      setLoadedContent(true);
    }
    else {
      let pathInfo = location.pathname.split('/');
      let nameInfo = pathInfo[pathInfo.length - 1];

      axios({
        url: 'https://wordpress.caps.org/graphql',
        method: 'post',
        data: {
          query: `
            query MyQuery {
              asianVoice(id: "asian_voices/${nameInfo}", idType: URI) {
                asianVoicesTypes {
                  nodes {
                    taxonomyName
                    name
                  }
                }
                title
                content
                youtube {
                  link
                }
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro
                  subtitle
                }
                gallery_carousel {
                  fieldGroupName
                  galleryTitle
                }
                uri
                asianVoices_moreInfo {
                  countryAdditional
                  organizationAdditional
                  titleAdditional
                }
              }
            }
          `
        }
      }).then((result) => {
        result.data.data.asianVoice.youtube.link && setYt_ID(parseYoutubeURL(result.data.data.asianVoice.youtube.link));
        setInterviewContent(result.data.data.asianVoice);
        setLoadedContent(true);
      });
    }
    window.scrollTo(0,0);
  }, []);


  function parseYoutubeURL(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      console.log('error');
    }
  }

  const meta = {
    title: interviewContent.title ? interviewContent.title : '',
    description: ''
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!loadedCommonData || !isContentLoaded) ?
        <CAPSLoader logo_image={logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <AsianVoiceTitle
            content={interviewContent}
          />

          <div className={common_styles.content_container}>
            <div className={styles.margined_content}>
              <div className={styles.content} dangerouslySetInnerHTML={{__html: interviewContent.content}}></div>
              {Yt_ID && 
              <div className={styles.yt_centered}>
                <div className={styles.yt_contain}>
                  <YouTube id={Yt_ID}/>
                </div>
              </div>}
            </div>
          </div>

          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default AsianVoices;
