import ourwork1 from '../resources/our-work/OurWork1.jpg';
import ourwork2 from '../resources/our-work/OurWork2.jpg';
import ourwork3 from '../resources/our-work/OurWork3.jpg';
import ourwork4 from '../resources/our-work/OurWork4.jpg';
import bg from '../resources/our-work/Asia3.png';
import {workTypes} from './workTypes.js';
import WorkCard from './WorkCard.js';
import styles from './css-modules/ourwork.module.css';


function OurWork({className}){
  return (
    <section className={styles.work_type_section} style={{backgroundImage: `url(${bg})`}}>
      <h2 style={{color: 'white', marginTop: '0px', padding: '50px 10px 30px 10px', fontSize: '1.8rem', textAlign: 'center'}}>OUR WORK</h2>
      <div className={styles.work_card_contain}>
          <WorkCard image={ourwork1} workTypeObj={workTypes[0]}/>
          <WorkCard image={ourwork2} workTypeObj={workTypes[1]}/>
          <WorkCard image={ourwork3} workTypeObj={workTypes[2]}/>
          <WorkCard image={ourwork4} workTypeObj={workTypes[3]}/>
      </div>
    </section>
  )

}

export default OurWork;
