import commRes1 from "../resources/our-research/comm-research1.jpg";
import commRes2 from "../resources/our-research/comm-research2.jpg";
import commRes3 from "../resources/our-research/comm-research3.jpg";
import styles from "./css-modules/comm-research.module.css";
import { Link, useLocation } from "react-router-dom";

function CommResearch() {
  const location = useLocation();

  return (
    <div className={styles.resource_card_contain}>
      <Link className={styles.resource_card}
        to={{
          pathname: `/work/comm-research1`,
          state: { background: location },
        }}
      >
        <div>
          <figure className={styles.img_resource_card}>
            <img
              src={commRes2}
              alt="Supporting image for describing resources"
              className={styles.image}
            />
          </figure>
          <div className={styles.desc_resource_card}>
            <h3>Alignment of social investment with goals and governance</h3>
            <div className={styles.separator_container}>
              <div className={styles.separator_line}></div>
              <div className={styles.separator_skewed}></div>
            </div>
            <p>Click to learn more</p>
          </div>
        </div>
      </Link>

      <Link className={styles.resource_card}
        to={{
          pathname: `/work/comm-research2`,
          state: { background: location },
        }}
      >
        <div>
          <figure className={styles.img_resource_card}>
            <img
              src={commRes3}
              alt="Supporting image for describing resources"
              className={styles.image}
            />
          </figure>
          <div className={styles.desc_resource_card}>
            <h3 className={styles.project_head}>Project and program design, evaluation and review</h3>
            <div className={styles.separator_container}>
              <div className={styles.separator_line}></div>
              <div className={styles.separator_skewed}></div>
            </div>
            <p>Click to learn more</p>
          </div>
        </div>
      </Link>

      <Link className={styles.resource_card}
        to={{
          pathname: `/work/comm-research3`,
          state: { background: location },
        }}
      >
        <div>
          <figure className={styles.img_resource_card}>
            <img
              src={commRes1}
              alt="Supporting image for describing resources"
              className={styles.image}
            />
          </figure>
          <div className={styles.desc_resource_card}>
            <h3 className={styles.customize_head}>Customized research</h3>
            <div className={styles.separator_container}>
              <div className={styles.separator_line}></div>
              <div className={styles.separator_skewed}></div>
            </div>
            <p>Click to learn more</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default CommResearch;
