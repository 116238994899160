import { useState } from 'react';
import styles from './css-modules/voice_member.module.css';
import {
  Link,
} from "react-router-dom";

function VoiceMember(props) {
  const [uri, setUri] = useState(0);
  return (
    <Link
      className={styles.team_member_link}
      to={{
        pathname: `/resources/asian_voices/${props.details.uri.split('/')[props.details.uri.split('/').length - 2]}`,
        content: props.details
      }}
    >
      <div className={styles.team_member}>
        <div className={styles.image_container} style={{background: `url(${props.details.asianPeopleInfo.coverImage.mediaItemUrl})`, backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
        <div className={styles.member_info_container}>
          <h3 className={styles.employee_name}>{props.details.title}</h3>
          {props.details.asianVoicesTypes.nodes.length > 0 && props.details.asianVoicesTypes.nodes.map((type, index) =>
            <p key={index + type.name} className={styles.types}>{type.name}</p>
          )}
        </div>
      </div>
    </Link>
  )
}

export default VoiceMember;
