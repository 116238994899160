import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './css-modules/resources.module.css';
import common_styles from './css-modules/views.module.css';
import CAPSLoader from "../components/capsLoader.js";
import Navbar from '../components/navbar.js';
import Footer from '../components/footer.js';
import SmallPageTitle from '../components/view-specific/small_page_title.js';
import axios from 'axios';
import PostRenderer from '../components/view-specific/post_renderer.js';
import Fade from '@mui/material/Fade';
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";

function PolicyResearch(props) {

  const [postsLoaded, setPostsLoaded] = useState(false);
  const [caps_searchResults, setCAPSSearchResults] = useState([]);
  const [caps_nextPage, setCAPSNextPage] = useState(false);

  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `${location.pathname}` });
    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
            query fetchPolicyResearch {
                allCapsResearch(
                first:13, where:{taxQuery: {taxArray: {taxonomy: RESEARCHTYPE, terms: "policy-research", operator: IN, field: SLUG}}}
                ) {
                nodes {
                    id
                    title
                    date
                    asianPeopleInfo {
                        intro
                        subtitle
                        coverImage {
                            mediaItemUrl
                        }
                    }
                    allResearchType {
                        nodes {
                          name
                        }
                      }
                      economies {
                        nodes {
                          name
                        }
                      }
                      topics {
                        nodes {
                          name
                        }
                      }
                      uri
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    endCursor
                    hasNextPage              
                  }
                }
            }
        `
      }
    }).then((result) => {
      setCAPSSearchResults(result.data.data.allCapsResearch.nodes);
      setPostsLoaded(true);
      setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
    });
    window.scrollTo(0,0);
  }, []);

  function moreCAPSPosts() {
    axios({
      url: 'https://wordpress.caps.org/graphql',
      method: 'post',
      data: {
        query: `
          query MyQuery {
            allCapsResearch( first: 6, after: "${caps_nextPage.endCursor}", where:{taxQuery: {taxArray: {taxonomy: RESEARCHTYPE, terms: "policy-research", operator: IN, field: SLUG}}}) {
              nodes {
                title
                date
                asianPeopleInfo {
                  coverImage {
                    mediaItemUrl
                  }
                  intro 
                  subtitle
                }
                allResearchType {
                  nodes {
                    name
                  }
                }
                economies {
                  nodes {
                    name
                  }
                }
                topics {
                  nodes {
                    name
                  }
                }
                uri
              }
              pageInfo {
                startCursor
                hasPreviousPage
                endCursor
                hasNextPage
              }
            }
          }                
        `
      }
    }).then((result) => {
      setCAPSSearchResults(caps_searchResults.concat(result.data.data.allCapsResearch.nodes));
      setCAPSNextPage(result.data.data.allCapsResearch.pageInfo);
    });
  } 

  const meta = {
    title: 'Policy Research',
    description: 'CAPS policy research helps business leaders, philanthropists and policymakers see how they can facilitate and increase private capital flows towards the social sector.',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {(!props.loadedCommonData || !postsLoaded) ?
         <CAPSLoader logo_image={props.logo}/>
      :
      <Fade in={true}>
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array = {[
              {text: 'about us', route: 'about', type: 'standard'},
              {text: 'OUR WORK', route: 'work', type: 'standard'},
              {text: 'RESOURCES', route: 'resources', type: 'standard'},
              {text: 'CONTACT US', route: 'contact', type: 'standard'},
              {text: 'NEWSLETTER', type: 'button', function: 'subscription'}
            ]}
          />

          <SmallPageTitle
              title='Policy Research'
              subtitle=""
          />

          <div className={common_styles.content_container}>
          {caps_searchResults.length > 0 && 
              <div className={styles.posts_section_container}>
                <h2 className={styles.research_section}>Research by CAPS</h2>
                <div className={styles.post_container}>
                  {caps_searchResults.map((result, index) => {         
                      return <div key={index}>
                        <PostRenderer content={result} type='our-research' index={index}/>
                      </div>
                  })}
                </div>
                {caps_nextPage.hasNextPage && <div onClick={() => moreCAPSPosts()} className={styles.next_button}>Load More Reports</div>} 
              </div>
          }
          </div>

          <Footer/>
        </div>
      </Fade>
      }
    </div>
  );
}

export default PolicyResearch;
