import styles from "../css-modules/about_approach.module.css";
import icon1 from "../../../resources/approach-icons/icon1.png";
import icon2 from "../../../resources/approach-icons/icon2.png";
import icon3 from "../../../resources/approach-icons/icon3.png";
import icon4 from "../../../resources/approach-icons/icon4.png";
import icon5 from "../../../resources/approach-icons/icon5.png";
import map_background from "../../../resources/approach-icons/map_background.png";
import classNames from "classnames";

const approachData = [
  {
    icon: icon1,
    statement: (
      <p>
        <b>Understanding</b> fundamental strengths and cultural practices of
        Asia
      </p>
    ),
  },
  {
    icon: icon2,
    statement: (
      <p>
        <b>Working with philanthropists</b> and corporate donors to increase the
        impact of their investment
      </p>
    ),
  },
  {
    icon: icon3,
    statement: (
      <p>
        <b>Influencing</b> the adoption of business rigor and tools in the
        charitable sector
      </p>
    ),
  },
  {
    icon: icon4,
    statement: (
      <p>
        <b>Building capacity</b> of intermediary organisations in the social
        sector
      </p>
    ),
  },
  {
    icon: icon5,
    statement: (
      <p>
        <b>Researching and promoting</b> policies and practices that best enable
        the giving and receiving of private social investment
      </p>
    ),
  },
];

function Approach() {
  return (
    <div className={styles.approach_container_header}>
      <h2>Our Approach</h2>
      <div className={styles.approach_container}>
        <div className={styles.map_background}>
          <img
            src={map_background}
            className={styles.map_background_image}
          ></img>
        </div>
        <div className={styles.approach_left}>
          {approachData.length > 0 &&
            approachData.map((approach, index) => (
              <div className={styles.left_approach} key={`approach-${index}`}>
                {index % 2 === 0 && (
                  <div className={styles.approach_content}>
                    <p className={styles.approach_para}>{approach.statement}</p>
                    <img
                      src={approach.icon}
                      className={classNames(
                        styles.approach_icon,
                        styles.icon_left_margin
                      )}
                    ></img>
                  </div>
                )}
              </div>
            ))}
        </div>

        <div className={styles.approach_right}>
          {approachData.length > 0 &&
            approachData.map((approach, index) => (
              <div className={styles.right_approach} key={`approach-${index}`}>
                {index % 2 !== 0 && (
                  <div className={styles.approach_content}>
                    <img
                      src={approach.icon}
                      className={classNames(
                        styles.approach_icon,
                        styles.icon_right_margin
                      )}
                    ></img>
                    <p className={styles.approach_para}>{approach.statement}</p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Approach;
