import styles from "./css-modules/our_work.module.css";
import common_styles from "./css-modules/views.module.css";
import CAPSLoader from "../components/capsLoader.js";
import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import PageTitle from "../components/view-specific/page_title.js";
import SideHeadSection from "../components/SideHeadSection.js";
import door from "../resources/doors/door_three.jpeg";
import ConvDesc from "../components/view-specific/convening_description.js";
import { useEffect, useState } from "react";
import axios from "axios";
import DocumentMeta from 'react-document-meta';
import ReactGA from "react-ga4";
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom'

function Work(props) {
  const [policyResearchData, setPolicyResearchData] = useState([]);
  const [appliedResearchData, setAppliedResearchData] = useState([]);

  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: `/work` });

    if (!location.state) {
      window.scrollTo(0,0);
    }

    if (!props.loadedWorkData) {
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query fetchPolicyResearch {
              allCapsResearch(
                first:6, where:{taxQuery: {taxArray: {taxonomy: RESEARCHTYPE, terms: "policy-research", operator: IN, field: SLUG}}}
              ) {
                nodes {
                  id
                  title
                  uri
                  date
                  asianPeopleInfo {
                    intro
                    subtitle
                    coverImage {
                      mediaItemUrl
                    }
                  }
                }
              }
              displayConveningData {
                nodes {
                  displayConvening {
                    display
                  }
                }
              }
            }          
          `,
        },
      }).then((result) => {
        props.setCheckConvButton(result.data.data.displayConveningData.nodes[0].displayConvening.display);
        setPolicyResearchData(result.data.data.allCapsResearch.nodes);
      });
      axios({
        url: "https://wordpress.caps.org/graphql",
        method: "post",
        data: {
          query: `
            query fetchPolicyResearch {
              allCapsResearch(
                first:6, where:{taxQuery: {taxArray: {taxonomy: RESEARCHTYPE, terms: "applied-research", operator: IN, field: SLUG}}}
              ) {
                nodes {
                  id
                  title
                  uri
                  date
                  asianPeopleInfo {
                    intro
                    subtitle
                    coverImage {
                      mediaItemUrl
                    }
                  }
                }
              }
            }          
          `,
        },
      }).then((result) => {
        setAppliedResearchData(result.data.data.allCapsResearch.nodes);
      });
    }
  }, []);

/*  useEffect(() => {
    if (props.loadedCommonData && props.loadedWorkData) {
      if (location.state.param) {
        if (location.state.param === 'Policy Research') {
          console.log(document.getElementById('policy-research').getBoundingClientRect());
          window.scrollTo(0, document.getElementById('policy-research').getBoundingClientRect().top - 170);
        }
        else if (location.state.param === 'Applied Research') {
          console.log('called');
          console.log(document.getElementById('applied-research').getBoundingClientRect());
          window.scrollTo(0, document.getElementById('applied-research').getBoundingClientRect().top - 170);
          window.scrollTo(0, document.getElementById('applied-research').getBoundingClientRect().top);
        }
        else if (location.state.param === 'Commissioned Research') {
          console.log(document.getElementById('commissioned-research').getBoundingClientRect());
          window.scrollTo(0, document.getElementById('commissioned-research').getBoundingClientRect().top - 170);
        }
        else if (location.state.param === 'Convening') {
          console.log(document.getElementById('convening').getBoundingClientRect());
          window.scrollTo(0, document.getElementById('convening').getBoundingClientRect().top - 170);
        }
      }
    }
  }, [props.loadedWorkData, props.loadedCommonData]); */

  useEffect(() => {
    if (policyResearchData.length > 0 && appliedResearchData.length > 0) {
      let newArr = [
        { type: "policy", data: policyResearchData },
        { type: "applied", data: appliedResearchData },
      ];
      props.setWorkData(newArr);
      props.setLoadedWorkData(true);
    }
  }, [policyResearchData, appliedResearchData]);

  const meta = {
    title: 'CAPS | Our Work',
    description: '',
  };

  return (
    <div className={common_styles.view_container}>
      <DocumentMeta {...meta}/>
      {!props.loadedCommonData || !props.loadedWorkData ? (
         <CAPSLoader logo_image={props.logo}/>
      ) : (
        <div className={common_styles.view_content_container}>
          <Navbar
            logo_image={props.logo}
            navigation_array={[
              { text: "about us", route: "about", type: "standard" },
              { text: "OUR WORK", route: "work", type: "standard" },
              { text: "RESOURCES", route: "resources", type: "standard" },
              { text: "CONTACT US", route: "contact", type: "standard" },
              { text: "NEWSLETTER", type: "button", function: "subscription" },
            ]}
          />

          <PageTitle
            title="Our Work"
            desc="Our work covers policy research, applied research, commissioned research and convening. In collaboration with our extensive network of local partners and support from Asian philanthropists across 18 Asian economies, we generate evidence-based insights into how individuals, companies and governments can best address social challenges."
            titleImage={door}
          />
          <SideHeadSection
            header="Policy Research"
            description={
             <div id="policy-research">
                <p>
                  CAPS policy research helps business leaders, philanthropists and policymakers see
                  how they can facilitate and increase private capital flows
                  towards the social sector.
                </p>
                <p>
                  CAPS’s biennial <span className={common_styles.colored_span}>Doing Good Index</span> identifies the enabling and impeding factors for private social investment and philanthropy for 18 Asian economies using a data-driven, evidence based approach.  It is a globally
                  cited resource and policy guide for strengthening
                  philanthropic ecosystems in Asia.
                </p>
              </div>
            }
            bgCol={"#ffffff"}
            content="research"
            toLink="/caps_research/policy_research"
            toLinkTitle="View All Reports"
            contentArray={props.workData[0].data}
          />
          <SideHeadSection
            header="Applied Research"
            description={
              <div id="applied-research" className={styles.padding_work_p}>
                <p>
                  CAPS applied research provides useful understanding and tools through qualitative
                  and quantitative research to drive more impactful investment
                  towards meeting social needs. Our reports are ground breaking, evidence-based analyses on critical yet under-researched topics in Asia.
                </p>
              </div>
            }
            bgCol={"#f4f4f4"}
            content="research"
            toLink="/caps_research/applied_research"
            toLinkTitle="View All Reports"
            contentArray={props.workData[1].data}
          />
          <SideHeadSection
            header="Commissioned Research"
            description={
              <div id="commissioned-research">
                <p>
                  Since 2015, CAPS has worked with philanthropists, family offices, foundations, social delivery organizations and governments to develop bespoke strategies and programmatic plans.  We understand what works within the Asia context, who to partner with and what governance models are most suited to meaningful outcomes. Our work leverages CAPS' research and network to help clients drive change, nurture important relationships and maximize the impact of their social investments.
                </p>

                <Link to="/contact">
                  <p className={styles.comm_research_butt}>
                    Contact us for commisioned research
                  </p>
                </Link>
              </div>
            }
            bgCol={"#ffffff"}
            content="comm-research"
          />
          <div id="convening">
           <ConvDesc checkConvButton={props.checkConvButton}/>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Work;
