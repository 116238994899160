import resources1 from "../resources/resources/resources1.jpeg";
import resources2 from "../resources/resources/resources2.jpg";
import resources3 from "../resources/resources/resources3.jpg";
import resources4 from "../resources/resources/resources4.jpg";
import styles from "./css-modules/resourceTiles.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";

function ResourcesTiles({ layout }) {
  return (
    <section className={styles.resource_tiles}>
      <div className={styles.card_description_contain}>
        <h2 className={styles.title}>Resources</h2>
        <p className={styles.description}>
          Our resources showcase the best studies, reports, articles and videos
          on Asia, from Asia.
        </p>
      </div>
      <div
        className={classNames(
          styles.resource_card_contain,
          layout === "row" ? styles.card_contain_row : styles.card_contain_grid
        )}
      >
        <Link
          to={`/resources/glossary/`}
          className={classNames(
            styles.resource_card,
            layout === "row"
              ? styles.resource_card_row
              : styles.resource_card_grid
          )}
        >
          <div>
            <figure className={styles.img_resource_card}>
              <img
                src={resources1}
                alt="Supporting image for describing resources"
                className={layout === "row" ? styles.image : styles.gridImage}
              />
            </figure>
            <div className={styles.desc_resource_card}>
              <h3>CAPS Glossary</h3>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p>Terms and definitions for doing good</p>
            </div>
          </div>
        </Link>
        <Link
          to={`/resources/asian_voices/`}
          className={classNames(
            styles.resource_card,
            layout === "row"
              ? styles.resource_card_row
              : styles.resource_card_grid
          )}
        >
          <div>
            <figure className={styles.img_resource_card}>
              <img
                src={resources2}
                alt="Supporting image for describing resources"
                className={layout === "row" ? styles.image : styles.gridImage}
              />
            </figure>
            <div className={styles.desc_resource_card}>
              <h3>Asian Voices</h3>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p>Interviews with leading voices from our network</p>
            </div>
          </div>
        </Link>
        <Link
          to={`/resources/library`}
          className={classNames(
            styles.resource_card,
            layout === "row"
              ? styles.resource_card_row
              : styles.resource_card_grid
          )}
        >
          <div>
            <figure className={styles.img_resource_card}>
              <img
                src={resources3}
                alt="Supporting image for describing resources"
                className={layout === "row" ? styles.image : styles.gridImage}
              />
            </figure>
            <div className={styles.desc_resource_card}>
              <h3>Library</h3>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p>Curated resources on Asia’s social sectors</p>
            </div>
          </div>
        </Link>
        <Link
          to={`/resources/newsletter`}
          className={classNames(
            styles.resource_card,
            layout === "row"
              ? styles.resource_card_row
              : styles.resource_card_grid
          )}
        >
          <div>
            <figure className={styles.img_resource_card}>
              <img
                src={resources4}
                alt="Supporting image for describing resources"
                className={layout === "row" ? styles.image : styles.gridImage}
              />
            </figure>
            <div className={styles.desc_resource_card}>
              <h3>Newsletter</h3>
              <div className={styles.separator_container}>
                <div className={styles.separator_line}></div>
                <div className={styles.separator_skewed}></div>
              </div>
              <p>News from social investment from around Asia</p>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
}

export default ResourcesTiles;
