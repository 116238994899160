export const workTypes = [
  {
    workType: "Policy Research",
    description: `Helping business leaders, philanthropists and policymakers see how they can facilitate and
    increase private capital flows towards the social sector`,
  },
  {
    workType: "Applied Research",
    description: `Providing useful understanding and tools through qualitative and quantitative research to
    drive more impactful investment towards meeting social needs`,
  },
  {
    workType: "Commissioned Research",
    description: `Advising corporations and foundations on how to achieve their giving goals through strategic review,
    project design and evaluation leading to improved social investment strategies and CSR programs`
  },
  {
    workType: "Convening",
    description: `Curating invitation-only, closed event dedicated to our network of philanthropists; Connecting our
    partners through meetings and workshops to foster learning community and support capacity building`
  }
];
