import styles from "./css-modules/report_sub_modal.module.css";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import axios from 'axios';
import { AiOutlineClose } from "react-icons/ai";
import ReactGA from "react-ga4";

function ReportSubModal({ reportSubModal, currReportLink, setReportSubModal, reportTitle, currReportType }) {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [incompleteErr, setIncompleteErr] = useState(false);
  const [successFul, setSuccessFul] = useState(false);
  const [mailChimpReq, setMailChimpReq] = useState(null);

  useEffect(() => {
    mailChimpReq && 
      axios
        .post(
          "https://18.163.120.145.nip.io/api/addReportDownloader",
          mailChimpReq
        )
        .then(function (response) {
          if (response.data.statusCode === 200) {
            setDisableInputs(false);
            setSuccessFul(true);
            setIncompleteErr(false);
            resetForm();

            ReactGA.event("file_download", {
              'email': email,
              'name': fName + ' ' + lName,
              'org': organization,
              'title': reportTitle + ' - ' + currReportType,
            });

            const a = document.createElement("a");
            a.setAttribute('href', currReportLink);
            a.setAttribute('target', '_blank');
            a.style = "display: none";
            a.click();

          } else if (response.data.statusCode === 400) {
            setDisableInputs(false);
            setSuccessFul(true);
            setIncompleteErr(false);
            resetForm();

            ReactGA.event("file_download", {
              'email': email,
              'name': fName + ' ' + lName,
              'org': organization,
              'title': reportTitle + ' - ' + currReportType,
            });

            const a = document.createElement("a");
            a.setAttribute('href', currReportLink);
            a.setAttribute('target', '_blank');
            a.style = "display: none";
            a.click();

          //  window.open(currReportLink);
          } else {

          /*  ReactGA.event("file_download", {
              'email': email,
              'name': fName + ' ' + lName,
              'org': organization,
              'title': reportTitle + ' - ' + currReportType,
            });


            const a = document.createElement("a");
            a.setAttribute('href', currReportLink);
            a.setAttribute('target', '_blank');
            a.style = "display: none";
            a.click(); */
            setDisableInputs(false);
            setSuccessFul(false);
            setIncompleteErr(true);
          }
        });
  }, [mailChimpReq]);

  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function setValueState(event, id) {
    if (id === "fname") {
      setFName(event.target.value);
    } else if (id === "lname") {
      setLName(event.target.value);
    } else if (id === "organization") {
      setOrganization(event.target.value);
    } else if (id === "email") {
      setEmail(event.target.value);
    }
  }

  function resetForm() {
    setOrganization('');
    setEmail('');
    setFName('');
    setLName('');
  }


  function submitContactForm() {
    if (email === "" || organization === "" || fName === "" || lName === "") {
      setIncompleteErr(true);
      setSuccessFul(false);
    } else {
      setDisableInputs(true);
      if (validateEmail(email)) {
        let req = {
          email: email,
          firstName: fName,
          lastName: lName,
          organization: organization,
          typeTag: reportTitle + ' - ' + currReportType
        };
        setMailChimpReq(req);
      } else {
        setIncompleteErr(true);
        setSuccessFul(false);
      }
    }
  }

  return (
    <Fade in={reportSubModal}>
        <div className={styles.modal_container}>
            <div className={styles.form_container}>
              <h3 className={styles.newsletter_sub_header}>Download Report</h3>
              <p className={styles.sub}><i>Please provide the following information to view the file</i></p>
              <AiOutlineClose
                onClick={() => {
                  setReportSubModal(false);
                  const scrollY = document.body.style.top;
                  document.body.style.position = "";
                  document.body.style.top = "";
                  window.scrollTo(0, parseInt(scrollY || "0") * -1);
                  document.body.style.overflow = "unset";
                  resetForm();
                  setIncompleteErr(false);
                  setSuccessFul(false);
                  setDisableInputs(false);
                }}
                className={styles.icon_close}
              />
              <div className={styles.input_container}>
                <TextField
                  className={styles.text_form}
                  value={fName}
                  onChange={(event) => setValueState(event, "fname")}
                  required
                  disabled={disableInputs}
                  id="first_name"
                  label="First Name"
                  variant="standard"
                />
              </div>
              <div className={styles.input_container}>
                <TextField
                  className={styles.text_form}
                  value={lName}
                  onChange={(event) => setValueState(event, "lname")}
                  required
                  disabled={disableInputs}
                  id="last_name"
                  label="Last Name"
                  variant="standard"
                />
              </div>
              <div className={styles.input_container}>
                <TextField
                  className={styles.text_form}
                  value={email}
                  onChange={(event) => setValueState(event, "email")}
                  required
                  disabled={disableInputs}
                  id="email"
                  label="Email"
                  variant="standard"
                />
              </div>
              <div className={styles.input_container}>
                <TextField
                  className={styles.text_form}
                  value={organization}
                  onChange={(event) => setValueState(event, "organization")}
                  required
                  disabled={disableInputs}
                  id="organization"
                  label="Organization"
                  variant="standard"
                />
              </div>
              {incompleteErr && (
                <Fade in={true}>
                  <p className={styles.status}>
                    Failed to submit. Please make sure all information is
                    provided (email is correctly formatted) and try again
                  </p>
                </Fade>
              )}
              {successFul && (
                <Fade in={true}>
                  <p className={styles.status}>
                    Thank you. If the file does not automatically open, <a href={currReportLink} target="_blank" rel="noopener noreferrer">click here</a>. 
                  </p>
                </Fade>
              )}
              <div
                className={styles.button_submit}
                onClick={() => submitContactForm()}
              >
                <p className={styles.button_p}>Submit</p>
              </div>
            </div>
        </div>
    </Fade>
  );
}

export default ReportSubModal;
