import styles from "./css-modules/resourceTiles.module.css";
import classNames from "classnames";
import Fade from '@mui/material/Fade';
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

function ConveningTiles({ layout, content }) {

    const [toolOpen, setToolOpen] = useState(false);
    const [toolData, setToolData] = useState({});

    function openTool(data) {
        setToolData(data);
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
        document.body.style.overflow = "hidden";
        setToolOpen(true);
    }

    function closeTool() {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
        document.body.style.overflow = "unset";
        setToolOpen(false); 
    }
    
  return (
    <section className={styles.resource_tiles} style={{background: '#fff'}}>
      <div className={styles.card_description_contain}>
        <p className={styles.description} style={{textAlign: 'left'}}>
            CAPS regularly holds invitation-only philanthropy conferences with our donors, clients, philanthropists, global experts and thought leaders from Asia and beyond. This is the only pan-Asian, principals-only philanthropy conference series in the region. CAPS’ convenings provide a unique ground for business leaders, philanthropists and visionaries to share ideas, experiences and strategies for improving our communities and doing good.
        </p>
        <p className={styles.description} style={{textAlign: 'left'}}>
            In addition to the philanthropists convening, CAPS brings its partner organizations together to create a network where knowledge and insights are shared. CAPS is committed to building an inclusive and efficient ecosystem of social sector communities across Asia
        </p>
      </div>
      <div
        className={classNames(
          styles.resource_card_contain,
          layout === "row" ? styles.card_contain_row : styles.card_contain_grid
        )}
      >
          {content && content.length > 0 && content.map((item, idx) => {
            return <div 
              onClick={() => openTool(item)}
              className={classNames(
                styles.resource_card,
                layout === "row"
                  ? styles.resource_card_row
                  : styles.resource_card_grid
              )}>
                <figure className={styles.img_resource_card}>
                    <img
                    src={item.imageData.image.mediaItemUrl}
                    alt="Supporting image for describing resources"
                    className={layout === "row" ? styles.image : styles.gridImage}
                    />
                </figure>
                <div className={styles.desc_resource_card}>
                    <h3>{item.title}</h3>
                    <div className={styles.separator_container}>
                    <div className={styles.separator_line}></div>
                    <div className={styles.separator_skewed}></div>
                    </div>
                    <p>Click for details</p>
                </div>
            </div>
          })}
      </div>

      <Fade in={toolOpen}>
        <div className={styles.convening_tool} style={{height: window.innerHeight}}>
            <div className={styles.picture_container}>
                {toolData.imageData && <img src={toolData.imageData.image.mediaItemUrl} className={styles.tool_image}></img>}
            </div>
            <div className={styles.info_container}>
                <div className={styles.close_button_tool} onClick={() => closeTool()}>
                    <AiOutlineClose className={styles.close_icon}/>
                </div>
                {toolData.title && <h1>{toolData.title}</h1>}
                {toolData.content && <div className={styles.tool_text} dangerouslySetInnerHTML={{ __html: toolData.content}}></div>}
            </div>
        </div>    
      </Fade>
    </section>
  );
}

export default ConveningTiles;
