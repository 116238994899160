import styles from "./css-modules/commr_modal.module.css";
import { useEffect } from "react";
import Fade from "@mui/material/Fade";
// import classNames from "classnames";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

function CommRModal({ modalData, setModalOpen, modalOpen }) {
  const { commRType } = useParams();
  const commRDatum = modalData.filter((commR, idx) => commRType === commR.id)[0];
  const commRTitle = commRDatum.commRTitle;
  const commRBullets = commRDatum.bullets;

  useEffect(() => {

    if (!modalOpen) {
      setModalOpen(true);
    }

    // document.body.style.top = `-${window.scrollY}px`;
    // document.body.style.position = "fixed";
    // document.body.style.overflow = "hidden";
    // return () => {
    //   const scrollY = document.body.style.top;
    //   document.body.style.position = "";
    //   document.body.style.top = "";
    //   window.scrollTo(0, parseInt(scrollY || "0") * -1);
    //   document.body.style.overflow = "unset";
    // };
  }, []);

  return (
    <Fade in={modalOpen}>
      <section className={styles.modalData}>
        <Link onClick={() => setModalOpen(false)} to="/work">
          <button className={styles.goBackWhite}></button>
        </Link>

        <div className={styles.data_container}>
          <h3 className={styles.commRHeader}>{commRTitle}</h3>
          <ul>
            {commRBullets.map((bullet, idx) => (
              <li key={`bullet${idx}`}>
                {bullet}
              </li>
            ))}
          </ul>
          <Link onClick={() => setModalOpen(false)} to="/work">
            <div className={styles.close_button}>
              <AiOutlineClose onClick={() => setModalOpen(false)} />
            </div>
          </Link>
        </div>
      </section>
    </Fade>
  );
}

export default CommRModal;
