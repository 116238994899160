import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { useAsianGeoData } from "./useAsianGeoData.js";
import { useData } from "./useData.js";
import styles from "./css-modules/networkMap.module.css";
import classNames from "classnames";
import VoronComp from './voronComp.js'


const lineGenerator = d3.line().curve(d3.curveCardinal);

function getClassName(event) {
  return event.target.className.baseVal.split(" ")[1];
}

export default function NetworkMap({
  svgWidth,
  svgHeight,
  mapStyles,
  landkey,
  geoPath,
  geoProj,
  setModalOpen,
  setModalData,
  partnersData
}) {
  const topoData = useAsianGeoData();
  const networkLocs = useData();
  const [hoverEcon, setHoverEcon] = useState(null);
  const svgRef = useRef();

  useEffect(() => {
    if (networkLocs) {
      d3.selectAll("path.connLines")
        .data(networkLocs)
        .each(function (d, i) {
          const pathLength = d3.select(this).node().getTotalLength();
          d.totLen = pathLength;
        });

      d3.selectAll("text").data(networkLocs);
    }
  }, [networkLocs]);

  if (!topoData | !networkLocs) {
    return (
      <div className="vizContain">
        <pre>Loading . . .</pre>
      </div>
    );
  } else {
    return (
      <div
        className={styles.vizContain}
        style={{position: "relative" }}
      >
        <svg
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          preserveAspectRatio="xMinYMin meet"
          ref={svgRef}
          className={styles.svg}
        >
          <g className="mapGrp">
            {topoData.features.map((feature) => (
              <path style={mapStyles} key={landkey} d={geoPath(feature)}></path>
            ))}
          </g>
          <g className="networkGrp">
            {networkLocs.map((loc) => (
              <g className="locGrp" key={loc.Economy}>
                <text
                  x={loc.x}
                  y={loc.y}
                  transform={
                    hoverEcon === loc.Id
                      ? loc.Economy == "Hong Kong"
                        ? `translate(0, -35)`
                        : ["Cambodia", "Singapore"].includes(loc.Economy)
                        ? `translate(0, 25)`
                        : `translate(0, -20)`
                      : `translate(0, 0)`
                  }
                  fill={"#172F58"}
                  fillOpacity={loc.Id == hoverEcon ? 1 : 0}
                  textAnchor="middle"
                  fontSize={loc.Economy == "Hong Kong" ? "15px" : "12px"}
                  style={{
                    transition: `fill-opacity 0.15s ease-out, transform 0.15s ease-out`,
                    transformOrigin: `${loc.x}px ${loc.y}px`
                  }}
                >
                  {loc.Economy}
                </text>
                <path
                  className="connLines"
                  d={lineGenerator([
                    [loc.sx, loc.sy],
                    [loc.cx, loc.cy],
                    [loc.x, loc.y],
                  ])}
                  strokeWidth={"3px"}
                  stroke={"#172F58"}
                  fill="none"
                  strokeOpacity={0.12}
                  strokeLinecap="round"
                />
                <circle
                  cx={loc.x}
                  cy={loc.y}
                  r={loc.Economy == "Hong Kong" ? 25 : 10}
                  stroke={"#172F58"}
                  fill={"none"}
                  strokeWidth={loc.Economy == "Hong Kong" ? "3px" : "2px"}
                  className={classNames(
                    "animBubble",
                    `${loc.Id}`,
                    hoverEcon === loc.Id && "hover"
                  )}
                  style={{
                    transformOrigin: `${loc.x}px ${loc.y}px`,
                    transform:
                      hoverEcon === loc.Id
                        ? loc.Economy == "Hong Kong"
                          ? `scale(1.15)`
                          : `scale(1.25)`
                        : `scale(1)`,
                    transition: `transform 0.15s ease-out`,
                  }}
                />
                <circle
                  cx={loc.x}
                  cy={loc.y}
                  r={loc.Economy == "Hong Kong" ? 25 : 10}
                  className={classNames("locatorBubble", `${loc.Id}`)}
                  style={{
                    cursor: "pointer",
                    fill: "#172F58"
                  }}
                />
              </g>
            ))}
            <VoronComp
              d3Projection={geoProj}
              voronoiExt={[-1, -1, svgWidth + 1, svgHeight + 1]}
              pointData={networkLocs}
              circleRadius={40}
              onMouseEnterFunc={(e) => setHoverEcon(getClassName(e))}
              onMouseLeaveFunc={(e) => setHoverEcon(null)}
              setModalOpen = {setModalOpen}
              setModalData = {setModalData}
              partnersData = {partnersData}
            />
          </g>
        </svg>
      </div>
    );
  }
}
