import styles from './css-modules/capsLoader.module.css';
import Loader from "react-loader-spinner";

function CAPSLoader(props){
  return (
    <div className={styles.flex_contain}>
      <div className={styles.logo_contain}>
        <Loader
          type="TailSpin"
          color="#757575"
          height={40}
          width={40}
          timeout={3000} //3 secs
        />
      </div>
  </div>
  )
}

export default CAPSLoader;