import styles from './css-modules/page_title.module.css';
import classNames from 'classnames';

function PageTitle(props) {
  return (
    <div className={styles.title_container}>
      <div className={styles.title_text_container}>
        <h1 className={classNames(styles.title, "heading")}>{props.title}</h1>
        <div className={styles.title_separator}></div>
        <p className={styles.desc}>{props.desc}</p>
      </div>
      <div className={styles.title_image_container}>
        <div className={styles.image_gradient_overlay}></div>
        <img src={props.titleImage} className={styles.title_image}></img>
      </div>
    </div>
  );
}

export default PageTitle;
