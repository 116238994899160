import { useEffect, useState } from 'react';
import styles from './css-modules/post_renderer.module.css';
import classNames from 'classnames';
import {
  Link,
  useLocation,
} from "react-router-dom";

function PostRenderer(props) {

    let options = { year: 'numeric', month: 'long' };

    const [economies, setEconomies] = useState('');
    const [topics, setTopics] = useState('');
    const [toLink, setToLink] = useState('');
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        let topics = '';
        let economies = '';

        for (let i = 0; i < props.content.topics.nodes.length; i++) {
          if (i === props.content.topics.nodes.length - 1) {
            topics += props.content.topics.nodes[i].name;
          }
          else {
            topics += props.content.topics.nodes[i].name + ', '
          }
        }

        for (let i = 0; i < props.content.economies.nodes.length; i++) {
          if (i === props.content.economies.nodes.length - 1) {
            economies += props.content.economies.nodes[i].name;
          }
          else {
            economies += props.content.economies.nodes[i].name + ', '
          }
        }
        
        setEconomies(economies);
        setTopics(topics);

        if (props.type === 'our-research') {
            let containsCaseStudy = props.content.allResearchType.nodes.find(el => el.name === 'Case Studies');

            setToLink(`/work/${props.type}_${props.content.uri.split('/')[props.content.uri.split('/').length - 2]}`);
            let labelArr = ['Research by CAPS'];
            let beforeDate = new Date("2018-08-30");
            console.log(props.content);
            (new Date(Date.parse(props.content.date)) < beforeDate) && (!dateCheck('1/16/2018')) && (!dateCheck('2/6/2018')) && labelArr.push('Case Study');
            (containsCaseStudy) && labelArr.push('Case Study');
            setLabels(labelArr);
        }
        else if (props.type === 'other-research') {
            let customizeUri = props.content.uri.slice(0, -1);
            customizeUri = customizeUri.substring(1);
            customizeUri = customizeUri.replaceAll('/', '&');
            setToLink(`/content/${props.type}_${customizeUri}`);
            let labelArr = ['External Resources'];
            setLabels(labelArr);
        }
        else if (props.type === 'asian-voice') {
          setToLink(`/resources/asian_voices/${props.content.uri.split('/')[props.content.uri.split('/').length - 2]}`);
          let labelArr = ['Asian Voices'];
          setLabels(labelArr);
        }
    }, [props.content]); 

    function dateCheck(date) {
      return new Date(Date.parse(props.content.date)).toLocaleDateString().includes(date);
    }


    return (
        <Link key={props.index} to={toLink} className={styles.container}>
            <div className={styles.photo_container}>
               {props.content.asianPeopleInfo.coverImage && <img src={props.content.asianPeopleInfo.coverImage.mediaItemUrl} className={styles.post_image} alt={props.content.title}></img>}
            </div>

            <div className={styles.details_container}>
                <div className={styles.types_container}>
                  {labels && labels.length > 0 && labels.map((label, index) => 
                    <p key={index} className={classNames(styles.type_text, styles[label.toLowerCase().replaceAll(' ', '_')])}>{label}</p>
                  )}
                </div>
                <h3>{props.content.title}</h3>
                {props.content.asianPeopleInfo.subtitle && <p className={styles.subtitle}>{props.content.asianPeopleInfo.subtitle}</p>}
                <div className={styles.intro} dangerouslySetInnerHTML={{__html: props.content.asianPeopleInfo.intro}}/>
                {props.content.topics.nodes.length > 0 && 
                    <p className={classNames(styles.topics_economy, styles.topics_increased_margin)}>
                    <b style={{textTransform: 'uppercase'}}>{props.content.topics.nodes.length > 1 ? 'Topics: ': 'Topic: '}</b>
                    {topics}
                    </p>
                }

                {props.content.economies.nodes.length > 0 && 
                    <p className={styles.topics_economy}>
                    <b style={{textTransform: 'uppercase'}}>{props.content.economies.nodes.length > 1 ? 'Economies: ': 'Economy: '}</b>
                    {economies}
                    </p>
                }
                {props.content.date && 
                    <p className={styles.topics_economy}>
                    <b style={{textTransform: 'uppercase'}}>Date: </b>
                    {new Date(Date.parse(props.content.date)).toLocaleDateString("en-UK", options)}
                    </p>
                }
            </div>
        </Link>
  )
}

export default PostRenderer;
