import styles from "./css-modules/member_modal.module.css";
import ScrollLock from "react-scrolllock";
import { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import classNames from "classnames";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

function MemberModal({
  modalData,
  setModalData,
  modalOpen,
  setModalOpen,
  allTeamD,
}) {
  const [initializeData, setInitializedData] = useState(false);

  useEffect(() => {
    if (modalData.uri) {
      if (
        modalData.uri.split("/")[
          modalData.uri.split("/").length - 2
        ] !==
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ]
      ) {
        let node = allTeamD[3].data.filter(function (node) {
          return node.uri.includes(
            window.location.pathname.split("/")[
              window.location.pathname.split("/").length - 1
            ]
          );
        })[0];
        setModalData(node);
        setInitializedData(true);
      } else {
        setInitializedData(true);
      }
    }

    if (!modalOpen) {
      setModalOpen(true);
    }

    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      document.body.style.overflow = "unset";
    };
  }, []);

  function stringToHTML(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, "text/html");
    return doc.body.innerHTML;
  }

  return (
    <Fade in={modalOpen && initializeData}>
      <section className={styles.modalData} style={window.innerWidth < 912 ? {height: window.innerHeight} : {height: '100vh'}}>
        <Link onClick={() => setModalOpen(false)} to="/about">
          <button className={styles.goBackWhite}></button>
        </Link>

        <div className={styles.data_container}>
          <div className={styles.photo_container}>
            {modalData.details && (
              <div
                className={styles.image_container}
                style={{
                  background: `url(${modalData.details.picture.sourceUrl})`,
                }}
              ></div>
            )}
            <div className={styles.info_container}>
              {modalData.title && (
                <h1 className={styles.employee_name}>{modalData.title}</h1>
              )}
              <div className={styles.separator}></div>
              {modalData.details && (
                <p
                  className={classNames(
                    styles.employee_desig,
                    modalData.empType.type === "Our Team" && styles.noBoldDesig
                  )}
                >
                  {modalData.details.title}
                </p>
              )}
              {modalData.details && (
                <p className={styles.employee_comp}>
                  {modalData.details.companyName}
                </p>
              )}
              {modalData.details && (
                <p className={styles.employee_country}>
                  {modalData.details.countryRegion}
                </p>
              )}
            </div>

            <Link onClick={() => setModalOpen(false)} to="/about">
              <div className={styles.close_button}>
                <AiOutlineClose onClick={() => setModalOpen(false)} />
              </div>
            </Link>
          </div>
          <div className={styles.paragraph_container}>
            {modalData.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: stringToHTML(modalData.content),
                }}
              />
            )}
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default MemberModal;
